import {
  GET_NEGATIVE_INVENTORY_LOCATION_WISE_REPORT_REQUEST,
  GET_NEGATIVE_INVENTORY_LOCATION_WISE_REPORT_SUCCESS,
  GET_NEGATIVE_INVENTORY_LOCATION_WISE_REPORT_ERROR
} from "../actions/types";

export const negativeInventorylocationWise = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_NEGATIVE_INVENTORY_LOCATION_WISE_REPORT_REQUEST:
      return {
        loading: true,
      };
    case GET_NEGATIVE_INVENTORY_LOCATION_WISE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_NEGATIVE_INVENTORY_LOCATION_WISE_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default: {
      return state;
    }
  }
};

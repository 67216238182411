import React, { useState, useEffect } from 'react'
import { Button, Input, Tag } from 'antd';
import { Row as Brow, Col as Bcol } from "reactstrap";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from 'react-redux';
import { skuExists } from '../../redux/actions/skuExistsErp.action';
import { ReadCookie } from '../../utils/readCookie';
import Tables from '../../components/Table/Tables';
import { CSVLink } from 'react-csv';
import { isObject } from 'lodash';

const SkuExistsErp = () => {
  const { SkuExist } = useSelector(state => state);

  const dispatch = useDispatch();
  const token = ReadCookie("token");

  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);

  console.log("SkuExist", SkuExist)

  const fileType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {

      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);

        reader.onload = (e) => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFile(null);
        setExcelFileError("Please select only excel file types");
      }
    } else {
    }
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);

      const result = data.map(item => item.sku);



      dispatch(skuExists(token, result.toString())).then((res) => {
        console.log("RES", res);
      })
    }
  };


  const downloadExcelFileData = (data) => {
    if (isObject(data)) {

      if (data.length > 0) {
        return data && data?.map((v) => {
          return {
            "SKU": v.item_code,
            "Status": v.status
          };
        })
      }
    }
  }

  const columns = [
    {
      title: "SKU",
      dataIndex: "item_code",
      width: 100,
      fixed: 'left',
    },
    {
      title: "Status",
      editable: false,
      width: 80,
      render: (text, record) => {
        return <Tag color={record.status === "Exists" ? "green" : "red"}>{record.status}</Tag>;
      },
    }
  ];



  return (
    <div >
      <Bcol lg={6} xxl={3}>
        <div style={{ display: "flex", gap: 20 }}>
          <Input
            type="file"
            ClassName="form-control"
            onChange={handleFile}
          // disabled={
          //   !(formState === "add" || formState === "edit")
          // }
          />
          {excelFileError && (
            <div className="text-danger">{excelFileError}</div>
          )}
          <Button
            type="submit"
            className="btn btn-success"
            // style={{ marginTop: 5 + "px" }}
            onClick={handleSubmit}
            disabled={excelFile === null}
          >
            Submit Sku File
          </Button>
        </div>
      </Bcol>

      <div style={{ width: 600, marginTop: 20 }}>
        {
          SkuExist?.payload?.data && (
            <div>
              <CSVLink
                filename={"SkuExits.csv"}
                data={downloadExcelFileData(SkuExist?.payload?.data) || []}

                onClick={() => {
                }}
              >
                Download Excel
              </CSVLink>

            </div>
          )
        }
        <Tables
          className="abc"
          type={"SkuExits"}
          load={SkuExist.loading}
          scroll={({ x: 600 }, { y: 400 })}
          size="small"

          bordered
          perPage={100}
          source={SkuExist?.payload?.data.sort((a, b) => {
            if (a.status === "Not Exists") return -1;
            if (b.status === "Exists") return 1;
            return 0;
          }) || []}
          col={columns}
          paginate
          pagination={{ pageSize: 100 }}
        />
      </div>


    </div>
  )
}

export default SkuExistsErp;
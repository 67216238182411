import { AutoComplete, Button, Input, Popover } from "antd";
import React, { useState, useCallback } from "react";
import { getGloabalSearch } from "../../redux/actions/globalSearch.action";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import { Empty } from "antd";
import Loader from "react-loader-spinner";

const GlobalSearch = ({ token }) => {
  const { globalSearch } = useSelector((state) => state);
  const { submited } = useParams();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    let cleanedVal = val.replace(/[";]/g, "");
    if (cleanedVal.includes("'")) {
      cleanedVal = cleanedVal.replace(/'/g, "''");
    }
    
    if (options) {
      setOptions([]);
    }
    setLoading(true);
    dispatch(getGloabalSearch(token, cleanedVal)).then((res) => {
      setOptions(res?.payload?.data);
      setLoading(false);
    });
  };

  const linkHandler = (origin, item) => {
    if (item?.menu_id === 24) {
      window.open(
        `${origin}/invoice?mode=edit&order_header_id=${item.ref_id}&invoice_header_id=${item.document_pk}&customer_id=${item.customer_id}`,
        "_blank"
      );
      document.cookie = `menuId=${184}`;
    } else if (item?.menu_id === 22) {
      window.open(
        `${origin}/sale-order?mode=edit&order_id=${item.document_pk}&customer_id=${item.customer_id}`,
        "_blank"
      );
      document.cookie = `menuId=${53}`;
    } else if (item?.menu_id === 23) {
      window.open(
        `${origin}/order-shipment?mode=edit&order_id=${item.document_pk}&customer_id=${item.customer_id}`,
        "_blank"
      );
      document.cookie = `menuId=${1254}`;
    } else if (item?.menu_id === 25) {
      window.open(
        `${origin}/return-authorization?mode=edit&return_id=${item.document_pk}&customer_id=${item.customer_id}`,
        "_blank"
      );
      document.cookie = `menuId=${56}`;
    } else if (item?.menu_id === 26) {
      window.open(
        `${origin}/item-receipt-new?mode=edit&return_id=${item.document_pk}`,
        "_blank"
      );
      document.cookie = `menuId=${188}`;
    } else if (item?.menu_id === 27) {
      window.open(
        `${origin}/inventory-adjustment/edit/${item.document_pk}?edit=yes`,
        "_blank"
      );
      document.cookie = `menuId=${474}`;
    } else if (item?.menu_id === 28) {
      window.open(
        `${origin}/item-transfer/edit/${item.document_pk}?edit=yes`,
        "_blank"
      );
      document.cookie = `menuId=${195}`;
    } else if (item?.menu_id === 29) {
      window.open(
        `${origin}/sale-order-adjustment-new?mode=edit&order_id=${item.document_pk}&customer_id=${item.customer_id}`,
        "_blank"
      );
      document.cookie = `menuId=${54}`;
    } else if (item?.menu_id === 30) {
      window.open(
        `${origin}/product-info?mode=edit&product_id=${item.document_pk}`,
        "_blank"
      );
      document.cookie = `menuId=${1259}`;
    } else if (item?.menu_id === 31) {
      window.open(
        `${origin}/customer-New-Info/edit/${item.document_pk}?edit=yes`,
        "_blank"
      );
      document.cookie = `menuId=${1258}`;
    } else if (item?.menu_id === 32) {
      window.open(`${origin}/bill/edit/${item.document_pk}?edit=yes`, "_blank");
      document.cookie = `menuId=${59}`;
    } else if (item?.menu_id === 33) {
      window.open(
        `${origin}/bill-payments/edit/${item.document_pk}?edit=yes`,
        "_blank"
      );
      document.cookie = `menuId=${839}`;
    } else if (item?.menu_id === 34) {
      window.open(
        `${origin}/bill-direct-payments/edit/${item.document_pk}?edit=yes`,
        "_blank"
      );
      document.cookie = `menuId=${1068}`;
    } else if (item?.menu_id === 35) {
      window.open(
        `${origin}/bill-credit/edit/${item.document_pk}?edit=yes`,
        "_blank"
      );
      document.cookie = `menuId=${1260}`;
    } else if (item?.menu_id === 36) {
      window.open(
        `${origin}/customer-payment/edit/${item.document_pk}?edit=yes`,
        "_blank"
      );
      document.cookie = `menuId=${408}`;
    } else if (item?.menu_id === 37) {
      window.open(
        `${origin}/customer-refund/edit/${item.document_pk}?edit=yes`,
        "_blank"
      );
      document.cookie = `menuId=${870}`;
    }
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);
  const renderPopoverContent = (item) => {
    return (
      <div>
        <p>{item.p_result}</p>
        {/* Add more details if necessary */}
      </div>
    );
  };
  return (
    <AutoComplete
      notFoundContent={
        loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader type="Oval" color="#3c4b58" height={20} width={20} />{" "}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {"No Data"}
          </div>
        )
      }
      dropdownMatchSelectWidth={252}
      style={{ border: "1px solid #3c4b58", width: 500, borderRadius: 25 }}
      options={
        options?.length > 0
          ? options?.map((item) => {
              return {
                label: (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>
                      <p
                        onClick={() =>
                          linkHandler(window.location.origin, item)
                        }
                      >
                        <Popover
                          content={renderPopoverContent(item)}
                          placement="left"
                        >
                          {item.p_result}
                        </Popover>
                      </p>
                    </span>
                  </div>
                ),
              };
            })
          : []
      }
      onSearch={optimizedFn}
    >
      <Input.Search className="bg-transparent" size="large" placeholder="Search" enterButton />
    </AutoComplete>
  );
};

export default GlobalSearch;

import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_ERROR,
  ADMIN_LOGOUT,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGOUT_SUCCESS
  // ADMIN_LOGOUT_SUCCESS
} from "./types"
import { BASEURL } from "../../constant/config.js"
var CryptoJS = require("crypto-js");

const axios = require("axios");
export const userLogin = (email, password) => dispatch => {
  dispatch({ type: ADMIN_LOGIN_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}login/user`,
    headers: {
      Accept: "application/json",
    },
    data: {
      login_id: CryptoJS.AES.encrypt(email, process.env.REACT_APP_DECRYPT_KEY).toString(),
      user_password: CryptoJS.AES.encrypt(password, process.env.REACT_APP_DECRYPT_KEY).toString(),
    }
  })
    .then(response => {
      document.cookie = `token=${response.data.accessToken}`;
      document.cookie = `name=${email}`;
      document.cookie = `id=${response.data.user_Id}`;
      return dispatch({ type: ADMIN_LOGIN_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: ADMIN_LOGIN_ERROR,
        payload: error.response,
      });
    })
}

export const logout = (token) => dispatch => {


  dispatch({ type: ADMIN_LOGOUT })
  return axios({
    method: "POST",
    url: `${BASEURL}create/logout`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      localStorage.clear();
      window.location = "/login";
      return dispatch({ type: ADMIN_LOGOUT_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return;
    });
}
import React, { useEffect } from 'react'
import "./Profile.css";
import { Avatar, Col, Row, Table } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, UserOutlined } from '@ant-design/icons';
import Tables from '../../components/Table/Tables';
import { useDispatch, useSelector } from 'react-redux';
import { ReadCookie } from '../../utils/readCookie';
import { getUserLog } from '../../redux/actions/userLog.action';
import moment from 'moment';

const Profile = () => {
    const { userLog } = useSelector((state) => state);

    const dispatch = useDispatch();
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");

console.log({menuId,
    token})

    useEffect(() => {
        dispatch(getUserLog(token, menuId))
    }, [])

    return (
        <div className='profile-container'>
            <div className='profile-hd-main'>Profile</div>
            <div>
                <Row className='mb-20' gutter={[{ xs: 6, sm: 12, md: 16, lg: 24, xl: 30, xxl: 32 }, { xs: 6, sm: 12, md: 16, lg: 24, xl: 30, xxl: 32 }]}>
                    <Col xs={24} sm={24} md={12} lg={4} xl={6} className="gutter-row mb-4">
                        <LeftSection loading={userLog?.loading} data={userLog?.payload?.data} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={20} xl={18} className="gutter-row mb-4">
                        <div className='profile-card-main'>
                            <Row className='profile-logs-card' gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={12} lg={6} xl={8} className="gutter-row mb-4">
                                    <Card title="Time Worked Today" loading={userLog?.loading} data={userLog?.payload?.data} />
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={8} className="gutter-row mb-4">
                                    <Card title="Time Worked This Week" trendValue={0} trendType="up" value={0} />
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={8} className="gutter-row mb-4">
                                    <Card title="Time Worked This Month" trendValue={0} trendType="up" value={0} />
                                </Col>

                            </Row>
                            <ProfileLog loading={userLog?.loading} data={userLog?.payload?.data} />
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default Profile;


const LeftSection = ({ loading, data }) => {
    let name = ReadCookie("name");
console.log("data", data)
    return (
        <div className='profile-card-main'>
            <div className='profile-card-content'>
                <div className='profile-card-avatar'>
                    <Avatar
                        size={80}
                        icon={<UserOutlined />}
                    />
                </div>
                <div className='profile-left-card-detail'>
                    Name : <span>{name}</span>
                </div>
                <div className='profile-left-card-detail'>
                    Email : <span>{data && data[0]?.email}</span>
                </div>
                <div className='profile-left-card-detail'>
                    Phone : <span>{data && data[0]?.mobile_no}</span>
                </div>
            </div>
        </div>
    )
}


const ProfileLog = ({ loading, data }) => {


    const columns = [
        {
            title: 'Date',
            dataIndex: 'login_date',
            key: 'login_date',
            render: (text, record) => {
                return moment(record.login_date).format("MM-DD-YYYY");
            },
        },
        {
            title: 'User Name',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        {
            title: 'Logged In Time',
            dataIndex: 'login_time',
            key: 'login_time',
        },
        {
            title: 'Logged Out Time',
            dataIndex: 'logout_time',
            key: 'logout_time',
        },
        {
            title: 'Spend Time',
            dataIndex: 'spend_time',
            key: 'spend_time',
        },
        // {
        //     title: 'Total Spend Time',
        //     dataIndex: 'total_spend_time',
        //     key: 'total_spend_time',
        // },
    ];

    return (

        <div className='profile-card-content'>
            <div className='profile-card-hd'>User Logs</div>
            <Tables
                className="user_log_table"
                type={"userLog"}
                load={loading}
                scroll={({ x: 600 }, { y: 400 })}
                size="small"
                bordered
                perPage={100}
                source={data}
                col={columns}
                paginate
                pagination={{ pageSize: 100 }}
            />
            {/* <Table dataSource={dataSource} columns={columns} /> */}
        </div>

    )
}


const Card = ({ title = "", loading, data }) => {

    const findItem = data?.find((v) => moment(v.login_date).format("MM-DD-YYYY") === moment(new Date).format("MM-DD-YYYY"));

    const duration = findItem && moment.duration(findItem.total_spend_time);

    const hours = duration?.hours();
    const minutes = duration?.minutes();
    const seconds = duration?.seconds();

    console.log(`Hours: ${hours}, Minutes: ${minutes}, Seconds: ${seconds}`);

    console.log("findItem", findItem)
    return (
        <div className='profile-card-main'>
            <div className='profile-card-content'>
                <div className='profile-card-hd'>
                    {title}
                </div>
                <div className='profile-card-text'>
                    <div className='profile-card-hours-text'>
                        Hours : {hours}
                    </div>
                    <div className='profile-card-hours-text'>
                        Minutes : {minutes}
                    </div>
                    <div className='profile-card-hours-text'>
                        Second : {seconds}
                    </div>

                    {/* <div className='profile-card-text-right'>
                        Minutes : 56
                    </div> */}
                </div>
            </div>
        </div>
    )
}
import {
    GET_ALL_ITEM_ERROR, GET_ALL_ITEM_REQUEST, GET_ALL_ITEM_SUCCESS,
    GET_ONE_ITEM_ERROR, GET_ONE_ITEM_REQUEST, GET_ONE_ITEM_SUCCESS,
    ADD_ITEM_ERROR, ADD_ITEM_REQUEST, ADD_ITEM_SUCCESS,
    DELETE_ITEM_ERROR, DELETE_ITEM_REQUEST, DELETE_ITEM_SUCCESS,
    UPDATE_ITEM_ERROR, UPDATE_ITEM_REQUEST, UPDATE_ITEM_SUCCESS

} from "./types"

import { BASEURL } from "../../constant/config.js"

const axios = require("axios");
export const getAllItem = (token, filter) => dispatch => {
    dispatch({ type: GET_ALL_ITEM_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/item?filter=${filter}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
            return dispatch({ type: GET_ALL_ITEM_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ALL_ITEM_ERROR,
                payload: error.response,
            });
        })
}

export const getOneItem = (token, id) => dispatch => {
    dispatch({ type: GET_ONE_ITEM_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/item/${id}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
            return dispatch({ type: GET_ONE_ITEM_SUCCESS, payload: response.data.data[0] });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ONE_ITEM_ERROR,
                payload: error.response,
            });
        })
}

export const addItem = (token, data, random, inseams, sizes, image_url) => dispatch => {
    
    let newData = {
        description: data.description,
        company_id: data.company,
        item_code: data.sku,
        fit_category_id: data.fitCategory,
        gender_id: data.gender,
        season_year: data.seasonYear,
        season_id: data.season,
        cut_id: data.cut,
        fabric_id: data.fabric,
        wash_type_id: data.washType,
        wash_id: data.wash,
        style_id: data.style,
        gender_category_id: data.genderCategory,
        inseam_label_id: data.inseamLabel,
        product_type_id: data.productType,
        
        product_status_id: data.status,
        cost_price: data.cost,
        ldp_rate: data.ldpRate,
        rise_label_id: data.riseLabel,
        rise_id: data.rise,
        item_type_id: data.itemType,
        fabric_group: data.fabric_group,
        is_active: true,
        
        fabric_composition: data.fabricComposition,
        new_season_year: data.newSeasonYear,
        new_season_id: data.newSeason,
        
        
        product_class_id: data.productClass,
        rate: data.retailPrice,
        inseam: inseams.join(),
        size: sizes.join(),
        random: random?.map(v => ({ ...v, company_id: data.company, fabric_group: data.fabric_group })),
        image_url: image_url ? image_url : null
    }
    dispatch({ type: ADD_ITEM_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/item`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
        data: newData
    })
        .then(response => {
            return dispatch({ type: ADD_ITEM_SUCCESS, payload: response.data.p_internal_id[0].p_internal_id });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_ITEM_ERROR,
                payload: error.response,
            });
        })
}
export const updateItem = (token, data, id, from, random, inseams, sizes, imageURL) => dispatch => {
    let newData = {}
    if (from === "subItem") {
        if (data.nrfColorCode) {
            newData.nrf_color_code = data.nrfColorCode;
        }
        if (data.nrfSizeCode) {
            newData.nrf_size_code = data.nrfSizeCode;
        }
        if (data.upcCode) {
            newData.upc_code = data.upcCode;
        }

    } else {
        newData = {
            description: data.description,
            item_code: data.sku,
            fit_category_id: data.fitCategory,
            gender_id: data.gender,
            season_year: data.seasonYear,
            season_id: data.season,
            cut_id: data.cut,
            fabric_id: data.fabric,
            wash_type_id: data.washType,
            wash_id: data.wash,
            style_id: data.style,
            gender_category_id: data.genderCategory,
            inseam_label_id: data.inseamLabel,
            product_type_id: data.productType,
            
            product_status_id: data.status,
            cost_price: data.cost,
            ldp_rate: data.ldpRate,
            rise_label_id: data.riseLabel,
            rise_id: data.rise,
            is_active: true,
            upc_code: data.upcCode,
            fabric_composition: data.fabricComposition,
            new_season_year: data.newSeasonYear,
            new_season_id: data.newSeason,
            nrf_size_code: data.nrfSizeCode,
            nrf_color_code: data.nrfColorCode,
            product_class_id: data.productClass,
            rate: data.retailPrice,
            is_active: data.active,
            item_type_id: data.itemType,
            fabric_group: data.fabric_group,
            random: random.map(a => {
                return {
                    ...a, product_status_id: data.status, new_season_id: data.newSeason,fabric_group: data.fabric_group
                }
            }),
            inseam: inseams.join(),
            size: sizes.join(),
            image_url: imageURL ? imageURL : null

        }
    }
    dispatch({ type: UPDATE_ITEM_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/item/${id}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
        data: newData
    })
        .then(response => {
            return dispatch({ type: UPDATE_ITEM_SUCCESS, payload: response.data.data[0] });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_ITEM_ERROR,
                payload: error.response,
            });
        })
}

export const deleteItem = (token, id) => dispatch => {
    dispatch({ type: DELETE_ITEM_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/item/${id}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
            return dispatch({ type: DELETE_ITEM_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_ITEM_ERROR,
                payload: error.response,
            });
        })
}
import {
    GET_STATES_ERROR, GET_STATES_REQUEST, GET_STATES_SUCCESS,
    GET_DIVISION_STATS_REQUEST,
    GET_DIVISION_STATS_SUCCESS,
    GET_DIVISION_STATS_ERROR,

    GET_ACCOUNT_TYPE_STATS_REQUEST,
    GET_ACCOUNT_TYPE_STATS_SUCCESS,
    GET_ACCOUNT_TYPE_STATS_ERROR,

    GET_SALES_BY_MONTH_STATS_REQUEST,
    GET_SALES_BY_MONTH_STATS_SUCCESS,
    GET_SALES_BY_MONTH_STATS_ERROR,

    GET_RETURN_RATE_STATS_REQUEST,
    GET_RETURN_RATE_STATS_SUCCESS,
    GET_RETURN_RATE_STATS_ERROR,

    GET_AUC_STATS_SUCCESS,
    GET_AUC_STATS_REQUEST,
    GET_AUC_STATS_ERROR,

    GET_PLAN_ACTUAL_STATS_REQUEST,
    GET_PLAN_ACTUAL_STATS_SUCCESS,
    GET_PLAN_ACTUAL_STATS_ERROR,

    GET_STYLE_STATS_REQUEST,
    GET_STYLE_STATS_SUCCESS,
    GET_STYLE_STATS_ERROR,

    GET_FIT_CATEGORY_STATS_REQUEST,
    GET_FIT_CATEGORY_STATS_SUCCESS,
    GET_FIT_CATEGORY_STATS_ERROR,
    GET_FABRIC_STATS_REQUEST,
    GET_FABRIC_STATS_SUCCESS,
    GET_FABRIC_STATS_ERROR,

    GET_COUNTRY_STATS_REQUEST,
GET_COUNTRY_STATS_SUCCESS,
GET_COUNTRY_STATS_ERROR,
} from "../actions/types";

export const dashboardStats = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_STATES_REQUEST:
            return {
                loading: true,
            };
        case GET_STATES_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_STATES_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}


export const divisionStats = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_DIVISION_STATS_REQUEST:
            return {
                loading: true,
            };
        case GET_DIVISION_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_DIVISION_STATS_ERROR:
            return {
                ...state,
                loading: false
            };

        default: {
            return state;
        }
    }
};


export const accountTypeStats = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ACCOUNT_TYPE_STATS_REQUEST:
            return {
                loading: true,
            };
        case GET_ACCOUNT_TYPE_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ACCOUNT_TYPE_STATS_ERROR:
            return {
                ...state,
                loading: false
            };

        default: {
            return state;
        }
    }
};

export const salesByMonthStats = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_SALES_BY_MONTH_STATS_REQUEST:
            return {
                loading: true,
            };
        case GET_SALES_BY_MONTH_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_SALES_BY_MONTH_STATS_ERROR:
            return {
                ...state,
                loading: false
            };


        default: {
            return state;
        }
    }
};


export const returnRateStats = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_RETURN_RATE_STATS_REQUEST:
            return {
                loading: true,
            };
        case GET_RETURN_RATE_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_RETURN_RATE_STATS_ERROR:
            return {
                ...state,
                loading: false
            };


        default: {
            return state;
        }
    }
};

export const aucStats = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_AUC_STATS_REQUEST:
            return {
                loading: true,
            };
        case GET_AUC_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_AUC_STATS_ERROR:
            return {
                ...state,
                loading: false
            };


        default: {
            return state;
        }
    }
};


export const actualPlanStats = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_PLAN_ACTUAL_STATS_REQUEST:
            return {
                loading: true,
            };
        case GET_PLAN_ACTUAL_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_PLAN_ACTUAL_STATS_ERROR:
            return {
                ...state,
                loading: false
            };

        default: {
            return state;
        }
    }
};

export const styleStats = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_STYLE_STATS_REQUEST:
            return {
                loading: true,
            };
        case GET_STYLE_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_STYLE_STATS_ERROR:
            return {
                ...state,
                loading: false
            };

        default: {
            return state;
        }
    }
};


export const fitCategoryStats = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_FIT_CATEGORY_STATS_REQUEST:
            return {
                loading: true,
            };
        case GET_FIT_CATEGORY_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_FIT_CATEGORY_STATS_ERROR:
            return {
                ...state,
                loading: false
            };

        default: {
            return state;
        }
    }
};


export const fabricStats = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_FABRIC_STATS_REQUEST:
            return {
                loading: true,
            };
        case GET_FABRIC_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_FABRIC_STATS_ERROR:
            return {
                ...state,
                loading: false
            };

        default: {
            return state;
        }
    }
};

export const countryStats = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_COUNTRY_STATS_REQUEST:
            return {
                loading: true,
            };
        case GET_COUNTRY_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_COUNTRY_STATS_ERROR:
            return {
                ...state,
                loading: false
            };

        default: {
            return state;
        }
    }
};

import React, { useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button, Form, Input, DatePicker, Collapse, Row, Col, Select, Table,Spin } from "antd";
import Tables from "../../components/Table/Tables";
import { salesPersonListColumns } from "../../utils/cols";
import { getRights } from "../../redux/actions/userRights.action";
import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { useHistory } from "react-router-dom";
import { ReadCookie } from "../../utils/readCookie";
import { dateChecker } from "../../components/CheckDate/checkDate";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import { useCallback, useEffect } from "react";
import { getOrderStatus } from "../../redux/actions/orderStatus.action";
import { getFactor } from "../../redux/actions/factor.action";
import { createInvoiceEmail, createInvoiceEmailTest, getInvoiceEmail } from "../../redux/actions/invoiceEmail.action";
import notification from "../../utils/notification";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";

const InvoiceEmailTest = ({ token }) => {
    TabTitle("Invoice Email")
    const [form] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const { saleOrderFormData, orderStatus, factor, invoiceEmail } = useSelector((state) => state);
    const { Panel } = Collapse;
    const companyId = ReadCookie("defaultCompany");
    const menuId = ReadCookie("menuId");
    const [lines, setLines] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [excelData, setExcelData] = useState([])
    useEffect(() => {
        
        
        dispatch(getOrderStatus(token));
        dispatch(getFactor(token, menuId));

    }, [dispatch, token]);


    const onFinish = async (e) => {

        await form.validateFields().then((values) => {
            setLoading(true);
            dispatch(getInvoiceEmail(token, values)).then((res) => {
                if (res?.type === "GET_INVOICE_EMAIL_SUCCESS") {
                    
                    setLoading(false);
                    downloadExcelFileData(res?.payload?.data);
                    setLines(res?.payload?.data);
                }
            }).catch((error) => {
                setLoading(false);
              })
        })
    }
    function downloadExcelFileData(data) {
        setExcelData(
          data.map((v) => {
            
            return {
              "Invoice No)": v["invoice_header_no"],
              "Invoice Date": v["invoice_date"],
              "Customer Name": v["customer_name"],
              "Email": v["email"],
              "Term": v["term"],
            };
          })
        )
      }
    
    const columns = [
        {
            title: "Invoice No",
            dataIndex: "invoice_header_no",
            width: 100,
            fixed: 'left',

        },
        {
            title: "Invoice Date",
            dataIndex: "invoice_date",
            width: 120,
        },
        {
            title: "Customer Name",
            dataIndex: "customer_name",
            width: 140,
        },
        {
            title: "Email",
            dataIndex: "email",
            width: 140,
        },
        {
            title: "Term",
            dataIndex: "term",
            width: 140,
        },
        {
            title: "Email",
            dataIndex: "emailsent",
            width: 140,
        },
    ]


    function debounce(cb, delay = 1000) {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                cb(...args);
            }, delay);
        };
    }


    const handleChanges = (val) => {
        dispatch(getSaleOrderFormData(token, companyId, menuId, val, "")).then(
            (res) => {
                if (
                    res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
                    res?.payload.length === 0
                ) {
                    notification("warning", "Empty List");
                }
                if (res?.payload?.customerDetail?.length === 0) {
                }
            }
        );
    };

    const optimizedFn = useCallback(debounce(handleChanges), []);


    let result =
        selectedRows.length > 0 &&
        selectedRows?.reduce(function (r, a) {
            r[a.email] = r[a.email] || [];
            r[a.email].push(a);
            return r;
        }, Object.create(null));

    const selectedRow = result ? Object.values(result) : [];



    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        
        
        
        
        
    };

    const sendEmail = () => {
        selectedRow.map((resp)=> {
            dispatch(createInvoiceEmailTest(token, resp)).then((res) => {
                if (res?.type === "CREATE_INVOICE_EMAIL_SUCCESS") {
                    notification("success", "Customers", res?.payload);
                    setSelectedRows([]);
                    setLines([]);
                }
            });
        })
    }


    return (
        <div className="styleContainer">
            <div className="styleBody h-100">
                <PageHeader title={"Invoice Email"} />
                <div className="bodyStyle">
                    <Collapse
                        style={{ width: "99%", margin: "10px" }}
                        bordered={false}
                        defaultActiveKey={['1']}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    >
                        <Panel header="Filters" key="1" className="site-collapse-custom-panel" extra={<Button
                            onClick={() => {
                                history.push("/saleperson");
                            }}
                            className="iconBtn crudBTN"
                            shape="circle"
                        />}>
                            <Form
                                form={form}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Row>
                                    <Col xs={24} xl={8}>
                                        <Form.Item label="From Date" name="fromDate"
                                        >
                                            <DatePicker
                                                size="small"
                                                id="fromData"
                                                format="MM-DD-YYYY"
                                                allowClear
                                                className="dateInput customInputs"
                                                defaultValue={moment()}
                                                value={moment()}

                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} xl={8}>
                                        <Form.Item label="To Date" name="toDate"
                                        >
                                            <DatePicker
                                                size="small"
                                                id="toDate"
                                                format="MM-DD-YYYY"
                                                allowClear
                                                className="dateInput customInputs"
                                                defaultValue={moment()}
                                                value={moment()}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} xl={8}>
                                        <Form.Item
                                            name="customer"
                                            label="Customer"
                                        >
                                            <Select
                                                size="small"
                                                autoComplete={"nope"}
                                                allowClear
                                                showSearch
                                                optionFilterProp="children"
                                                loading={saleOrderFormData.loading}
                                                
                                                onSearch={(value) => optimizedFn(value)}
                                            >
                                                {saleOrderFormData?.payload?.customerDetail.map(
                                                    (item) => {
                                                        return (
                                                            <Select.Option
                                                                key={item.customer_id}
                                                                value={item.customer_id}
                                                            >
                                                                {" "}
                                                                {`${item.customer_code} - ${item.customer_name}`}
                                                            </Select.Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} xl={8}>
                                        <Form.Item
                                            label="Status"
                                            name="status"
                                        >
                                            <Select
                                                size="small"
                                                autoComplete={"nope"}
                                                allowClear
                                                loading={orderStatus.loading}
                                            
                                            
                                            
                                            >
                                                {orderStatus?.payload?.data.map(
                                                    (item) => {
                                                        return (
                                                            <Select.Option
                                                                key={item.order_status_id}
                                                                value={item.order_status_id}
                                                            >
                                                                {item.status_name}
                                                            </Select.Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} xl={8}>
                                        <Form.Item
                                            label="Payment Class"
                                            name="paymentClass"
                                        >

                                            <Select
                                                size="small"
                                                autoComplete={"nope"}
                                                filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                                                showSearch
                                                allowClear
                                                loading={factor.loading}
                                            
                                            
                                            
                                            
                                            >
                                                {factor?.payload?.data.map(
                                                    (item) => {
                                                        return (
                                                            <Select.Option
                                                                key={item.factor_id}
                                                                
                                                                value={item.factor_id}
                                                            >
                                                                {item.factor_name}
                                                            </Select.Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>


                                </Row>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </div>
                                {
                                    selectedRows.length > 0 ? (
                                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                                            <Button
                                                disabled={invoiceEmail.loading ? true : false}
                                                type="primary" onClick={sendEmail}>
                                                Send Email {selectedRows.length}
                                            </Button>
                                        </div>
                                    ) : null
                                }
                            </Form>
                        </Panel>
                    </Collapse>

                    <div className="mainProductContainer">
                        <div style={{ padding: 10 }}>
                        {
          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            lines.length>0?(
                            <Table
                            title={() =>
                                <CSVLink
                                  filename={"invoice-email.csv"}
                                  data={excelData}
                                  onClick={() => {
              
                                  }}
                                >
                                  Download Excel
                                </CSVLink>
                              }
                                rowSelection={{
                                    ...rowSelection,
                                }}
                                rowKey="invoice_header_id"
                                scroll={450}
                                perPage={100}
                                columns={columns}
                                dataSource={lines.length > 0 ? lines : []}
                                loading={lines?.loading}
                                paginate
                                pagination={{
                                    defaultCurrent: 1,
                                    defaultPageSize: 100,
                                    pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000],
                                  }}
                            />
            ):<div
            style={{
              backgroundColor: "#CCCCCC",
              borderWidth: "1pt",
              borderStyle: "Dashed",
              fontWeight: "bold",
              height: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No record(s)
          </div>
        )
      }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default InvoiceEmailTest;
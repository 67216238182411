import {
    ADMIN_LOGIN_REQUEST,
    ADMIN_LOGIN_ERROR,
    ADMIN_LOGOUT,
    ADMIN_LOGIN_SUCCESS
} from "../actions/types"
const user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ADMIN_LOGIN_REQUEST:
            return {
                loading: true,
            };
        case ADMIN_LOGIN_SUCCESS:
            // window.location = "/";
            return {
                ...state,
                loading: false,
                isLoggedIn: true,
                user: payload,
            };
        case ADMIN_LOGIN_ERROR:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                loading: false
            };
        case ADMIN_LOGOUT:
            // document.cookie.split(";").forEach(function (c) {
            //     document.cookie = c
            //         .replace(/^ +/, "")
            //         .replace(
            //             /=.*/,
            //             "=;expires=" + new Date().toUTCString() + ";path=/"
            //         );
            // });
            // window.location = "/login";
            return {
                isLoggedIn: false,
                user: null,
            };

        default:
            return state;
    }
}
import React, { useEffect, useCallback, useRef, useState } from "react";
import "./CustomerPaymentLedger.css";
import { Select, DatePicker, Form, Table, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { getAllScmBrand } from "../../redux/actions/scmBrand.action";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { loadCustomers } from "../../redux/actions/customerForm";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { Excel } from "antd-table-saveas-excel";
import moment from "moment";
import { useParams } from "react-router-dom";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";
import { getPaymentLedgerSummaryReport } from "../../redux/actions/paymentLedgerSummaryReport.action";

const { Option } = Select;
const dateFormatList = ["MM/DD/YYYY"];

const PaymentLedgerSummaryReport = ({ token }) => {
  TabTitle("Payment Ledger")
  const userName = ReadCookie("name");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const focusInput = useRef(null);
  const tableRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [customerList, setCutomerList] = useState([]);
  const [customerId, setCutomerId] = useState([]);
  const [excelData, setExcelData] = useState([])
  const {
    customerForms,
    paymentSummary
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const [customer, setCustomer] = useState("All");
  const { submited } = useParams();



  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    if (val === "" || val === "All") return;
    setCustomer(val);
    dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null, null)).then(
      (res) => {
        if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
          setCustomer(res?.payload?.data);
        }
      }
    );
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);
  let rbalance = 0;

  const onFinish = async (e) => {
    setLoader(true)
    await form.validateFields().then((values) => {
      dispatch(getPaymentLedgerSummaryReport(token, values)).then((res) => {
        
        let filterData = res?.payload?.data && res?.payload?.data.filter((elem) => {
          return elem.invoice_header_id != "0";
        })
        
        let filterZeroId = res?.payload?.data && res?.payload?.data.filter((elem) => {
          return elem.invoice_header_id == "0";
        })
        

        setCutomerId(filterZeroId)
        setCutomerList(filterData)
        setLoader(false);
        downloadExcelFileData(res?.payload?.data);
      })
    });
  };

  useEffect(() => {
    dispatch(getGender(token, menuId));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getAllCompany(token));
    dispatch(getAllScmBrand(token));
    dispatch(getCustomerType(token));
  }, [dispatch, menuId, token]);

  const handlePrint = () => {
    window.print();
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Customer": v.customer_name,
          "Invoice Amount": v.invoice_amount,
          "Note Amount": v.note_amount,
          "Payment Amount": v.amount_paid,
          "Refund Amount": v.refund_amount,
          "CN Apply": v.credit_note_apply,
          "CM Amount": v.credit_memo_amount,
          "CM Apply": v.credit_memo_apply,
          "UnApplied Amount": v.unapplied_amount,
          "Net Balance": v.net_balance,
        };
      })
    )
  }


  const column = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoice_amount",
      key: "invoice_amount",
      align: "right",
      render: (text, record) => {
        return '$' + Number(record.invoice_amount).toFixed(2)
      }
    },
    {
      title: "Note Amount",
      dataIndex: "note_amount",
      key: "note_amount",
      align: "right",
      render: (text, record) => {
        return '$' + Number(record.note_amount).toFixed(2)
      }
    },
    {
      title: "Payment Amount",
      dataIndex: "amount_paid",
      key: "amount_paid",
      align: "right",
      render: (text, record) => {
        return '$' + Number(record.amount_paid).toFixed(2)
      }
    },
    {
      title: "Refund Amount",
      dataIndex: "refund_amount",
      key: "refund_amount",
      align: "right",
      render: (text, record) => {
        return '$' + Number(record.refund_amount).toFixed(2)
      }
    },
    {
      title: "CN Apply",
      dataIndex: "credit_note_apply",
      key: "credit_note_apply",
      align: "right",
      render: (text, record) => {
        return '$' + Number(record.credit_note_apply).toFixed(2)
      }
    },
    {
      title: "CM Amount",
      dataIndex: "credit_memo_amount",
      key: "credit_memo_amount",
      align: "right",
      render: (text, record) => {
        return '$' + Number(record.credit_memo_amount).toFixed(2)
      }
    },
    {
      title: "CM Apply",
      dataIndex: "credit_memo_apply",
      key: "credit_memo_apply",
      align: "right",
      render: (text, record) => {
        return '$' + Number(record.credit_memo_apply).toFixed(2)
      }
    },
    {
      title: "UnApplied Amount",
      dataIndex: "unapplied_amount",
      key: "unapplied_amount",
      align: "right",
      render: (text, record) => {
        return '$' + Number(record.unapplied_amount).toFixed(2)
      }
    },
    {
      title: "Net Balance",
      dataIndex: "net_balance",
      key: "net_balance",
      align: "right",
      render: (text, record) => {
        return '$' + Number(record.net_balance).toFixed(2)
      }
    },
  ];

  const invoice_Amount = customerList.reduce((acc, item, index) => acc + parseFloat(item.invoice_amount), 0)
  const note_Amount = customerList.reduce((acc, item, index) => acc + parseFloat(item.note_amount), 0)
  const payment_amount = customerList.reduce((acc, item, index) => acc + parseFloat(item.amount_paid), 0)
  const refund_amount = customerList.reduce((acc, item, index) => acc + parseFloat(item.refund_amount), 0)
  const cn_apply = customerList.reduce((acc, item, index) => acc + parseFloat(item.credit_note_apply), 0)
  const cm_amount = customerList.reduce((acc, item, index) => acc + parseFloat(item.credit_memo_amount), 0)
  const cm_apply = customerList.reduce((acc, item, index) => acc + parseFloat(item.credit_memo_apply), 0)
  const unapplied_amount = customerList.reduce((acc, item, index) => acc + parseFloat(item.unapplied_amount), 0)
  const net_amount = customerList.reduce((acc, item, index) => acc + parseFloat(item.net_balance), 0)






  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="report-wrapper-main header_class business_class ledger_print">
            <div className="row business_header justify-content-between pb-2 ledger_print">
              <div className="col-3 d-flex align-items-start  box-space">
                <div className="logobrand">
                  <img id="ImgLogo" alt="logo" src={logo} />
                </div>
              </div>

              <div className="col-4 box-space right-content-header text-right pb-0">
                <div className=" d-flex justify-content-end">
                  <div className=" d-flex printing_class mr-8">
                    <div className="print_icon printing_class">
                      <i
                        onClick={handlePrint}
                        id="Image1"
                        title="Print"
                        alt="Print"
                        src={btnPrint}
                        className="fa-solid fa-print"
                      ></i>
                    </div>
                    <div className="print_icon printing_class" >
                      <CSVLink
                        filename={"Pyament-ledger-Summary-Report.csv"}
                        data={excelData}
                        onClick={() => {
                        }}
                      >
                        <input
                          type="image"
                          name="ImgExcel"
                          alt="Export to excel"
                          id="ImgExcel"
                          title="Export to excel"
                          src={xlsx}
                          
                          
                          style={{ borderWidth: "0px", height: "14px" }}
                        />
                      </CSVLink>
                    </div>
                  </div>
                  <span style={{ fontWeight: "bolder" }}>
                    Payment Ledger Summary Report
                    <br />

                    <div>
                      Rpt # 489
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            {/* Filters Table */}
            <div className="row  business_header ledger_print">
              <div className="col business_header">
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                  <div className="main_form row">

                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label htmlFor="" className="labels width30">
                          From:
                        </label>
                        <div className=" w-100">
                          <Form.Item
                            className="full_date"
                            name="from_date"
                            initialValue={moment("2010-01-01", "YYYY-MM-DD")}
                          >
                            <DatePicker
                              size="small"
                              defaultValue={moment().format("MM-DD-YYYY")}
                              format={dateFormatList}
                              className={"input-width"}
                            
                            />
                          </Form.Item>
                        </div>
                      </div>

                    </div>
                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label htmlFor="" className="labels width30">
                          To:
                        </label>
                        <div className=" w-100">
                          <Form.Item
                            className="full_date"
                            name="to_date"
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size="small"
                              defaultValue={moment().format("MM-DD-YYYY")}
                              format={dateFormatList}
                              className={"input-width"}
                            
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main_form row">

                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label className="labels width30">Customer:</label>
                        <div className=" w-100">
                          <Form.Item
                            name="customer_id"
                            
                            
                            
                            
                            
                            
                          >
                            <Select
                              showSearch
                              allowClear
                              optionFilterProp="children"
                              className={"select_Inputs"}
                              loading={customerForms.loading}
                              
                              size="small"
                              onSearch={(value) => {
                                optimizedFn(value);
                              }}
                              ref={focusInput}
                              value={customer}
                            >
                              {customerForms?.payload?.data.map((item) => {
                                return (
                                  <Option
                                    key={item.customer_id}
                                    value={item.customer_id}
                                  >
                                    {" "}
                                    {`${item.customer_code} ${item.customer_name}`}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className=" col-6">
                      <div className=" d-flex flex-row align-items-baseline">
                        <label className="labels width30">Status:</label>
                        <div className=" w-100">
                          <Form.Item
                            className=" w-100"
                            name="status"
                            initialValue={"All"}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              
                              className="select_Inputs"
                              
                              defaultValue={
                                submited ? form.getFieldValue().status : "All"
                              }
                              size="small"
                            >
                              <Option key={"All"} value={"All"}>
                                All
                              </Option>
                              <Option key={"P"} value={"P"}>
                                Paid
                              </Option>
                              <Option key={"U"} value={"U"}>
                                Unpaid
                              </Option>
                              {/* <Option key={"E"} value={"E"}>
                                Excess Paid
                              </Option> */}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div>
                        <Button
                          htmlType="submit"
                          type="primary"
                          className="submit-btn-btm "
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* Render Data Table */}
          </div>
          <div className="table_content customer_ledger_table_content ledger_print"
            style={{ maxWidth: '1160px' }}
          >
            <div className="">
              {/* {paymentSummary ? (
                paymentSummary?.payload?.data.length > 0 ? (
                  (
                    <div className="data_Table">
                      <div >
                        <div
                          
                          style={{ margin: "0 auto", paddingTop: "10px" }}
                        >
                          <table id="Table1" cellPadding="3" cellSpacing="0" align="center" className="tablestyleControls" style={{ fontSize: "10px" }}>
                            <tbody>
                              <tr>
                                <td className="font_weight">
                                  Name :
                                </td>
                                <td style={{ width: "406px" }} colSpan={3}>
                                  <b>

                                    {paymentSummary?.payload?.data[0].customer_name}
                                  </b>
                                </td>

                              </tr>
                              <tr>
                                <td className="font_weight">
                                  Address :
                                </td>
                                <td colSpan={3}>
                                  <b>
                                    {paymentSummary?.payload?.data[0].address_1}
                                  </b>
                                </td>

                              </tr>
                              <tr>
                                <td className="no-border-btm font_weight" style={{ width: "70px" }}>
                                  City :
                                </td>
                                <td style={{ width: "263px" }}>
                                  <b>
                                    {paymentSummary?.payload?.data[0].city_name}
                                  </b>
                                </td>
                                <td style={{ width: "126px" }} className="font_weight">
                                  State :
                                </td>
                                <td style={{ width: "119.6px" }} >
                                  <b>
                                    {paymentSummary?.payload?.data[0].state_name}
                                  </b>
                                </td>

                              </tr>
                              <tr>
                                <td className="font_weight">
                                  Country :
                                </td>
                                <td>
                                  <b>
                                    {paymentSummary?.payload?.data[0].country_name}
                                  </b>
                                </td>
                                <td className="no-border-btm font_weight">
                                  Zip Code :
                                </td>
                                <td>
                                  <b>
                                    {paymentSummary?.payload?.data[0].zip_code}
                                  </b>
                                </td>
                              </tr>
                            </tbody >
                          </table >
                        </div>
                      </div>
                    </div>
                  )

                ) : <></>) : <></>} */}
              <Table
                className="
                
                test04 mt-3 sales_report_res_table_intransit ledger_print"
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                headerClassName='abc'
                dataSource={customerList && customerList}
                columns={column}
                loading={paymentSummary.loading}
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 100,
                  pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                }}
                summary={(pageData) => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                            ${invoice_Amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                          ${note_Amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                          ${payment_amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                          ${refund_amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                          ${cn_apply.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                          ${cm_amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                          ${cm_apply.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                          ${unapplied_amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right", margin: "0" }} className="totalquantity">
                          ${net_amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentLedgerSummaryReport;

import {
  GET_SALEORDERFORMDATA_REQUEST,
  GET_SALEORDERFORMDATA_SUCCESS,
  GET_SALEORDERFORMDATA_ERROR,
  ADD_SALEORDER_HEADER_REQUEST,
  ADD_SALEORDER_HEADER_SUCCESS,
  ADD_SALEORDER_HEADER_ERROR,
  GET_ALL_SALEORDER_REQUEST,
  GET_ALL_SALEORDER_SUCCESS,
  GET_ALL_SALEORDER_ERROR,
  DELETE_SALEORDERHEADER_REQUEST,
  DELETE_SALEORDERHEADER_SUCCESS,
  DELETE_SALEORDERHEADER_ERROR,
  UPDATE_SALEORDER_HEADER_REQUEST,
  UPDATE_SALEORDER_HEADER_ERROR,
  UPDATE_SALEORDER_HEADER_SUCCESS,
  GET_ONE_SALEORDERHEADER_SUCCESS,
  GET_ONE_SALEORDERHEADER_REQUEST,
  GET_ONE_SALEORDERHEADER_ERROR,
  UPDATE_SALE_ORDER_ISCLOSED_REQUEST,
  UPDATE_SALE_ORDER_ISCLOSED_SUCCESS,
  UPDATE_SALE_ORDER_ISCLOSED_ERROR,
} from "./types";
import moment from "moment";
import { BASEURL } from "../../constant/config.js";
import { FormProvider } from "antd/lib/form/context";
import numeral from "numeral";
const axios = require("axios");

export const getSaleOrderFormData =
  (token, companyId, menuId, customerName, customerId, clear) => (dispatch) => {
    dispatch({ type: GET_SALEORDERFORMDATA_REQUEST });

    if (clear) {
      return dispatch({
        type: GET_SALEORDERFORMDATA_SUCCESS,
        payload: {
          berganPaymentTerm: [],
          termForPrint: [],
          factorDetail: [],
          customerDetail: [],
          adminShipTaxCode: [],
          adminShipMethod: [],
          shipVia: [],
          customerTerm: [],
          freightTerm: [],
          berganBilling: [],
          salesPersonDetail: [],
          adminRegionDetail: [],
          orderStatusDetail: [],
          gender: [],
          locationDetail: [],
        },
      });
    }
    return axios({
      method: "POST",
      url: `${BASEURL}getAll/formByCompany`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        company_id: companyId,
        menu_id: menuId,
        customer_name: customerName,
        customer_id: customerId,
      },
    })
      .then((response) => {
        return dispatch({
          type: GET_SALEORDERFORMDATA_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_SALEORDERFORMDATA_ERROR,
          payload: error.response,
        });
      });
  };

export const getOneSaleOrderHeader = (token, id) => (dispatch) => {
  dispatch({ type: GET_ONE_SALEORDERHEADER_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/saleOrderHeader/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ONE_SALEORDERHEADER_SUCCESS,
        payload: response?.data.data[0],
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_SALEORDERHEADER_ERROR,
        payload: error.response,
      });
    });
};

export const addSaleOrderHeader =
  (token, formData, company_id, orderItems) => (dispatch) => {



    let uniqueOrderItems = orderItems.filter(
      (v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
    );
    let newData = {
      commit_status: orderItems.commit_status,
      bergan_billing_id: formData.berganBillingId,
      bergan_payment_term_id: formData.berganPaymentTermId,
      billing_address_id: formData.billingCustomer,
      cancel_date: moment(formData.cancelDate).format("MM-DD-YYYY"),
      company_id: company_id,
      customer_id: formData.customer,
      customer_store_no: formData.store,
      dc_no: formData.dc,
      discount_id: formData.discountId,
      discount_code: formData.discountCode,
      discount_basis: formData.discountBasis,
      discount_amount: numeral(formData.discount).format("00.00"),
      discount_percent: formData.discountPercent,
      department_description: formData.deptDescription,
      division_code: formData.divisionCode,
      division_description: formData.divisionDescription,
      end_date: moment(formData.cancelDate).format("MM-DD-YYYY"),
      factor_id: formData.factor,
      freight_amount: formData.freightAmount,
      freight_charges: formData.freightCharges,
      freight_term_id: formData.freightTermId,
      gender_id: formData.gender,
      is_closed: formData.isClosed,
      order_hold: formData.order_hold,
      is_ship_complete: formData.isShipComplete,
      location_id: formData.location,
      mark_email: formData.markForEmail,
      mark_for_address_1: formData.markForAddress1,
      mark_for_address_2: formData.markForAddress2,
      mark_for_city: formData.markForCity,
      mark_for_code: formData.markForCode,
      mark_for_company_name: formData.markForCompanyName,
      mark_for_country: formData.markForCountry,
      mark_for_phone: formData.markForPhone,
      mark_for_state: formData.markForState,
      mark_for_zip: formData.markForZip,
      order_date: moment(formData.orderDate).format("MM-DD-YYYY"),
      order_status_id: formData.orderStatus,
      payment_term_id: formData.paymentTermId ? formData.paymentTermId : null,
      randomData: uniqueOrderItems,
      reference_no: formData.po,
      region_id: formData.region,
      release_certificate_date: formData.resaleCertificateDate,
      release_certificate_no: formData.resaleCertificateNo,
      remarks: formData.remarks,
      surcharge_amount: formData.surcharge_amount,
      sales_person_id: formData.salesPerson,
      salesPerson: formData.sales_person_id,
      ship_account_no: formData.shippingAccountNo,
      ship_via_id: formData.shipViaId,
      shipping_address_id: formData.shippingCustomer,
      shipping_cost: formData.shippingCost ? formData.shippingCost : 0,
      shipping_method_id: formData.shippingMethodId,
      shipping_tax_code: formData.shippingTaxCode,
      shipping_tax_rate: formData.shippingTaxRate,
      source_type: formData.sourceType,
      sp_region_category_id: formData.category,
      start_date: moment(formData.startDate).format("MM-DD-YYYY"),
      status: formData.order_status_id,
      term_for_print: formData.termForPrint,
      text_percent: formData.textPercent,
      memo: formData.memo,
      subsidiary_name: formData.subsidary,
      external_ref_id: formData.refID,
      department_code: formData.deptCode,
      ship_to_code: formData.shipToCode,
      special_instructions: formData.specialInstructions,
      factor_decision: formData.factor_decision,
      billing_firstName: formData.billing_firstname ? formData.billing_firstname : null,
      billing_lastName: formData.billing_lastname ? formData.billing_lastname : null,
      shipping_firstName: formData.shipping_firstname ? formData.shipping_firstname : null,
      shipping_lastName: formData.shipping_lastname ? formData.shipping_lastname : null,

      bill_to_non_us: formData.bill_to_non_us ? formData.bill_to_non_us : null,
      ship_to_non_us: formData.ship_to_non_us ? formData.ship_to_non_us : null,
      order_type_id: formData.order_type ? formData.order_type : null,
      tax_duties: formData.tax_duties ? formData.tax_duties : null,
      
    };

    dispatch({ type: ADD_SALEORDER_HEADER_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}create/saleOrderHeader`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: newData,
    })
      .then((response) => {
        return dispatch({
          type: ADD_SALEORDER_HEADER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: ADD_SALEORDER_HEADER_ERROR,
          payload: error.response,
        });
      });
  };

export const updateSaleOrderHeader =
  (
    token,
    formData,
    saleOrderID,
    company_id,
    orderItems,
    shippingID,
    billingID
  ) =>
    (dispatch) => {

      let uniqueOrderItems = orderItems.filter(
        (v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
      );
      let test = uniqueOrderItems.map((item) => {
        return {
          ...item,
          discount: numeral(item.discount).format("00.00")
        }
      });


      

      let newData = {
        commit_status: orderItems.commit_status,
        bergan_billing_id: formData.berganBillingId,
        bergan_payment_term_id: formData.berganPaymentTermId,
        billing_address_id: formData.billingCustomer,
        cancel_date: moment(formData.cancelDate).format("MM-DD-YYYY"),
        company_id: company_id,
        customer_id: formData.customer,
        customer_store_no: formData.store,
        dc_no: formData.dc,
        department_description: formData.deptDescription,
        division_code: formData.divisionCode,
        division_description: formData.divisionDescription,
        discount_id: formData.discountId,
        discount_code: formData.discountCode,
        discount_amount: formData.discount == "" ? null : numeral(formData.discount).format("00.00"),
        discount_percent: formData.discountPercent,
        discount_basis: formData.discountBasis,
        end_date: moment(formData.cancelDate).format("MM-DD-YYYY"),
        factor_id: formData.factor,
        freight_amount: formData.freightAmount,
        freight_charges: formData.freightCharges,
        freight_term_id: formData.freightTermId,
        gender_id: formData.gender,
        is_closed: formData.is_closed,
        order_hold: formData.order_hold,
        is_ship_complete: formData.isShipComplete,
        location_id: formData.location,
        mark_email: formData.markForEmail,
        mark_for_address_1: formData.markForAddress1,
        mark_for_address_2: formData.markForAddress2,
        mark_for_city: formData.markForCity,
        mark_for_code: formData.markForCode,
        mark_for_company_name: formData.markForCompanyName,
        mark_for_country: formData.markForCountry,
        mark_for_phone: formData.markForPhone,
        mark_for_state: formData.markForState,
        mark_for_zip: formData.markForZip,
        order_date: moment(formData.orderDate).format("MM-DD-YYYY"),
        order_status_id: formData.orderStatus,
        payment_term_id: formData.paymentTermId,
        randomData: test,
        reference_no: formData.po,
        region_id: formData.region,
        release_certificate_date: formData.resaleCertificateDate,
        release_certificate_no: formData.resaleCertificateNo,
        remarks: formData.remarks,
        surcharge_amount: formData.surcharge_amount,
        sales_person_id: formData.salesPerson,
        salesPerson: formData.sales_person_id,
        ship_account_no: formData.shippingAccountNo,
        ship_via_id: formData.shipViaId,
        shipping_address_id: formData.shippingCustomer,
        shipping_cost: formData.shippingCost,
        shipping_method_id: formData.shippingMethodId,
        shipping_tax_code: formData.shippingTaxCode,
        shipping_tax_rate: formData.shippingTaxRate,
        source_type: formData.sourceType,
        sp_region_category_id: formData.category,
        start_date: moment(formData.startDate).format("MM-DD-YYYY"),
        status: formData.order_status_id,
        term_for_print: formData.termForPrint,
        text_percent: formData.textPercent,
        memo: formData.memo,
        subsidiary_name: formData.subsidary,
        external_ref_id: formData.refID,
        department_code: formData.deptCode,
        ship_to_code: formData.shipToCode,
        special_instructions: formData.specialInstructions,
        factor_decision: formData.factor_decision,
        billing_firstName: formData.billing_firstname ? formData.billing_firstname : " ",
        billing_lastName: formData.billing_lastname ? formData.billing_lastname : " ",
        shipping_firstName: formData.shipping_firstname ? formData.shipping_firstname : " ",
        shipping_lastName: formData.shipping_lastname ? formData.shipping_lastname : " ",

        
        

        bill_to_non_us: formData.bill_to_non_us ? formData.bill_to_non_us : null,
        ship_to_non_us: formData.ship_to_non_us ? formData.ship_to_non_us : null,
        order_type_id: formData.order_type ? formData.order_type : null,
        tax_duties: formData.tax_duties ? formData.tax_duties : null,
        
      };

      dispatch({ type: UPDATE_SALEORDER_HEADER_REQUEST });

      return axios({
        method: "PUT",

        url: `${BASEURL}update/saleOrderHeader/${saleOrderID}`,

        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        data: newData,
      })
        .then((response) => {
          return dispatch({
            type: UPDATE_SALEORDER_HEADER_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          return dispatch({
            type: UPDATE_SALEORDER_HEADER_ERROR,
            payload: error.response,
          });
        });
    };

export const getAllSalesOrderHeader = (token, data) => (dispatch) => {
  dispatch({ type: GET_ALL_SALEORDER_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/saleOrderHeader`,
    headers: { Accept: "application/json", Authorization: "Bearer " + token },
    data: {
      order_header_no: data.order_header_no === "" ? null : data.order_header_no,
      from_date: moment(data.from_date).format("MM-DD-YYYY"),
      to_date: moment(data.to_date).format("MM-DD-YYYY"),
      po_no: data.po_no === "" ? null : data.po_no,
      customer_code: data.customer_code === "" ? null : data.customer_code,
      sales_person_name: data.sales_person_name === "" ? null : data.sales_person_name,
      region_name: data.region_name === "" ? null : data.region_name,
      gender_name: data.gender_name === "" ? null : data.gender_name,
      term_name: data.term_name === "" ? null : data.term_name,
      sp_region_category_id: null,
      company_id: 2
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_SALEORDER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_SALEORDER_ERROR,
        payload: error.response,
      });
    });
};


export const deleteSaleOrderHeader = (token, id) => (dispatch) => {
  dispatch({ type: DELETE_SALEORDERHEADER_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/saleOrderHeader/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    
    
    
  })
    .then((response) => {
      return dispatch({
        type: DELETE_SALEORDERHEADER_SUCCESS,
        payload: response?.data.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_SALEORDERHEADER_ERROR,
        payload: error.response,
      });
    });
};


export const updateIsClosed = (token, id) => (dispatch) => {
  dispatch({ type: UPDATE_SALE_ORDER_ISCLOSED_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/isClosedUpdate/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: UPDATE_SALE_ORDER_ISCLOSED_SUCCESS,
        payload: response?.data.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_SALE_ORDER_ISCLOSED_ERROR,
        payload: error.response,
      });
    });
};

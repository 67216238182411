import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getRights } from "../../redux/actions/userRights.action";
import { loadCountry } from "../../redux/actions/adminCountry";
import { loadState } from "../../redux/actions/adminState";
import { loadCity } from "../../redux/actions/adminCity";

import Tables from "../../components/Table/Tables";
import { Button, Form, Input, Modal, Select, Switch, Tooltip, Popconfirm } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, FilterFilled, SearchOutlined } from "@ant-design/icons";
import { ReadCookie } from "../../utils/readCookie";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { getOneCustomerAddress } from "../../redux/actions/customerAddress.action";

const { confirm } = Modal;
const { Option } = Select;

export default function BillingSection({ customer_id, token, formType, billingAddress, setBillingAddress, deletedBillingLine, setDeletedBillingLine }) {
  const { Billing, City, State, userRights, country, customerAddress } = useSelector((state) => state);
  const [biliingObj, setBillingObj] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [countryForm, setCountry] = useState({});
  const [visible, setVisible] = useState(false);
  const [stateForm, setState] = useState({});

  const [cityForm, setCity] = useState({});
  const menuId = ReadCookie("menuId");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isDefaultActive, setIsDefaultActive] = useState(false);
  const [isDefaultBill, setIsDefaultBill] = useState(false);
  const [isDefaultShip, setIsDefaultShip] = useState(false);
  const [itemIndex, setItemIndex] = useState(null);

  const billingColumns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.first_name &&
        record.first_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.first_name - b.first_name;
      },
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.last_name &&
        record.last_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.last_name - b.last_name;
      },
    },
    {
      title: "Addressee",
      dataIndex: "address_addressee",
      key: "address_addressee",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.addressee
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.addressee - b.addressee;
      },
    },
    {
      title: "Address 1",
      dataIndex: "address_address_1",
      key: "address_address_1",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.address_address_1
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.address_address_1 - b.address_address_1;
      },
    },
    {
      title: "Address 2",
      dataIndex: "address_address_2",
      key: "address_address_2",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.address_address_2
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.address_address_2 - b.address_address_2;
      },
    },
    {
      title: "City",
      dataIndex: "address_city_name",
      key: "address_city_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.city_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.city_name - b.city_name;
      },
    },
    {
      title: "State",
      dataIndex: "address_state_name",
      key: "address_state_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.state_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.state_name - b.state_name;
      },
    },
    {
      title: "Zip",
      dataIndex: "address_zip_code",
      key: "address_zip_code",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.zip_code
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.zip_code - b.zip_code;
      },
    },
    {
      title: "Country",
      dataIndex: "address_country_name",
      key: "address_country_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.country_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.country_name - b.country_name;
      },
    },
    {
      title: "Active",
      key: "action",
      render: (text, record) => {
        return (
          <>
            {record.is_active === false ? "False" : "True"}
          </>
        )
      }
    },
    {
      title: "Default Billing",

      key: "action",
      render: (text, record) => {


        return (
          <>
            {record.default_bill_to === false ? "False" : "True"}
          </>
        )
      }
    },
    {
      title: "Default Shipping",
      key: "action",
      render: (text, record) => (
        <>
          {record.default_ship_to === false ? "False" : "True"}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => {

        return (
          <>
            {
              formType !== "formView" && (
                <>
                  <Tooltip title="Edit" color={"white"}>
                    <EditOutlined
                      className={`edit-Button`}
                      onClick={() => {
                        hanldeSubItems(record, formType === "add" ? index : billingAddress.findIndex((v) => v.customer_address_id === record.customer_address_id))
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Delete" color={"white"}>
                    <Popconfirm
                      title={`Are you sure to delete?`}
                      onConfirm={() => hanldeDeleteItems(record, formType === "add" ? index : billingAddress.findIndex((v) => v.customer_address_id === record.customer_address_id))}
                    >
                      <DeleteOutlined className={`delete-Button`} />
                    </Popconfirm>
                  </Tooltip>
                </>
              )
            }

          </>
        )
      },
    }
  ];

  useLayoutEffect(() => {
    dispatch(getRights(token, menuId));
  }, []);

  useEffect(() => {
    if (formType === "add") {
      return setBillingAddress([]);
    }
    if (customer_id !== "" && formType === "formView") {
      setTimeout(() => {
        dispatch(getOneCustomerAddress(token, customer_id)).then((res) => {
          setBillingAddress(res?.payload?.data);
        });
      }, 2000);
    }
  }, [customer_id]);

  const hanldeSubItems = (record, index) => {
    console.log("record", record);
    console.log("record formType", form.getFieldValue());
    dispatch(loadCountry(token));
    setEventType("edit");
    setVisible(true);

    setItemIndex(index);

    form.setFieldsValue({
      first_name: record.first_name,
      last_name: record.last_name,
      address_addressee: record.address_addressee,
      address_address_1: record.address_address_1,
      address_address_2: record.address_address_2,
      address_country_name: record.address_country_name,
      address_city_name: record.address_city_name,
      address_state_name: record.address_state_name,
      email: record.email,
      fax: record.fax,
      phone: record.phone,
      address_zip_code: record.address_zip_code,
      is_active: record.is_active,
      default_bill_to: record.default_bill_to,
      default_ship_to: record.default_ship_to,
    })

    // By default Set CITY, COUNTRY, STATE on Edit Mode...
    setCity({
      children: record.address_city_name,
      value: record.address_city_id,
    })
    setCountry({
      children: record.address_country_name,
      value: record.address_country_id,
    })
    setState({
      children: record.address_state_name,
      value: record.address_state_id,
    })

    setIsDefaultActive(record.is_active);
    setIsDefaultBill(record.default_bill_to);
    setIsDefaultShip(record.default_ship_to);

    dispatch(loadCity(token, record.address_city_id));
    dispatch(loadState(token, record.address_state_id));
  };

  const hanldeDeleteItems = (record, index) => {
    const dataSource = [...billingAddress];
    const deleteDataSource = [...deletedBillingLine];
    deleteDataSource.push(record);
    setDeletedBillingLine(deleteDataSource)
    setBillingAddress(dataSource.filter((item, i) => i !== index));

  };

  const onChangeCountry = (value, obj) => {
    setCountry(obj);
    dispatch(loadCity(token, `${value}`));
    dispatch(loadState(token, `${value}`));
  };

  const onChangeCity = (value, obj) => {
    setCity(obj);
  };

  const onChangeState = (value, obj) => {
    setState(obj);
  };

  const onSearch = (val) => { };



  const handleOk = async (data) => {
    // Add 
    if (eventType === "add") {
      await form.validateFields().then((values) => {

        if (isDefaultBill) {
          billingAddress.map((v) => v.default_bill_to = false);
        }
        if (isDefaultShip) {
          billingAddress.map((v) => v.default_ship_to = false);
        }

        values.default_bill_to = isDefaultBill;
        values.default_ship_to = isDefaultShip;
        values.is_active = isDefaultActive;


        const data = [...billingAddress, ({
          ...values,
          address_city_name: cityForm.children,
          address_country_name: countryForm.children,
          address_state_name: stateForm.children,
          country_id: values.address_country_name,
          city_id: values.address_city_name,
          state_id: values.address_state_name,
          is_edited: true
        })]
        setBillingAddress(data);

        form.setFieldsValue({
          first_name: null,
          last_name: null,
          address_addressee: null,
          address_address_1: null,
          address_address_2: null,
          address_country_name: null,
          address_city_name: null,
          address_state_name: null,
          email: null,
          fax: null,
          phone: null,
          address_zip_code: null,
          is_active: null,
          default_bill_to: null,
          default_ship_to: null,
        })
        setIsDefaultActive(false);
        setIsDefaultBill(false);
        setIsDefaultShip(false);
        setVisible(false);
        setEventType(null);
      });
    } else if (eventType === "edit") {
      await form.validateFields().then((values) => {
        if (isDefaultBill) {
          billingAddress.map((v, idx) => {
            v.default_bill_to = false;
            if (idx === 0 || idx === 1) {
              v.is_edited = true;
            }
            return v
          });
        }
        if (isDefaultShip) {
          billingAddress.map((v, idx) => {
            v.default_ship_to = false
            if (idx === 0 || idx === 1) {
              v.is_edited = true;
            }
            return v
          });
        }

        let copyBillingAddress = [...billingAddress];
        copyBillingAddress[itemIndex].first_name = values.first_name;
        copyBillingAddress[itemIndex].last_name = values.last_name;
        copyBillingAddress[itemIndex].address_addressee = values.address_addressee;
        copyBillingAddress[itemIndex].address_address_1 = values.address_address_1;
        copyBillingAddress[itemIndex].address_address_2 = values.address_address_2;
        copyBillingAddress[itemIndex].address_country_name = countryForm.children;
        copyBillingAddress[itemIndex].address_city_name = cityForm.children;
        copyBillingAddress[itemIndex].address_state_name = stateForm.children;
        copyBillingAddress[itemIndex].country_id = countryForm.value;
        copyBillingAddress[itemIndex].city_id = cityForm.value;
        copyBillingAddress[itemIndex].state_id = stateForm.value;
        copyBillingAddress[itemIndex].email = values.email;
        copyBillingAddress[itemIndex].fax = values.fax;
        copyBillingAddress[itemIndex].phone = values.phone;
        copyBillingAddress[itemIndex].address_zip_code = values.address_zip_code;
        copyBillingAddress[itemIndex].is_active = values.is_active;
        copyBillingAddress[itemIndex].default_bill_to = values.default_bill_to;
        copyBillingAddress[itemIndex].default_ship_to = values.default_ship_to;
        if (formType === "edit") {
          copyBillingAddress[itemIndex].is_edited = true;
        }
        setBillingAddress(copyBillingAddress);

        setIsDefaultActive(values.is_active);
        setIsDefaultBill(values.default_bill_to);
        setIsDefaultShip(values.default_ship_to);
        setVisible(false);
        setEventType(null);
      });

    }
  };

  console.log("BillingAddress", billingAddress);

  const handleCancel = () => {
    setVisible(false);
    form.setFieldsValue({
      first_name: null,
      last_name: null,
      address_addressee: null,
      address_address_1: null,
      address_address_2: null,
      address_country_name: null,
      address_city_name: null,
      address_state_name: null,
      email: null,
      fax: null,
      phone: null,
      address_zip_code: null,
      is_active: null,
      default_bill_to: null,
      default_ship_to: null,
    })
    setIsDefaultActive(false);
    setIsDefaultBill(false);
    setIsDefaultShip(false);
  };

  const getModalData = () => {
    dispatch(loadCountry(token));
    form.setFieldsValue({
      first_name: null,
      last_name: null,
      address_addressee: null,
      address_address_1: null,
      address_address_2: null,
      address_country_name: null,
      address_city_name: null,
      address_state_name: null,
      email: null,
      fax: null,
      phone: null,
      address_zip_code: null,
      is_active: null,
      default_bill_to: null,
      default_ship_to: null,
    })
    setEventType("add");
    setVisible(true);
  };

  return (
    <>
      <div>
        <Modal
          className="modal-40"
          visible={visible}
          title="Address Form"
          okText="Submit"
          onOk={(data) => handleOk(data)}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
          // initialValues={{ modifier: "public" }}
          >
            <div className="container-fluid p-0 modal-form-main">
              <Brow>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="first_name" label=" First Name ">
                    <Input
                      size="small"
                      className="b-radius-10"

                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="last_name"
                    label="Last Name "
                  >
                    <Input
                      size="small"
                      className="b-radius-10"

                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="address_addressee"
                    label="Addressee"
                    rules={[
                      { message: "Addressee is required", required: true },
                    ]}
                  >
                    <Input
                      size="small"
                      className="b-radius-10"

                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="address_address_1" label="Address 1">
                    <Input
                      size="small"
                      className="b-radius-10"

                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="address_address_2" label="Address 2">
                    <Input
                      size="small"
                      className="b-radius-10"

                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="address_country_name" label="Country">
                    <Select
                      size="small"
                      showSearch

                      optionFilterProp="children"
                      value={
                        formType === "edit"
                          ? form.getFieldValue().country
                          : null
                      }
                      onChange={onChangeCountry}
                      onSearch={onSearch}
                      loading={country.loading}

                    >
                      {country?.payload?.data &&
                        country?.payload?.data.map((country) => {

                          return (
                            <Option
                              key={country.country_id}
                              value={country.country_id}
                            >
                              {country.country_name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="address_city_name" label="City">
                    <Select
                      size="small"
                      showSearch

                      optionFilterProp="children"
                      value={cityForm}
                      onChange={onChangeCity}
                      onSearch={onSearch}
                      loading={City.loading}
                      defaultValue={
                        formType === "edit" ? form.getFieldValue().city : null
                      }
                    >
                      {City?.payload?.data &&
                        City?.payload?.data.map((city) => (
                          <Option key={city.city_id} value={city.city_id}>
                            {city.city_name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="address_state_name" label="State">
                    <Select
                      size="small"
                      showSearch

                      optionFilterProp="children"
                      value={stateForm}
                      onChange={onChangeState}
                      onSearch={onSearch}
                      loading={State.loading}
                    >
                      {State?.payload?.data &&
                        State?.payload?.data.map((state) => (
                          <Option key={state.state_id} value={state.state_id}>
                            {state.state_name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input
                      size="small"
                      className="b-radius-10"

                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="fax" label="Fax">
                    <Input
                      size="small"
                      className="b-radius-10"

                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="phone"
                    label="Phone"
                  >
                    <Input
                      size="small"
                      className="b-radius-10"

                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="address_zip_code"
                    label="Zip"
                  >
                    <Input
                      size="small"
                      className="b-radius-10"

                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={3} xl={3} xxl={3}>
                  <Form.Item label="is_active" name="is_active">
                    <Switch
                      size="small"
                      checked={isDefaultActive}
                      onChange={(e) => setIsDefaultActive(e)}
                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={3} xl={3} xxl={3}>
                  <Form.Item label="Default Billing" name="default_bill_to">
                    <Switch
                      size="small"
                      checked={isDefaultBill}
                      onChange={(e) => setIsDefaultBill(e)}
                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={3} xl={3} xxl={3}>
                  <Form.Item label="Default shipping" name="default_ship_to">
                    <Switch
                      checked={isDefaultShip}
                      onChange={(e) => setIsDefaultShip(e)}
                      size="small"
                    />
                  </Form.Item>
                </Bcol>
              </Brow>
            </div>
          </Form>
        </Modal>
      </div>
      <Button
        disabled={!(formType === "add" || formType === "edit")}
        className="iconBtn crudBTN float-right mbpx-5"
        shape="circle"
        icon={<PlusOutlined />}
        onClick={() => getModalData()}
      />

      <Tables
        type="billing"
        billingObj={setBillingObj}
        scroll={520}
        perPage={5}
        scrollX={"90rem"}
        source={JSON.parse(JSON.stringify(billingAddress.sort((a, b) => {
          if (a.default_bill_to === b.default_bill_to) {

            return a.default_ship_to === b.default_ship_to ? 0 : a.default_ship_to ? -1 : 1;
          } else {
            return a.default_bill_to ? -1 : 1;
          }
        })))}
        col={billingColumns}
        load={customerAddress?.loading}
        paginate
      />
    </>
  );
}

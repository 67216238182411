import {
  GET_SPECIALITY_BY_STATE_REQUEST,
  GET_SPECIALITY_BY_STATE_SUCCESS,
  GET_SPECIALITY_BY_STATE_ERROR
} from "../actions/types";


export const specialityByState = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SPECIALITY_BY_STATE_REQUEST:
      return {
        loading: true,
      };
    case GET_SPECIALITY_BY_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_SPECIALITY_BY_STATE_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default: {
      return state;
    }
  }
};

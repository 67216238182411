import PageHeader from "../../components/PageHeader/PageHeader";
import "../Style/style.css";
import { Row, Col, Form, Input, Tabs, Divider, Checkbox, Select } from "antd";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { useState, useLayoutEffect, useRef, useEffect } from "react";
import { getRights } from "../../redux/actions/userRights.action";
import { useSelector, useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import Tables from "../../components/Table/Tables";
import { logColumns, paymentColumns } from "../../utils/cols";
import notification from "../../utils/notification";
import { getLog } from "../../redux/actions/log.action";
import {
  getPaymentTerm,
  addPaymentTerm,
  updatePaymentTerm,
  deletePaymentTerm,
} from "../../redux/actions/paymentterm.action";
import { TabTitle } from "../../utils/GeneralFunction";
import { getFactor } from "../../redux/actions/factor.action";
import { getSystemLog } from "../../redux/actions/systemLog.action";

const { TabPane } = Tabs;

const Paymentterm = ({ token }) => {
  TabTitle("Payment Term");
  const { userRights, log, paymentterm, factor, systemLog } = useSelector(
    (state) => state
  );
  const [minimize, setMinimize] = useState(false);
  const [formState, setFormState] = useState("view");
  const [paymentName, setPaymentName] = useState("");
  const [daysUntil, setDaysUntil] = useState(null);
  const [factorID, setFactorID] = useState(null);
  const [form] = Form.useForm();
  const paymentNameInput = useRef(null);
  const [disable, setDisable] = useState(false);
  const [paymentObj, setPaymentObj] = useState(null);
  const [check, setCheck] = useState(true);
  const dispatch = useDispatch();
  const id = ReadCookie("menuId");
  console.log("paymentObj", paymentObj);

  useEffect(() => {
    dispatch(getPaymentTerm(token, id));
    // dispatch(getLog(token, { scm_term: "scm_term" }));
    dispatch(getFactor(token, id));
  }, [dispatch, token, id]);

  const handleDelete = () => {
    if (paymentObj) {
      dispatch(deletePaymentTerm(token, paymentObj.term_id)).then((res) => {
        if (res.type === "DELETE_PAYMENT_TERM_SUCCESS") {
          notification(
            "success",
            "Term Deleted",
            "Payment Term deleted successfully!"
          );
        }
        if (res.type === "DELETE_PAYMENT_TERM_ERROR") {
          notification(
            "error",
            res?.payload?.request?.statusText,
            res?.payload?.data?.data
          );
        }
        form.setFieldsValue({
          city: "",
          cid: null,
        });

        setPaymentName("");
        setDaysUntil(null);
        setFactorID(null);
        setPaymentObj(null);
        setFormState("view");
        dispatch(getPaymentTerm(token, id));
      });
    }
    setFormState("view");
  };

  useEffect(() => {
    if (paymentObj) {
      dispatch(
        getSystemLog(token, {
          table_name: "scm_term",
          record_id: paymentObj?.term_id,
        })
      );
      setFormState("view");
      form.setFieldsValue({
        id: paymentObj.term_id,
        term: paymentObj.term_name,
        factor_id: paymentObj.factor_id,
        days_until_net_due: paymentObj.days_until_net_due,
        active: paymentObj.is_active,
      });
      setPaymentName(paymentObj.term_name);
      setDaysUntil(paymentObj.days_until_net_due);
      setFactorID(paymentObj.factor_id);
      setCheck(paymentObj.is_active);
    }
  }, [paymentObj]);

  useEffect(() => {
    if (paymentName === "" || (!daysUntil&&daysUntil!==0) || !factorID) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  
    if(formState === "edit"||formState === "add"){
        console.log("run");

        form.validateFields()
    }
  }, [paymentName, daysUntil, factorID,formState]);
// useEffect(() => {
//     console.log("formState",formState);
    
//     if(formState === "edit"||formState === "add"){
//         console.log("run");

//         form.validateFields()
//     }
// }, [,formState])

  useLayoutEffect(() => {
    dispatch(getRights(token, id)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          setFormState("table");
        }
      }
    });
  }, []);
  //   useEffect(() => {
  //     dispatch(getLog(token));
  //   }, [dispatch, token]);

  const formSave = async () => {
    await form
      .validateFields()
      .then((values) => {
        console.log("values", values);

        if (formState === "add") {
          dispatch(
            addPaymentTerm(
              token,
              {
                term_name: paymentName,
                is_active: check,
                factor_id: factorID,
                days_until_net_due: daysUntil,
              },
              id
            )
          ).then((res) => {
            if (res.type === "ADD_PAYMENT_TERM_ERROR") {
              notification("error", "Not Created", res?.payload?.data?.data);
            }
            if (res.type === "ADD_PAYMENT_TERM_SUCCESS") {
            //   setFormState("view");

              notification(
                "success",
                "Term Created",
                "Payment Term created successfully!"
              );
              form.setFieldsValue({
                term: "",
                days_until_net_due: null,
                factor_id: null,
              });
              setFormState("view");
              setPaymentName("");
              setDaysUntil(null);
              setFactorID(null);
              setPaymentObj(null);
            }
            dispatch(getPaymentTerm(token, id));
          });
        }
        if (formState === "edit") {
          dispatch(
            updatePaymentTerm(
              token,
              {
                term_name: paymentName,
                is_active: check,
                factor_id: factorID,
                days_until_net_due: daysUntil,
              },
              paymentObj.term_id
            )
          ).then((res) => {
            if (res.type === "UPDATE_PAYMENT_TERM_ERROR") {
              notification("error", "Update", "Something went wrong");
            }
            if (res.type === "UPDATE_PAYMENT_TERM_SUCCESS") {
    setFormState("view");

              notification(
                "success",
                "Term Updated",
                "Record updated successfully"
              );
            }
            dispatch(getPaymentTerm(token, id));
          });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (formState === "add") {
      paymentNameInput.current.focus();
      form.setFieldsValue({
        term: null,
        days_until_net_due: null,
        factor_id: null,
      });
      form.validateFields()
      setCheck(true);
      setPaymentName("");
      setDaysUntil(null);
      setFactorID(null);
      setPaymentObj(null);
    }
  }, [formState]);

  const filterReset = () => {
    setFormState("view");
    setPaymentObj(null);
    setCheck(true);
    form.setFieldsValue({
      term: null,
      days_until_net_due: null,
      factor_id: null,
    });
    setPaymentName(null);
    setDaysUntil(null);
    setFactorID(null);
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Payment Terms"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="crudDiv">
              <CrudButtons
                formType={formState}
                rights={userRights?.payload}
                formState={setFormState}
                disable={disable}
                reset={filterReset}
                save={formSave}
                onDelete={handleDelete}
              />
            </div>
            <div className="styleContent">
              <Row className="tables-main-row-new">
                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                  <div className="styleTable">
                    <Tables
                      type="paymentterm"
                      scrollX={"20rem"}
                      formState={formState}
                      paymentObj={setPaymentObj}
                      source={paymentterm?.payload?.data}
                      col={paymentColumns}
                      paginate
                      scroll={500}
                      perPage={25}
                      load={paymentterm?.loading}
                      rowKey="city_id"
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                  <div className="styleForm">
                    <div className="testStyle">
                      <Form form={form} name="basic">
                        <div className="customRow styleFormUpper ">
                          {paymentObj !== null && !(formState === "add") && (
                            <div className="customCols slc">
                              <Form.Item
                                label="ID"
                                name="id"
                                initialValue={form.getFieldValue().id}
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 14 }}
                              >
                                <Input
                                  size="small"
                                  className="styleInput id-field-style"
                                  readOnly
                                />
                              </Form.Item>
                            </div>
                          )}

                          <div className="customCols slc">
                            <Form.Item
                              label="Payment Term"
                              name="term"
                              initialValue={form.getFieldValue().city}
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 14 }}
                              rules={[
                                {
                                  required: true,
                                  message: "Payment term is required",
                                },
                              ]}
                              
                            >
                              <Input
                                size="small"
                                disabled={
                                  !(formState === "add" || formState === "edit")
                                }
                                ref={paymentNameInput}
                                onChange={(e) => setPaymentName(e.target.value)}
                                className="styleInput"
                              />
                            </Form.Item>
                          </div>
                          <div className="customCols slc">
                            <Form.Item
                              label="Days until due"
                              name="days_until_net_due"
                              initialValue={form.getFieldValue().city}
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 14 }}
                              rules={[
                                {
                                  required: true,
                                  message: "Days until is required",
                                },
                              ]}
                            >
                              <Input
                                size="small"
                                type="number"
                                disabled={
                                  !(formState === "add" || formState === "edit")
                                }
                                min={0}
                                onChange={(e) => setDaysUntil(e.target.value)}
                                value={daysUntil}
                                className="styleInput"
                              />
                            </Form.Item>
                          </div>
                          <div className="customCols slc">
                            <div className="country-spaceremove-mob mlpx-10 relative">
                              <Form.Item
                                label="Payment Factor"
                                name="factor_id"
                                labelAlign="left"
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 15 }}
                                //   initialValue={"All"}
                                rules={[
                                  {
                                    required: true,
                                    message: "Payment Factor is required",
                                  },
                                ]}
                              >
                                <Select
                                  size="small"
                                  // className="styleInput"
                                  filterOption={(input, option) =>
                                    option?.children
                                      ?.toLowerCase()
                                      .indexOf(input?.toLowerCase()) >= 0
                                  }
                                  onChange={(e) => setFactorID(e)}
                                  value={
                                    formState === "add"
                                      ? null
                                      : paymentObj?.factor_id
                                  }
                                  //   defaultValue={
                                  //     formState === "add"
                                  //       ? null
                                  //       : paymentObj?.factor_id
                                  //   }
                                  disabled={
                                    !(
                                      formState === "add" ||
                                      formState === "edit"
                                    )
                                  }
                                  showSearch
                                  allowClear
                                  loading={factor.loading}
                                >
                                  {factor?.payload?.data.map((item) => {
                                    return (
                                      <Select.Option
                                        key={item.factor_id}
                                        value={item.factor_id}
                                      >
                                        {item.factor_name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div className="customCols slc">
                            <Form.Item
                              label="Active"
                              name="active"
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 14 }}
                            >
                              <Checkbox
                                disabled={
                                  !(formState === "add" || formState === "edit")
                                }
                                checked={check}
                                onChange={(e) => setCheck(e.target.checked)}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Form>
                    </div>

                    <div className="modalStyle"></div>
                    <Divider />
                    <div className="styleTab">
                      <Tabs
                        type="card"
                        tabBarGutter={2}
                        defaultActiveKey="1"
                        centered /**onChange={(key) => handleTab(key)} */
                      >
                        <TabPane tab="Log Info" key="logs">
                          <div className="tabs">
                            <Tables
                              type="company"
                              scrollX={"50rem"}
                              scroll={800}
                              perPage={10}
                              source={systemLog?.payload?.data}
                              col={logColumns}
                              load={systemLog.loading}
                              paginate
                            />
                          </div>
                        </TabPane>
                      </Tabs>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Paymentterm;

import {
    GET_STATES_ERROR,
    GET_STATES_REQUEST,
    GET_STATES_SUCCESS,

    GET_DIVISION_STATS_REQUEST,
    GET_DIVISION_STATS_SUCCESS,
    GET_DIVISION_STATS_ERROR,

    GET_ACCOUNT_TYPE_STATS_REQUEST,
    GET_ACCOUNT_TYPE_STATS_SUCCESS,
    GET_ACCOUNT_TYPE_STATS_ERROR,

    GET_SALES_BY_MONTH_STATS_REQUEST,
    GET_SALES_BY_MONTH_STATS_SUCCESS,
    GET_SALES_BY_MONTH_STATS_ERROR,

    GET_RETURN_RATE_STATS_REQUEST,
    GET_RETURN_RATE_STATS_SUCCESS,
    GET_RETURN_RATE_STATS_ERROR,

    GET_AUC_STATS_SUCCESS,
    GET_AUC_STATS_REQUEST,
    GET_AUC_STATS_ERROR,

    GET_PLAN_ACTUAL_STATS_REQUEST,
    GET_PLAN_ACTUAL_STATS_SUCCESS,
    GET_PLAN_ACTUAL_STATS_ERROR,

    GET_STYLE_STATS_REQUEST,
    GET_STYLE_STATS_SUCCESS,
    GET_STYLE_STATS_ERROR,

    GET_FIT_CATEGORY_STATS_REQUEST,
    GET_FIT_CATEGORY_STATS_SUCCESS,
    GET_FIT_CATEGORY_STATS_ERROR,
    GET_FABRIC_STATS_REQUEST,
    GET_FABRIC_STATS_SUCCESS,
    GET_FABRIC_STATS_ERROR,

    GET_COUNTRY_STATS_REQUEST,
    GET_COUNTRY_STATS_SUCCESS,
    GET_COUNTRY_STATS_ERROR,

} from './types'

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");




export const getDashboardStats = (token, values) => (dispatch) => {
    dispatch({ type: GET_STATES_REQUEST });
    console.log("values", values)
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/GetStatistics`,

        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: values
    })
        .then((response) => {
            return dispatch({ type: GET_STATES_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_STATES_ERROR,
                payload: error.response,
            });
        });
};


export const getDivisionStats = (token, values) => (dispatch) => {
    dispatch({ type: GET_DIVISION_STATS_REQUEST });
    console.log("values", values)
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getDivisionStatistics`,

        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: values
    })
        .then((response) => {
            return dispatch({ type: GET_DIVISION_STATS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_DIVISION_STATS_ERROR,
                payload: error.response,
            });
        });
};

export const getAccountTypeStats = (token, values) => (dispatch) => {
    dispatch({ type: GET_ACCOUNT_TYPE_STATS_REQUEST });
    console.log("values", values)
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getAccountTypeStatistics`,

        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: values
    })
        .then((response) => {
            return dispatch({ type: GET_ACCOUNT_TYPE_STATS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ACCOUNT_TYPE_STATS_ERROR,
                payload: error.response,
            });
        });
};

export const getSalesByMonthStats = (token, values) => (dispatch) => {
    dispatch({ type: GET_SALES_BY_MONTH_STATS_REQUEST });
    console.log("values", values)
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getSalesByMonthStatistics`,

        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: values
    })
        .then((response) => {
            return dispatch({ type: GET_SALES_BY_MONTH_STATS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_SALES_BY_MONTH_STATS_ERROR,
                payload: error.response,
            });
        });
};

export const getReturnRateStats = (token, values) => (dispatch) => {
    dispatch({ type: GET_RETURN_RATE_STATS_REQUEST });
    console.log("values", values)
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getReturnRateStatistics`,

        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: values
    })
        .then((response) => {
            return dispatch({ type: GET_RETURN_RATE_STATS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_RETURN_RATE_STATS_ERROR,
                payload: error.response,
            });
        });
};

export const getAucStats = (token, values) => (dispatch) => {
    dispatch({ type: GET_AUC_STATS_REQUEST });
    console.log("values", values)
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getAucChartStatistics`,

        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: values
    })
        .then((response) => {
            return dispatch({ type: GET_AUC_STATS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_AUC_STATS_ERROR,
                payload: error.response,
            });
        });
};

export const getPlanActualStats = (token, values) => (dispatch) => {
    dispatch({ type: GET_PLAN_ACTUAL_STATS_REQUEST });
    console.log("values", values)
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getPlanActualStatistics`,

        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: values
    })
        .then((response) => {
            return dispatch({ type: GET_PLAN_ACTUAL_STATS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_PLAN_ACTUAL_STATS_ERROR,
                payload: error.response,
            });
        });
};



export const getStyleStats = (token, values) => (dispatch) => {
    dispatch({ type: GET_STYLE_STATS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getStyleStatistics`,

        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: values
    })
        .then((response) => {
            return dispatch({ type: GET_STYLE_STATS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_STYLE_STATS_ERROR,
                payload: error.response,
            });
        });
};

export const getFitCategoryStats = (token, values) => (dispatch) => {
    dispatch({ type: GET_FIT_CATEGORY_STATS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getFitCategoryStatistics`,

        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: values
    })
        .then((response) => {
            return dispatch({ type: GET_FIT_CATEGORY_STATS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_FIT_CATEGORY_STATS_ERROR,
                payload: error.response,
            });
        });
};


export const getFabricStats = (token, values) => (dispatch) => {
    dispatch({ type: GET_FABRIC_STATS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getFabricStatistics`,

        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: values
    })
        .then((response) => {
            return dispatch({ type: GET_FABRIC_STATS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_FABRIC_STATS_ERROR,
                payload: error.response,
            });
        });
};


export const getCountryStats = (token, values) => (dispatch) => {
    dispatch({ type: GET_COUNTRY_STATS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getStatesUsaStatistics`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: values
    })
        .then((response) => {
            return dispatch({ type: GET_COUNTRY_STATS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_COUNTRY_STATS_ERROR,
                payload: error.response,
            });
        });
};

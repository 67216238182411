import {
  GET_LOCATION_WISE_REPORT_REQUEST,
  GET_LOCATION_WISE_REPORT_SUCCESS,
  GET_LOCATION_WISE_REPORT_ERROR,
  GET_NEGATIVE_INVENTORY_LOCATION_WISE_REPORT_REQUEST,
  GET_NEGATIVE_INVENTORY_LOCATION_WISE_REPORT_SUCCESS,
  GET_NEGATIVE_INVENTORY_LOCATION_WISE_REPORT_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

export const locationWiseReport = (token, data) => (dispatch) => {
  dispatch({ type: GET_LOCATION_WISE_REPORT_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/LocationWise`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {

      location_id: data.location === "All" ? null : data.location,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_LOCATION_WISE_REPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_LOCATION_WISE_REPORT_ERROR,
        payload: [],
      });
    });
};


export const NegativeInventorylocationWiseReport = (token, data) => (dispatch) => {
  dispatch({ type: GET_NEGATIVE_INVENTORY_LOCATION_WISE_REPORT_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/NegativeInventoryLocationWise`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {

      location_id: data.location === "All" ? null : data.location,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_NEGATIVE_INVENTORY_LOCATION_WISE_REPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_NEGATIVE_INVENTORY_LOCATION_WISE_REPORT_ERROR,
        payload: [],
      });
    });
};

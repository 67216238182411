import {
    SKU_EXITS_ERP_SUCCESS,
    SKU_EXITS_ERP_REQUEST,
    SKU_EXITS_ERP_ERROR,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const skuExists = (token, data) => (dispatch) => {
    dispatch({ type: SKU_EXITS_ERP_REQUEST });


    return axios({
        method: "POST",
        url: `${BASEURL}getAll/skuExists`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { sku_list: data, sku: null }
    })
        .then((response) => {
            return dispatch({ type: SKU_EXITS_ERP_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: SKU_EXITS_ERP_ERROR,
                payload: error.response,
            });
        });
};
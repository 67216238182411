import React, { useState } from 'react';
import { Chart } from "react-google-charts";
import "./dashboard.css";


// Mapping full month names to abbreviated versions
const monthMap = {
    "January": "Jan",
    "February": "Feb",
    "March": "Mar",
    "April": "Apr",
    "May": "May",
    "June": "Jun",
    "July": "Jul",
    "August": "Aug",
    "September": "Sep",
    "October": "Oct",
    "November": "Nov",
    "December": "Dec"
};

const allMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

// let colors = ["007ea7", "33658a", "5fa8d3", "7d8597", "979dac"] // 1st
// let colors = ["598392", "979dac", "d4a373", "a3cef1", "748cab"] // 2nd


export const BarPlanActualChart = ({ groupedData, colors }) => {
    const headers = ["Month", "Plan", "Sales", "Miss Plan",];

    const data = [
        headers,
        ...allMonths.map(month => {
            const fullMonthName = groupedData && Object.keys(groupedData).find(fullMonth => monthMap[fullMonth] === month);

            const monthData = groupedData && groupedData[fullMonthName] || []; // Default to an empty array if no data for the month

            const plan = monthData.length > 0 ? parseInt(monthData[0]?.planamount) : 0;
            const totalSales = monthData.length > 0 ? parseInt(monthData[0]?.totalsales) : 0;
            const missPlan = monthData.length > 0 ? parseInt(monthData[0]?.missplantytotalsale) : 0;

            return [month, plan, totalSales, missPlan];
        })
    ];


    return (
        <div className='charts_container'>
            <Chart
                chartType="Bar"
                width="100%"
                height="300px"
                data={data}
                options={{
                    isStacked: false,
                    legend: { position: "bottom" },
                    colors: colors
                }}
            />

        </div>
    );
};

export const BarAucChart = ({ groupedData, colors }) => {

    const headers = groupedData ? Object.keys(groupedData) : [];

    const allYears = Array.from(
        new Set(
            headers.flatMap(gender => groupedData[gender]?.map(item => item.yearr) || [])
        )
    ).sort();

    const data = [
        ["Gender", "AUC"], // Header row
        ...headers.map(gender => {
            const monthData = groupedData[gender] || [];

            // Create an object for quick lookup of auc values by year
            // const yearAucMap = monthData.reduce((acc, entry) => {
            //     acc[entry.yearr] = entry.auc;
            //     return acc;
            // }, {});
            let net_amount = 0;
            let qty = 0;
            monthData.map((item, idx) => {
                net_amount += parseInt(item.net_amount);
                qty += parseInt(item.qty_net);
                // returned += -parseInt(item.returned)
                // return_percent = monthData[0].returned / monthData[0].shipped * 100
            })

            let total_auc = net_amount / qty;

            // Populate each year column with the auc value or 0 if missing
            // const row = [gender, ...allYears.map(year => parseInt(yearAucMap[year]) || 0)];
            const row = [gender, total_auc];
            return row;
        })
    ];


    return (
        <div className='charts_container'>
            <Chart
                chartType="Bar"
                width="100%"
                height="300px"
                data={data}
                options={{
                    isStacked: false,
                    legend: { position: "bottom" },
                    colors: colors
                }}
            />
        </div>
    );
};

export const BarSaleByMonthChart = ({ groupedData, colors }) => {
    const headers = groupedData ? Object.keys(groupedData) : [];


    // Extract all unique years from groupedData
    const allYears = Array.from(
        new Set(
            headers.flatMap(gender => groupedData[gender]?.map(item => item.yearr) || [])
        )
    ).sort();

    // List of months
    // const allMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    // Initialize the data array with headers
    const data = [
        ["Month", ...allYears],
        ...allMonths.map(month => {
            const fullMonthName = groupedData && Object.keys(groupedData).find(fullMonth => monthMap[fullMonth] === month);
            const monthData = groupedData && groupedData[fullMonthName] || []; // Default to an empty array if no data for the month

            const yearSums = {};

            monthData.forEach(entry => {
                const year = entry.yearr;
                const netAmount = parseInt(entry.net_amount, 10); // Convert net_amount to an integer

                // Initialize the year in the sums object if it doesn't exist
                if (!yearSums[year]) {
                    yearSums[year] = 0;
                }

                // Add the net amount to the corresponding year's total
                yearSums[year] += netAmount;
            });

            // Populate each year column with the net amount or 0 if missing
            const row = [month, ...allYears.map(year => yearSums[year] || 0)];
            return row;
        })
    ];


    return (
        <div className='charts_container'>
            <Chart
                chartType="Bar"
                data={data}
                width="100%"
                height="300px"
                options={{
                    isStacked: false,
                    legend: { position: "bottom" },
                    vAxis: {
                        title: "Net Amount",
                        minValue: 0, // Set your custom minimum value here
                        // maxValue: 1222, // Adjust this based on your data range
                    },
                    colors: colors
                }}
            />
        </div>
    );
};

export const BarReturnRateChart = ({ groupedData, colors }) => {
    const headers = ["Month", "Shipped", "Returned", "Return Percent"];

    const data = [
        headers,
        ...allMonths.map(month => {
            const fullMonthName = groupedData && Object.keys(groupedData).find(fullMonth => monthMap[fullMonth] === month);
            const monthData = groupedData && groupedData[fullMonthName] || []; // Default to an empty array if no data for the month

            let shipped = 0;
            let returned = 0;  // Returned values are now negative

            monthData.map((item, idx) => {
                shipped += parseInt(item.shipped)
                returned += -parseInt(item.returned)
                // return_percent = monthData[0].returned / monthData[0].shipped * 100
            })

            let return_percent = Math.abs(returned) / shipped * 100;

            return [month, shipped, returned, return_percent];
        })
    ];



    const options = {
        // hAxis: {
        //     title: 'Month',
        // },
        vAxes: {
            0: {
                title: 'Shipped and Returned',
                minValue: -Math.max(...data.map(item => Math.abs(item[2]))) // Adjust the negative axis to accommodate "Returned"
            },
            1: { title: 'Return Percent' }, // Right axis for return percent
        },
        seriesType: 'bars', // Default series type is bars
        series: {
            0: { targetAxisIndex: 0 }, // Shipped series will use the left axis
            1: { targetAxisIndex: 0 }, // Returned series will use the left axis
            2: { type: 'line', targetAxisIndex: 1 }, // Return Percent as line on the right axis
        },
        bar: { groupWidth: '60%' },  // Control the width of bars
        colors: colors
    };

    return (
        <div className='charts_container'>
            <Chart
                chartType="ComboChart"
                width="100%"
                height="400px"
                data={data}
                options={options}
            />
        </div>
    );
};

export const BarDivisionChart = ({ groupedData, colors }) => {
    // Convert groupedData into Pie Chart data format
    const data = [
        ["Gender", "Net Amount"],
        ...Object.entries(groupedData).map(([gender, records]) => {
            const totalNetAmount = records.reduce((sum, record) => sum + parseInt(record.net_amount, 10), 0);
            return [gender, totalNetAmount];
        })
    ];

    const options = {
        // pieHole: 0.4,
        // is3D: true,
        pieStartAngle: 100,
        sliceVisibilityThreshold: 0,
        legend: {
            position: "top", // Positions legend at the top
            alignment: "center",
            maxLines: 3, // Limit number of lines (if available in your context)
            textStyle: {
                color: "#000000",
                fontSize: 12, // Reduce font size to fit more items
            },
        },
        colors: colors,
    };

    return (
        <div className='charts_container'>
            <Chart
                chartType="PieChart"
                data={data}
                options={options}
                width={"100%"}
                height={"400px"}
            />
        </div>
    );
};

export const BarAccountTypeChart = ({ groupedData, colors }) => {
    // Convert groupedData into Pie Chart data format
    const data = [
        ["Gender", "Net Amount"],
        ...Object.entries(groupedData).map(([gender, records]) => {
            const totalNetAmount = records.reduce((sum, record) => sum + parseInt(record.net_amount, 10), 0);
            return [gender, totalNetAmount];
        })
    ];


    const options = {
        // pieHole: 0.4,
        // is3D: true,
        pieStartAngle: 100,
        sliceVisibilityThreshold: 0,
        legend: {
            position: "top", // Positions legend at the top
            alignment: "center",
            maxLines: 3, // Limit number of lines (if available in your context)
            textStyle: {
                color: "#233238",
                fontSize: 12, // Reduce font size to fit more items
            },
        },
        colors: colors,
    };

    return (
        <div className='charts_container'>
            <Chart
                chartType="PieChart"
                data={data}
                options={options}
                width={"100%"}
                height={"400px"}
            />
        </div>
    );
};



export const StylesChart = ({ groupedData, colors }) => {
    const header = ["Sale Person", "Total Sales"];

    let data = [
        header, // Header for the chart
        ...Object.keys(groupedData || {})
            .map((styleName) => {
                const net_amount = groupedData[styleName].reduce(
                    (sum, record) => sum + parseInt(record?.net_amount || 0, 10),
                    0
                );
                return [styleName, net_amount]; // Return [name, totalSales]
            })
            .sort((a, b) => b[1] - a[1]) // Sort by total sales (descending)
            .slice(0, 20), // Take only the top 10
    ];

    const options = {
        // title: "Population of Largest U.S. Cities",
        chartArea: { width: "50%" },
        // hAxis: {
        //     title: "Total Population",
        //     minValue: 0,
        // },
        // vAxis: {
        //     title: "City",
        // },
        colors: colors
    };




    return (
        <div className='charts_container'>
            <Chart
                // Bar is the equivalent chart type for the material design version.
                chartType="BarChart"
                width="100%"
                height="500px"
                data={data}
                options={options}
            />
        </div>
    );
};

export const FitChart = ({ groupedData, colors }) => {
    const header = ["Fit Category Name", "Total Sales"];

    let data = [
        header, // Header for the chart
        ...Object.keys(groupedData || {})
            .map((fitCategoryName) => {
                const net_amount = groupedData[fitCategoryName].reduce(
                    (sum, record) => sum + parseInt(record?.net_amount || 0, 10),
                    0
                );
                return [fitCategoryName, net_amount]; // Return [name, totalSales]
            })
            .sort((a, b) => b[1] - a[1]) // Sort by total sales (descending)
            .slice(0, 20), // Take only the top 10
    ];

    const options = {
        // title: "Population of Largest U.S. Cities",
        chartArea: { width: "50%" },
        // hAxis: {
        //     title: "Total Population",
        //     minValue: 0,
        // },
        // vAxis: {
        //     title: "City",
        // },
        colors: colors
    };




    return (
        <div className='charts_container'>
            <Chart
                // Bar is the equivalent chart type for the material design version.
                chartType="BarChart"
                width="100%"
                height="500px"
                data={data}
                options={options}
            />
        </div>
    );
};


export const FabricChart = ({ groupedData, colors }) => {
    const header = ["Fabric Name", "Total Sales"];


    // Make sure groupedData is not null or undefined
    let data = [
        header, // Header for the chart
        ...Object.keys(groupedData || {})
            .map((fabricName) => {
                const net_amount = groupedData[fabricName].reduce(
                    (sum, record) => sum + parseInt(record?.net_amount || 0, 10),
                    0
                );
                return [fabricName, net_amount]; // Return [fabricName, totalSales]
            })
            .sort((a, b) => b[1] - a[1]) // Sort by total sales (descending)
            .slice(0, 15), // Take only the top 10
    ];



    console.log("Fabric data", data)

    const options = {
        chartArea: { width: "50%" },
        colors: colors, // Use your color array
        // vAxis: {
        //     title: "Fabric Name",
        // },
        // hAxis: {
        //     title: "Total Sales",
        //     minValue: 0,
        // },
        // legend: { position: "top" },
    };

    return (
        <div className='charts_container'>
            <Chart
                chartType="BarChart"
                width="100%"
                height="500px"
                data={data}
                options={options}
            />
        </div>
    );
};



export const CountryChart = ({ groupedData }) => {

    // Static data for the United States and its states with sales and year
    const data = [
        ["State Name", "Sales"], // Header for the chart data
        ...Object.keys(groupedData || {}).map((state) => {
            // Calculate the total sales (net_amount) for each state
            const net_amount = groupedData[state].reduce(
                (sum, record) => sum + parseInt(record?.net_amount || 0, 10),
                0
            );

            // Return the data with the modified state name and total sales
            return [state, net_amount];
        })
    ];

    // Define colors for the color axis (adjusted to your needs)
    const colorsss = [
        "#b5d1d8", "#a0c4cb", "#8ab8be", "#75aaba", "#609eb5",
        "#4a92b0", "#3c86a9", "#2e7a9c", "#1f6f8f", "#105984",
        "#0c4e7a", "#08477b", "#073e7d", "#06357f", "#053084"
    ];

    // Calculate the min and max sales values to scale the color axis dynamically
    const maxValue = Math.max(...data.slice(1).map(row => row[1]));
    const minValue = Math.min(...data.slice(1).map(row => row[1]));

    // const options = {
    //     region: "US",
    //     displayMode: "regions",
    //     resolution: "provinces",
    //     colorAxis: {
    //         minValue: minValue,
    //         maxValue: maxValue,
    //         colors: colorsss,
    //     },
    //     tooltip: {
    //         isHtml: true,
    //         trigger: "focus",
    //         // Example of customizing tooltips to show custom HTML:
    //         textStyle: {
    //             color: "blue", // Custom tooltip text color
    //         },
    //     },
    //     legend: { position: "none" }, // You can adjust the legend position as needed
    // };

    const options = {
        region: "US",
        displayMode: "regions",
        resolution: "provinces",
        tooltip: {
            isHtml: true,
            trigger: "focus",
            textStyle: {
                color: "black", // Customize text color in the tooltip
            },
            // Customize the tooltip content to show the abbreviation
            trigger: "both", // Can be on hover or select
        },
        colorAxis: {
            colors: colorsss, // Color scale for countries
        },
        datalessRegionColor: "#f5f5f5", // Color for regions without data
        // backgroundColor: "#f0f0f0", // Background color
        legend: { position: "none" }, // No legend for simplicity
    };

    return (
        <div className="charts_container">
            <Chart
                chartEvents={[{
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const state = data[selection[0].row + 1]; // Get selected state
                    },
                }]}
                chartType="GeoChart"
                width="100%"
                height="400px"
                data={data} // Pass static data
                options={options}
            />
        </div>
    );
};




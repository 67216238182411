import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Select, DatePicker, Spin, Table } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import moment from "moment";
import logo from "../../assets/images/logo.png";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import "../ProducList/productList.css";
import { TabTitle } from "../../utils/GeneralFunction.js";
import { getIncomeStatement, getIncomeStatementNew } from "../../redux/actions/incomeStatement.action.js";
import { useDownloadExcel } from "react-export-table-to-excel";
function filterDuplicates(arr) {
  const seen = {}; 
  return arr.filter(item => {
    if (!seen[item.account_no]) {
      seen[item.account_no] = true;
      return true;
    }
    return false;
  });
}
const IncomeStatementNew = ({ token }) => {
  TabTitle("Income Statement")
  const { saleTargetReport, incomeStatement } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  const history = useHistory();
  var date = moment();

  const [dateTypeId, setDateTypeId] = useState("mte");
  const [monthId, setMonthId] = useState(date.format("M"));
  const [yearId, setYearId] = useState(date.format("Y"));
  const [monthToId, setMonthToId] = useState(date.format("M"));
  const [yearToId, setYearToId] = useState(date.format("Y"));
  
  
  
  
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  
  const { submited } = useParams();
  const [netIncome, setNetIncome] = useState();
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Income Statement",
    sheet: "Income Statement",
  });

  useEffect(() => {
    let year = yearId;
    let month = monthId;
    let yearto = yearToId;
    let monthto = monthToId;
    let currentYearStartDate = moment().startOf("year").format("MM/DD/YYYY");
    let currentYearEndDate = moment().endOf("year").format("MM/DD/YYYY");
    let lastYearStartDate = moment()
      .startOf("year")
      .subtract(1, "year")
      .format("MM/DD/YYYY");
    let lastYearEndDate = moment()
      .endOf("year")
      .subtract(1, "year")
      .format("MM/DD/YYYY");

    if (dateTypeId === "mte") {
      currentYearStartDate = month + "/" + "01" + "/" + year;
      currentYearEndDate = monthto + "/" + moment(`${yearto} / ${monthto}`, "YYYY/MM").daysInMonth() + "/" + yearto;
    }

    
    
    
    

    const newState = {
      cy_start_date: currentYearStartDate,
      cy_end_date: currentYearEndDate,
    };
    setFormState(newState);
  }, [dateTypeId, monthId, yearId, monthToId, yearToId]);


  const onFinish = () => {
    setLoading(true);
    dispatch(getIncomeStatement(token, formState)).then((res) => {
      if (res?.type === "GET_INCOME_STATEMENT_SUCCESS") {
        
        setLoading(false);
      }
    });
  };



  const groupedData = incomeStatement &&
    incomeStatement?.payload?.data?.reduce((result, item) => {
      const accttype = item[0]?.accttype;
      if (!result[accttype]) {
        result[accttype] = [];
      }
      result[accttype].push(item);
      return result;
    }, {});


  const customSortOrder = ["Income", "Cost of Goods Sold", "Expense", "Other Expense", "Other Income"];

  const groupedArray = groupedData && Object.entries(groupedData).map(([accttype, items]) => ({
    accttype,
    items
  }));

  
  groupedArray && groupedArray.sort((a, b) => {
    const indexA = customSortOrder.indexOf(a.accttype);
    const indexB = customSortOrder.indexOf(b.accttype);
    
    return indexA - indexB;
  });


  const sums = groupedArray?.map((v) => {
    return v.items.reduce((acc, item) => {
      return item[0].finalamount.map((itemValue, index) => {
        return (acc[index] || 0) + itemValue;
      });
    }, []);
  });


  const sums12 = groupedArray?.map((v, index) => {
    return {
      sum: v.items.reduce((acc, item) => {
        return item[0].finalamount.map((itemValue, index) => {
          return (acc[index] || 0) + itemValue;
        });
      }, []),
      "accttype": v.accttype 
    };
  });



  

  
  let incomeArrResult = [];

  const incomeArr = sums12?.filter((val) => {
    if (val.accttype === "Income" || val.accttype === "Other Income") {
      incomeArrResult.push(val.sum);
    }
    return val;
  });
  

  const incomeFinalArray = incomeArrResult && incomeArrResult[0]?.map((_, index) => {
    return incomeArrResult.reduce((acc, arr) => acc + arr[index], 0);
  });



  let expensesResult = [];
  const expenses = sums12?.filter((val) => {
    if (val.accttype !== "Income" && val.accttype !== "Other Income") {
      expensesResult.push(val.sum);
    }
    return val;
  });
  

  const expensesFinalArray = expensesResult && expensesResult[0]?.map((_, index) => {
    return expensesResult.reduce((acc, arr) => acc + arr[index] * -1, 0);
  });
  
  

  const sumFinal = incomeFinalArray?.map((value, index) => {
    return value + expensesFinalArray[index];
  });




  const result = sums && sums.reduce((acc, curr) => acc.map((value, index) => value - curr[index]));

  const total = []
  for (let i = 0; i < sums?.length; i++) {
    let sum = 0;
    for (let j = 0; j < sums[i].length; j++) {
      sum += sums[i][j];
    }
    total.push(sum);
  }

  const data = sumFinal && sumFinal.reduce(function (previousValue, currentValue) {
    return (previousValue + parseFloat(currentValue));
  }, 0);


  const grossProfit = sums && sums[0].reduce((result, currentVal, index) => {
    result.push(currentVal - sums[1][index]);
    return result;
  }, []);


  const totalGrossProfit = grossProfit && grossProfit.reduce(function (previousValue, currentValue) {
    return (previousValue + parseFloat(currentValue));
  }, 0);



  const handlePrint = () => {
    window.print();
  };
  function groupExpensesByParentAccount(data) {
    const groupedExpenses = {};

    data.forEach(item => {
      const parentAccountID = item.items[0][0].parent_account_no + item.items[0][0].parent_account_name;

      if (!groupedExpenses[parentAccountID]) {
        groupedExpenses[parentAccountID] = [];
      }

      groupedExpenses[parentAccountID].push(item);
    });

    return Object.entries(groupedExpenses).map(([parentAccountID, items]) => ({
      parent_account_id: parentAccountID,
      items,
    }));
  }


  const childToParent = (childData) => {
    setNetIncome(childData)
  }




  return (
    <>
      <div className="container-fluid" >
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row justify-content-between pb-0">
                <div className="col-3 d-flex align-items-start  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>
                <div className="col-3 box-space right-content-header text-right">
                  <div className=" d-flex justify-content-end">
                    <div className=" d-flex justify-content-end printing_class">
                      <div className="print_icon printing_class">
                        <i
                          id="Image1"
                          title="Print"
                          alt="Print"
                          className="fa-solid fa-print printing_class"
                          onClick={handlePrint}
                        ></i>
                      </div>
                      <div className="print_icon printing_class">
                        {incomeStatement ? (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            onClick={onDownload}
                            style={{
                              borderWidth: "0px",
                              height: "14px",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            style={{ borderWidth: "0px", height: "14px" }}
                          />
                        )}
                      </div>
                    </div>
                    <span style={{ fontWeight: "bolder" }}>
                      Income Statement
                      <br />
                      Rpt # 006
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {
                <div className="row pb-2">
                  <div className="col">
                    <Form
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      layout="horizontal"
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <div className="main_form row">
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="gender_id" className="labels width30">
                              Year From:
                            </label>
                            <div style={{ marginBottom: "2px" }} className=" w-100">

                              <DatePicker
                                size="small"
                                className="filter-fields full_date"
                                value={moment(yearId)}
                                defaultValue={moment(yearId)}
                                onChange={(e) =>
                                  setYearId(moment(e).format("YYYY"))
                                }
                                picker="year"
                              />
                            </div>
                          </div>
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="gender_id" className="labels width30">
                              Year To:
                            </label>
                            <div style={{ marginBottom: "2px" }} className=" w-100">

                              <DatePicker
                                size="small"
                                className="filter-fields full_date"
                                value={moment(yearToId)}
                                
                                onChange={(e) =>
                                  setYearToId(moment(e).format("YYYY"))
                                }
                                picker="year"
                              />
                            </div>
                          </div>
                        </div>
                        <div className=" col-6">

                          <div className=" d-flex flex-row align-items-baseline">
                            <label className="labels width30">Month From:</label>
                            <div style={{ marginBottom: "2px" }} className=" w-100">
                              <DatePicker
                                size="small"
                                className="filter-fields full_date"
                                value={moment(monthId)}
                                
                                onChange={(e) =>
                                  setMonthId(moment(e).format("MM"))
                                }
                                picker="month"
                                format={"MM"}
                                mode="month"
                              />
                            </div>
                          </div>
                          <div className=" d-flex flex-row align-items-baseline">
                            <label className="labels width30">Month To:</label>
                            <div style={{ marginBottom: "2px" }} className=" w-100">
                              <DatePicker
                                size="small"
                                className="filter-fields full_date"
                                value={moment(monthToId)}
                                
                                onChange={(e) =>
                                  setMonthToId(moment(e).format("MM"))
                                }
                                picker="month"
                                format={"MM"}
                                mode="month"
                              />
                            </div>
                          </div>
                          <div style={{ marginTop: "0px", display: "flex", justifyContent: "end" }} >
                            {/* <label className=" no_visibility">none</label> */}
                            <Button type="primary" htmlType="submit" className='submit-btn-btm m-0'
                            
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
      {
        <div style={{ display: "none" }}>
          <div className="report-box-main" ref={tableRef}>
            {
              groupedArray ? (
                groupedArray.length > 0 ? (
                  <>

                    {
                      groupedArray && (
                        <>
                          <table>
                            <tr >
                              <td style={{ fontSize: "20px", fontWeight: 900, textAlign: "center" }} colSpan={groupedArray[0]?.items[0][0]?.allmonths?.length + 2}>DL1961</td>
                            </tr>
                            <tr>
                              <td style={{ fontSize: "20px", fontWeight: 900, textAlign: "center" }} colSpan={groupedArray[0]?.items[0][0]?.allmonths?.length + 2}>DL1961 : DL1961 (USA)</td>
                            </tr>
                            <tr>
                              <td style={{ fontSize: "20px", fontWeight: 900, textAlign: "center" }} colSpan={groupedArray[0]?.items[0][0]?.allmonths?.length + 2}>Income Statement</td>
                            </tr>
                            <tr>
                              <td style={{ fontSize: "20px", fontWeight: 900, textAlign: "center" }} colSpan={groupedArray[0]?.items[0][0]?.allmonths?.length + 2}>From {moment(monthId, 'MM').format('MMMM')} {yearId} to {moment(monthToId, 'MM').format('MMMM')} {yearToId}</td>
                            </tr>
                          </table>
                          <table className="report-table-main" style={{ fontSize: "10px" }} >
                            <tr className="report-row-head" >
                              <td className="report-column-head first-head" style={{ backgroundColor: "#d3d3d3", fontWeight: 900 }}>Financial New</td>
                              {
                                groupedArray && groupedArray[0].items?.map((v, index) => {
                                  return v[0]?.allmonths?.map((val) => {
                                    return (
                                      index == 0 ? <>
                                        <td className="report-column-head second-head text-right" style={{ backgroundColor: "#d3d3d3", fontWeight: 900, textAlign: "center" }}>{val}</td> </> : null
                                    )
                                  })
                                })
                              }
                              <td className="report-column-head second-head text-right" style={{ backgroundColor: "#d3d3d3", fontWeight: 900 }}>Total Amount</td>
                            </tr>
                            {
                              groupedArray && groupedArray.map((val, index) => {
                                return (
                                  <>
                                    <tr className="report-hd">
                                      <th className="report-hd" style={{ textAlign: "left", padding: "0 10px" }} >{val.accttype}</th>
                                    </tr>
                                    {val?.items?.map((v) => {
                                      if ((eval(v[0]?.finalamount.join('+'))) !== 0) {
                                        return (
                                          <>
                                            <tr>
                                              <td style={{ padding: "0 20px" }}>
                                                {v[0]?.account_name}
                                              </td>

                                              {
                                                v[0]?.finalamount?.map((item) => {
                                                  return (
                                                    <>
                                                      <td className="report-value text-right">
                                                        <span style={{ color: "#000000" }}>
                                                          ＄{numeral(item).format("0,00.00")}
                                                        </span>
                                                      </td>
                                                    </>
                                                  )
                                                })
                                              }
                                              <td className="report-value text-right">＄{
                                            eval(v[0]?.finalamount.join('+')).toString().includes("e") ? "0.00" : numeral(eval(v[0]?.finalamount.join('+'))).format("0,00.00")
                                              }</td>
                                            </tr>
                                          </>
                                        )
                                      } else {
                                        return null
                                      }
                                    })}
                                    <tr className="bottom-row">
                                      <td className="total-amount-left" style={{ fontWeight: 900, textAlign: "left", padding: "0 10px" }}>Total-{val?.accttype}</td>
                                      {sums[index]?.map(it => (
                                        <>
                                          <td className="total-amount" style={{ fontWeight: 900 }}>＄{numeral(it).format("0,00.00")}</td>
                                        </>
                                      ))}
                                      <td className="total-amount" style={{ fontWeight: 900 }}>＄{numeral(total[index]).format("0,00.00")}</td>
                                    </tr>
                                    {val?.accttype === "Cost of Goods Sold" && <tr className="bottom-row">
                                      <td className="total-amount-left" style={{ fontWeight: 900, padding: "0 10px" }}>{val?.accttype == "Cost of Goods Sold" ? "Gross Profit" : null}</td>
                                      {grossProfit && grossProfit.map((v) => {
                                        return <td className="total-amount" style={{ fontWeight: 900 }}>{val?.accttype == "Cost of Goods Sold" ? "＄" + numeral(v).format("0,00.00") : null}</td>
                                      })}
                                      <td className="total-amount" style={{ fontWeight: 900 }}>{val?.accttype == "Cost of Goods Sold" ? "＄" + numeral(totalGrossProfit).format("0,00.00") : null}</td>
                                    </tr>}
                                  </>
                                )
                              })
                            }
                            <tr style={{ fontWeight: 900, }} >
                              <td className="total-amount-left" style={{ textAlign: "left", padding: "0 10px" }}>
                                Net Profit / Loss
                              </td>
                              {sumFinal && sumFinal.map((v) => {
                                return <td className="total-amount">＄{numeral(v).format("0,00.00")}</td>
                              }
                              )}
                              <td className="total-amount">＄{numeral(data).format("0,00.00")}</td>
                            </tr>
                          </table>
                        </>
                      )
                    }
                  </>) :
                  (
                    <div className="no-Data">
                      <h1>No Data</h1>
                    </div>
                  )
              )
                : (
                  loading && (
                    <div className="loader" style={{ marginTop: "20px" }}>
                      <Spin size="medium" />
                    </div>
                  )
                )
            }
          </div>
        </div>}
      <div className="table_content international_order scrollbar-style"
        style={{ maxWidth: '1150px', width: "100%", overflowX: "auto", marginBottom: "10px" }}
      >
        {incomeStatement?.payload?.data !== undefined ?
          (
            incomeStatement?.payload?.data.length > 0 ? (
              <>
                <div style={{
                  display: "flex",
                  
                  padding: "1px 5px 1px 5px",
                  alignItems: "center",
                  background: "grey", color: "white",
                  height: "30px",
                  width: "fit-content",
                  minWidth: "100%",
                  
                  
                  top: "0",
                  left: "0",
                }} >
                  <div style={{ minWidth: "40px" }}>
                  </div>
                  <div className="financial-main">
                    <div style={{ minWidth: "400px" }}> Financial Row</div>
                    <div className="d-flex">
                      {
                        incomeStatement?.payload?.data.map((val, index) => {
                          return val[0]?.allmonths.map((v) => {
                            return (
                              index == 0 ? <>
                                <div style={{ minWidth: "150px", textAlign: "right" }}>{v?.charAt(0).toUpperCase() + v?.slice(1)}</div> </> : null
                            )
                          })
                        })
                      }
                      <div style={{ minWidth: "170px", textAlign: "right" }}>Total</div>
                    </div>
                  </div>
                </div>
                <>
                  <Accordion
                    data={groupedArray}
                    netIncome={netIncome}
                    setNetIncome={childToParent}
                    title={"Ordinary Income/Expense"}
                    dateFrom={formState?.cy_start_date}
                    dateTo={formState?.cy_end_date}
                  />
                </>
                <>
                  <Accordion
                    data={groupedArray}
                    netIncome={netIncome}
                    setNetIncome={childToParent}
                    title={"Other Income and Expenses"}
                    dateFrom={formState?.cy_start_date}
                    dateTo={formState?.cy_end_date}
                  />
                </>
                <div style={{ borderTop: "1px solid black", display: "flex", padding: "5px 5px 4px 5px", width: "fit-content", minWidth: "100%" }}>
                  <div style={{ minWidth: "40px" }}></div>
                  <div className="financial-main">
                    <td style={{ minWidth: "400px" }} className="fontBold">Net Income</td>
                    <div>
                      {netIncome?.map(e => {
                        return <td style={{ minWidth: "150px", textAlign: "right" }} className="fontBold pad-0">${numeral(e).format("0,00.00")}</td>
                      })}
                      <td style={{ minWidth: "170px", textAlign: "right" }} className="fontBold">${numeral(sum(netIncome)).format("0,00.00")}</td>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="no-Data">
                <h1>No Data</h1>
              </div>
            )
          ) : (
            loading && (
              <div className="loader" style={{ marginTop: "20px" }}>
                <Spin size="medium" />
              </div>
            )
          )
        }
      </div>
    </>
  );
};


export default IncomeStatementNew;

const Accordion = ({ data, title, netIncome, setNetIncome, dateFrom, dateTo }) => {
  const [isActive, setIsActive] = useState(true);

  const months = data[0]?.items[0][0]?.allmonths;
  const [totalAmountArray, setTotalAmountArray] = useState(Array(months?.length).fill(0));
  const [totalsOtherExpense, setTotalsOtherExpense] = useState(Array(months?.length).fill(0));


  useEffect(() => {
    const calculateTotalAmounts = () => {
      const totals = Array(months?.length).fill(0);
      const totalsSecond = Array(months?.length).fill(0);
      const totalsThird = Array(months?.length).fill(0);
      const totalsFourth = Array(months?.length).fill(0);

      data[0]?.items.forEach(itemGroup => {
        itemGroup.forEach(item => {
          item.values.forEach((value, index) => {
            totals[index] += parseFloat(value);
          });
        });
      });
      data[1]?.items.forEach(itemGroup => {
        itemGroup.forEach(item => {
          item.values.forEach((value, index) => {
            totalsSecond[index] += parseFloat(value);
          });
        });
      });
      data[2]?.items.forEach(itemGroup => {
        itemGroup.forEach(item => {
          item.values.forEach((value, index) => {
            totalsThird[index] += parseFloat(value);
          });
        });
      });

      data[4]?.items.forEach(itemGroup => {
        itemGroup.forEach(item => {
          item.values.forEach((value, index) => {
            totalsFourth[index] += parseFloat(value);
          });
        });
      });

      
      let arr = totals.map((e, i) => e - totalsSecond[i]  - totalsThird[i]);
      
     
      setTotalAmountArray(arr);
    };

    calculateTotalAmounts();
    const calculateTotalAmountsOtherExpense = () => {
      if (data.length < 4) {
        const totalsOtherExpense = Array(months?.length).fill(0);
        data[3]?.items.forEach(itemGroup => {
          itemGroup.forEach(item => {
            item.values.forEach((value, index) => {
              totalsOtherExpense[index] += parseFloat(value);
            });
          });
        });
        setTotalsOtherExpense(totalsOtherExpense);
      } else {
        const totalsOtherExpense = Array(months?.length).fill(0);
        const totalsOtherIncome = Array(months?.length).fill(0);

        data[3]?.items.forEach(itemGroup => {
          itemGroup.forEach(item => {
            item.values.forEach((value, index) => {
              totalsOtherExpense[index] += parseFloat(value);
            });
          });
        });
        data[4]?.items.forEach(itemGroup => {
          itemGroup.forEach(item => {
            item.values.forEach((value, index) => {
              totalsOtherIncome[index] += parseFloat(value);
            });
          });
        });
        let arr = totalsOtherExpense.map((e, i) => totalsOtherIncome[i] - e)
        setTotalsOtherExpense(arr);
      }
    };
    calculateTotalAmountsOtherExpense();
  }, [])

  useEffect(() => {
    const calclateNetIncome = () => {
      let arr = totalAmountArray.map((e, i) => e + totalsOtherExpense[i])
      setNetIncome(arr);
    }
    calclateNetIncome()
  }, [totalAmountArray,
    totalsOtherExpense])

  return (
    <div style={{ minWidth: "fit-content" }}>
      <div className="accordion-title" style={{
        display: "flex",
        padding: "5px 5px 4px 5px",
        alignItems: 'center', height: "26px"
      }} onClick={() => setIsActive(!isActive)}>
        <div style={{ minWidth: "40px", cursor: "pointer" }}>{isActive ? '-' : '+'}</div>
        <div className="financial-main">
          <td style={{ minWidth: "400px", fontWeight: "bold", fontSize: 14 }} className="fontBold"> {title}</td>
          <div>{
            title === "Ordinary Income/Expense" ?
              !isActive && <>
                {totalAmountArray?.map(e => {
                  return <td style={{ minWidth: "150px", textAlign: "right" }} className="fontBold">${numeral(e).format("0,00.00")}</td>
                })}
                <td style={{ minWidth: "170px", textAlign: "right" }} className="fontBold">${numeral(sum(totalAmountArray)).format("0,00.00")}</td>
              </>
              :
              !isActive && <>
                {totalsOtherExpense?.map(e => {
                  return <td style={{ minWidth: "150px", textAlign: "right" }} className="fontBold">${numeral(e).format("0,00.00")}</td>
                })}
                <td style={{ minWidth: "170px", textAlign: "right" }} className="fontBold">${numeral(sum(totalsOtherExpense)).format("0,00.00")}</td>
              </>
          }
          </div>
        </div>
      </div>
      {isActive &&
        <div className="accordion-content" >
          {title === "Ordinary Income/Expense" ?
            <>
              {data.map((val) => {
                if (val.accttype !== "Other Expense" && val.accttype !== "Other Income") return (
                  <>
                    <AccordionSubInner
                      data={val}
                      dateFrom={dateFrom}
                      dateTo={dateTo}
                    />
                    {
                      val.accttype === "Cost of Goods Sold" ?
                        <AccordionSubInnerGrossProfit
                          data={data}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                        /> : null
                    }
                  </>
                )
              })
              }
              {isActive && <>
                <div style={{ display: "flex", padding: "5px 5px 4px 5px", width: "fit-content", minWidth: "100%" }} >
                  <td style={{ minWidth: "40px" }}></td>
                  <div className="financial-main" style={{ borderTop: "1px solid black", }}>
                    <td style={{ minWidth: "400px", fontWeight: "bold" }} className="fontBold">Net Ordinary Income</td>
                    <div>
                      {totalAmountArray?.map(e => {
                        return <td style={{ minWidth: "150px", textAlign: "right" }} className="fontBold">${numeral(e).format("0,00.00")}</td>
                      })}
                      <td style={{ minWidth: "170px", textAlign: "right" }} className="fontBold">${numeral(sum(totalAmountArray)).format("0,00.00")}</td>
                    </div>
                  </div>
                </div>
              </>
              }
            </>
            :
            <>
              {
                <>
                  {data.map((val) => {
                    if (val.accttype === "Other Expense" || val.accttype === "Other Income") return (
                      <>
                        <AccordionSubInner
                          data={val}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                        />
                      </>
                    )
                  })}
                </>
              }
              {isActive && <>
                <div style={{ display: "flex", padding: "1px 5px 1px 5px", width: "fit-content", minWidth: "100%" }}>
                  <td style={{ minWidth: "40px" }}></td>
                  <div className="financial-main" style={{ borderTop: "1px solid black" }}>
                    <td style={{ minWidth: "400px", fontWeight: "bold" }} className="fontBold">Net Other Income and Expenses</td>
                    <div>
                      {totalsOtherExpense?.map(e => {
                        return <td style={{ minWidth: "150px", textAlign: "right" }} className="fontBold pad-0">${numeral(e).format("0,00.00")}</td>
                      })}
                      <td style={{ minWidth: "170px", textAlign: "right" }} className="fontBold">${numeral(sum(totalsOtherExpense)).format("0,00.00")}</td>
                    </div>
                  </div>
                </div>
              </>
              }
            </>
          }
        </div>}
    </div>
  );
};

const AccordionSubInner = ({ data, keyname, dateFrom, dateTo }) => {
  const [isActive1, setIsActive1] = useState(true);
  const months = data?.items[0][0]?.allmonths;
  const [totalAmountArray, setTotalAmountArray] = useState(Array(months?.length).fill(0));
  const [expensesDataRender, setExpensesDataRender] = useState()

  useEffect(() => {
    const calculateTotalAmounts = () => {
      const totals = Array(months?.length).fill(0);
      data?.items.forEach(itemGroup => {
        itemGroup.forEach(item => {
          item.values.forEach((value, index) => {
            totals[index] += parseFloat(value);
          });
        });
      });
      setTotalAmountArray(totals);
    };
    calculateTotalAmounts();

    if (data?.accttype === "Expense") {
      let parent_ids = []
      data?.items.map(element => {
        if (!parent_ids.includes(element[0]?.parent_account_no)) {
          parent_ids.push(element[0]?.parent_account_no)
        }
      });


      let expensesData = parent_ids?.map((e, ind) => {
        let a = data.items?.filter(x => {
          return x[0]?.parent_account_no === e
        })
        return { acccountName: a[0][0]?.account_name, id: e, items: a }
      })
      setExpensesDataRender(expensesData)
    }

  }, [data]);

  return (
    <div>
      <div className="accordion-title fontBold" style={{ display: "flex", padding: "5px 5px 4px 5px", fontSize: "20px", fontWeight: 'bold', alignItems: 'center', height: "26px", width: "fit-content", minWidth: "100%" }} onClick={() => setIsActive1(!isActive1)}>
        {/* <div style={{ minWidth: "10px" }}></div> */}
        <div style={{ minWidth: "40px", cursor: "pointer" }}>{isActive1 ? '-' : '+'}</div>
        {data?.accttype === "Expense" ?
          <div
            style={{
              
              fontSize: 14, fontWeight: 900,
            }}
            className="fontBold financial-main"
          >
            <td style={{ minWidth: "400px" }} className="fontBold ">{data?.accttype}</td>
            <div>
              {!isActive1 &&
                <>
                  {totalAmountArray?.map(e => {
                    return <td style={{ minWidth: "150px", textAlign: "right" }} className="fontBold">${numeral(e).format("0,00.00")}</td>
                  })}
                  <td style={{ minWidth: "170px", textAlign: "right" }} className="fontBold">${numeral(sum(totalAmountArray)).format("0,00.00")}</td>
                </>
              }
            </div>
          </div>
          : <div
            style={{
              
              fontSize: 14, fontWeight: 900, borderTop: "1px solid black"
              
            }}
            className="fontBold financial-main"
          >
            <td style={{ minWidth: "400px" }} className="fontBold">{data?.accttype}</td>
            <div>
              {!isActive1 &&
                <>
                  {totalAmountArray?.map(e => {
                    return <td style={{ minWidth: "150px", textAlign: "right" }} className="fontBold">${numeral(e).format("0,00.00")}</td>
                  })}
                  <td style={{ minWidth: "170px", textAlign: "right" }} className="fontBold">${numeral(sum(totalAmountArray)).format("0,00.00")}</td>
                </>
              }
            </div>
          </div>}
      </div>
      <div style={{ minWidth: "950px", display: "flex", flexDirection: "row" }}>

      </div>
      {isActive1 &&
        (data?.accttype === "Expense" ?
          <div className="accordion-content">
            {expensesDataRender?.map(e => {
              return <AccordionSubInnerSecondStageExpenses data={e} dateFrom={dateFrom}
                dateTo={dateTo} />
            })}
            {isActive1 &&
              <>
                <div style={{ display: "flex", padding: "1px 5px 1px 5px", width: "fit-content", minWidth: "100%", }} >
                  <div style={{ minWidth: "40px" }} className="fontBold"></div>
                  <div className="financial-main" style={{ borderTop: "1px solid black" }}>
                    <td style={{ minWidth: "400px" }} className="fontBold">Total {data?.accttype}</td>
                    <div >
                      {totalAmountArray?.map(e => {
                        return <td style={{ minWidth: "150px", textAlign: "right" }} className="fontBold pad-0">${numeral(e).format("0,00.00")}</td>
                      })}
                      <td style={{ minWidth: "170px", textAlign: "right" }} className="fontBold">${numeral(sum(totalAmountArray)).format("0,00.00")}</td>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
          : <div className="accordion-content">
            {data?.items.map(e => {
              return <AccordionSubInnerSecondStage data={e[0]} dateFrom={dateFrom}
                dateTo={dateTo} />
            })}
            {isActive1 &&
              <>
                <div style={{ display: "flex", padding: "1px 5px 1px 5px", width: "fit-content", minWidth: "100%" }} >
                  <div style={{ minWidth: "40px" }} className="fontBold"></div>
                  <div className="financial-main" style={{ borderTop: "1px solid black" }}>
                    <td style={{ minWidth: "400px" }} className="fontBold">Total {data?.accttype}</td>
                    <div >
                      {totalAmountArray?.map(e => {
                        return <td style={{ minWidth: "150px", textAlign: "right" }} className="fontBold pad-0">${numeral(e).format("0,00.00")}</td>
                      })}
                      <td style={{ minWidth: "170px", textAlign: "right" }} className="fontBold">${numeral(sum(totalAmountArray)).format("0,00.00")}</td>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>)}
    </div>
  );
};
const AccordionSubInnerGrossProfit = ({ data, keyname, dateFrom, dateTo }) => {
  const [isActive1, setIsActive1] = useState(true);
  const months = data[0]?.items[0][0]?.allmonths;
  const [totalAmountArray, setTotalAmountArray] = useState(Array(months?.length).fill(0));
  const [expensesDataRender, setExpensesDataRender] = useState()
  useEffect(() => {
    const calculateTotalAmounts = () => {
      const totals = Array(months?.length).fill(0);

      data[0]?.items.forEach(itemGroup => {
        itemGroup.forEach(item => {
          item.values.forEach((value, index) => {
            totals[index] += parseFloat(value);
          });
        });
      });
      const totalsSecond = Array(months?.length).fill(0);
      data[1]?.items.forEach(itemGroup => {
        itemGroup.forEach(item => {
          item.values.forEach((value, index) => {
            totalsSecond[index] += parseFloat(value);
          });
        });
      });
      let arr = totals.map((e, i) => e - totalsSecond[i])
      setTotalAmountArray(arr);
    };
    calculateTotalAmounts();

  }, [data]);

  return (
    <div style={{ display: "flex", padding: "5px 5px 4px 5px", width: "fit-content", minWidth: "100%" }}>
      <div style={{ minWidth: "40px" }}></div>
      <div className="financial-main" style={{ borderTop: "1px solid black" }}>
        <td style={{ minWidth: "400px" }} className="fontBold">Gross Profit</td>
        <div>
          {totalAmountArray?.map(e => {
            return <td style={{ minWidth: "150px", textAlign: "right" }} className="fontBold">${numeral(e).format("0,00.00")}</td>
          })}
          <td style={{ minWidth: "170px", textAlign: "right" }} className="fontBold ">${numeral(sum(totalAmountArray)).format("0,00.00")}</td>
        </div>
      </div>
    </div>
  );
};

const sum = (array) => {
  var sum = array?.reduce((accumulator, currentValue) => {
    return accumulator + currentValue
  }, 0)
  return sum
}
const AccordionSubInnerSecondStage = ({ data, keyname, dateFrom, dateTo }) => {
  const [isActive1, setIsActive1] = useState(true);
  if (sum(data?.finalamount)) {
    return (
      <div>
        <div style={{
          
          display: "flex", flexDirection: "row", padding: "1px 5px 1px 5px",
        }}>
          <div style={{ minWidth: "40px" }}> &nbsp; </div>
          <div className="financial-main">
            <td style={{ minWidth: "400px" }}>
              <Link
                style={{ color: "blue", textDecoration: "underline", padding: "10px", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
                to={{ pathname: `/income-detail`, search: `?account_name=${data?.account_name}&date_from=${dateFrom}&date_to=${dateTo}` }}
              >
                {data?.account_name}
              </Link>
            </td>
            <div>
              {data?.finalamount?.map(e => {
                return <td style={{ minWidth: "150px", textAlign: "right" }} className="pad-0">${numeral(e).format("0,00.00")}</td>
              })}
              <td style={{ minWidth: "170px", textAlign: "right" }}>${numeral(sum(data?.finalamount)).format("0,00.00")}</td>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null
  }

};

const AccordionSubInnerSecondStageExpenses = ({ data, keyname, dateFrom, dateTo }) => {
  const [isActive1, setIsActive1] = useState(true);
  
  const months = data?.items[0][0]?.allmonths;
  const [totalAmountArray, setTotalAmountArray] = useState(Array(months?.length).fill(0));
  useEffect(() => {
    const calculateTotalAmounts = () => {
      const totals = Array(months?.length).fill(0);
      data?.items.forEach(itemGroup => {
        itemGroup.forEach(item => {
          item.finalamount.forEach((value, index) => {
            totals[index] += parseFloat(value);
          });
        });
      });
      setTotalAmountArray(totals);
    };
    calculateTotalAmounts();
  }, [data])
  if (sum(data?.items[0][0]?.finalamount) !== 0) {
    
    return (
      <div>
        <div className="accordion-title fontBold" style={{
          display: "flex", padding: "5px 5px 4px 5px",
          
          fontSize: "20px", fontWeight: 'bold', alignItems: 'center', height: "26px"
        }} onClick={() => { data?.items?.length > 1 ? setIsActive1(!isActive1) :console.log("hello")}}>
          {data?.items?.length > 1 ? <div style={{ minWidth: "40px", cursor: "pointer" }}>{isActive1 ? '-' : '+'}</div> : <div style={{ minWidth: "40px" }}></div>}
          <div
            style={{
              
              fontSize: 14, fontWeight: 900, width: "100%"
            }}
            className="fontBold"
          >
            <div>
              {data?.items.length > 1 ?
                <>
                  <div style={{ display: "flex" }} className="financial-main">
                    <td style={{ minWidth: "400px" }} className="fontBold">{data?.items[0][0]?.parent_account_name}</td>
                    <div>
                      {!isActive1 &&
                        <>
                          {totalAmountArray?.map(e => {
                            return <td style={{ minWidth: "150px", textAlign: "right" }} className="fontBold">${numeral(e).format("0,00.00")}</td>
                          })}
                          <td style={{ minWidth: "170px", textAlign: "right" }} className="fontBold">${numeral(sum(totalAmountArray)).format("0,00.00")}sadsad</td>
                        </>
                      }
                    </div>
                  </div>
                </>
                :
                <div>
                  <div style={{ minWidth: "60px" }}>
                  </div>
                  <div className="financial-main">
                    <td style={{ minWidth: "400px" }}>
                      <Link
                        style={{ color: "blue", textDecoration: "underline", padding: "10px", textDecoration: "none" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        to={{
                          pathname: `/income-detail`,
                          search: `?account_name=${encodeURIComponent(data?.items[0][0]?.account_name)}&date_from=${encodeURIComponent(dateFrom)}&date_to=${encodeURIComponent(dateTo)}`
                        }}
                      >
                        {data?.items[0][0]?.account_name}
                      </Link>
                    </td>
                    <div>
                      {data?.items[0][0]?.finalamount?.map(e => {
                        return <td style={{ minWidth: "150px", textAlign: "right" }}>${numeral(e).format("0,00.00")}</td>
                      })}
                  
                      <td className="abc" style={{ minWidth: "170px", textAlign: "right" }}>${sum(data?.items[0][0]?.finalamount).toString().includes("e") ? '0.00' : numeral(sum(data?.items[0][0]?.finalamount)).format("0,00.00")}</td>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        {
          isActive1 &&
          <>
            <div style={{ minWidth: "100%", }}>
              {data?.items?.length !==1&& data?.items?.map((elem,ind) => {
                if ((sum(elem[0]?.finalamount)) !== 0) {
                  return <>
                    <div style={{ display: "flex", padding: "1px 5px 1px 5px" }}>
                      <td style={{ minWidth: "40px" }}></td>
                      <div className="financial-main">
                        <td style={{ minWidth: "400px" }}>
                          <Link
                            style={{ color: "blue", textDecoration: "underline", padding: "10px", textDecoration: "none" }}
                            target="_blank"
                            rel="noopener noreferrer"
                            to={{ pathname: `/income-detail`, search: `?account_name=${elem[0]?.account_name}&date_from=${dateFrom}&date_to=${dateTo}` }}
                          >
                            {elem[0]?.account_name}
                          </Link>
                        </td>
                        <div>
                          {elem[0]?.finalamount?.map(e => {
                            return <td style={{ minWidth: "150px", textAlign: "right" }} className="pad-0">${numeral(e).format("0,00.00")}</td>
                          })}
                          <td style={{ minWidth: "170px", textAlign: "right" }}>${numeral(sum(elem[0]?.finalamount)).format("0,00.00")}</td>
                        </div>
                      </div>
                    </div>
                  </>
                } else {
                  return null
                }
              })
              }
            </div>
            <div style={{ display: "flex", padding: "1px 5px 1px 5px", width: "fit-content", minWidth: "100%", }} >

              <div style={{ minWidth: "40px" }} className="fontBold"></div>
              <div className="financial-main" style={{ borderTop: "1px solid black" }}>
                <td style={{ minWidth: "400px" }} className="fontBold ">Total {data?.items[0][0]?.parent_account_name}</td>
                <div>
                  {totalAmountArray?.map(e => {
                    return <td style={{ minWidth: "150px", textAlign: "right" }} className="fontBold">${numeral(e).format("0,00.00")}</td>
                  })}
                  <td style={{ minWidth: "170px", textAlign: "right" }} className="fontBold">${numeral(sum(totalAmountArray)).format("0,00.00")}</td>
                </div>
              </div>
            </div>
          </>
        }
      </div >
    );
  } else {
    return null
  }
};


import { SEND_EMAIL_ERROR, SEND_EMAIL_SUCCESS, SEND_EMAIL_REQUEST } from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const sendEmail = (token, data) => dispatch => {
    dispatch({ type: SEND_EMAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}communication/PickTicketEmail`,
        
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data
    })
        .then(response => {
            return dispatch({
                type: SEND_EMAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch(error => {
            return dispatch({ type: SEND_EMAIL_ERROR, payload: error.response });
        });
};
// Not Approved
export const sendOrderConfirmationEmail = (token, data) => dispatch => {
    dispatch({ type: SEND_EMAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}communication/OrderConfirmationEmail`,
        
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data
    })
        .then(response => {
            return dispatch({
                type: SEND_EMAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch(error => {
            return dispatch({ type: SEND_EMAIL_ERROR, payload: error.response });
        });
};


export const sendOrderConfirmationApprovedEmail = (token, data) => dispatch => {
    dispatch({ type: SEND_EMAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}communication/OrderConfirmationApprovedSOEmail`,
        
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data
    })
        .then(response => {
            return dispatch({
                type: SEND_EMAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch(error => {
            return dispatch({ type: SEND_EMAIL_ERROR, payload: error.response });
        });
};

export const sendOrderCreditCardDeclinedEmail = (token, data) => dispatch => {
    dispatch({ type: SEND_EMAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}communication/OrderCreditCardDeclinedEmail`,
        
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data
    })
        .then(response => {
            return dispatch({
                type: SEND_EMAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch(error => {
            return dispatch({ type: SEND_EMAIL_ERROR, payload: error.response });
        });
};

export const sendOrderConfirmationOnHoldEmail = (token, data) => dispatch => {
    dispatch({ type: SEND_EMAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}communication/OrderConfirmationOnHoldSOEmail`,
        
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data
    })
        .then(response => {
            return dispatch({
                type: SEND_EMAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch(error => {
            return dispatch({ type: SEND_EMAIL_ERROR, payload: error.response });
        });
};



export const sendOrderConfirmationAutoApprovedPTEmail = (token, data) => dispatch => {
    dispatch({ type: SEND_EMAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}communication/OrderConfirmationAutoApprovedPTEmail`,
        
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data
    })
        .then(response => {
            return dispatch({
                type: SEND_EMAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch(error => {
            return dispatch({ type: SEND_EMAIL_ERROR, payload: error.response });
        });
};
export const sendOrderConfirmationConsignmentEmail = (token, data) => dispatch => {
    dispatch({ type: SEND_EMAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}communication/OrderConfirmationConsignmentEmail`,
        
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data
    })
        .then(response => {
            return dispatch({
                type: SEND_EMAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch(error => {
            return dispatch({ type: SEND_EMAIL_ERROR, payload: error.response });
        });
};
export const sendOrderShippedConfirmationInvoiceEmail = (token, data) => dispatch => {
    dispatch({ type: SEND_EMAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}communication/OrderShippedConfirmationInvoiceEmail`,
        
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data
    })
        .then(response => {
            return dispatch({
                type: SEND_EMAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch(error => {
            return dispatch({ type: SEND_EMAIL_ERROR, payload: error.response });
        });
};
export const sendOrderNotedEmail = (token, data) => dispatch => {
    dispatch({ type: SEND_EMAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}communication/OrderNotedEmail`,
        
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data
    })
        .then(response => {
            return dispatch({
                type: SEND_EMAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch(error => {
            return dispatch({ type: SEND_EMAIL_ERROR, payload: error.response });
        });
};
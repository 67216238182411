import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Select, DatePicker, Spin, Table } from "antd";
import { useLocation } from "react-router-dom";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import moment from "moment";
import logo from "../../assets/images/logo.png";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import "../ProducList/productList.css";
import { TabTitle } from "../../utils/GeneralFunction.js";
import { useDownloadExcel } from "react-export-table-to-excel";
import { getEcommerceDaily } from "../../redux/actions/ecommerceReports.action.js";

const EcommerceDaily = ({ token }) => {
  TabTitle("Ecommerce Daily")
  const { ecommerceDaily } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  var date = moment();
  const [dateTypeId, setDateTypeId] = useState("mte");
  const [monthId, setMonthId] = useState(date.format("MM"));
  const [yearId, setYearId] = useState(date.format("Y"));
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);


  const { onDownload: onDownloadReport1 } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Ecommerce Daily Report",
    sheet: "Sheet 1",
  });

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();


  const columns = [
    {
      title: "#",
      render: (text, record, index) => {
        return index + 1
      },
    },
    {
      title: "Date",
      dataIndex: "tran_date",
      key: "tran_date",
      className: " b_left",
      render: (text, record) => {
        return (
          <>
            {moment(text).format("MM-DD-YYYY")}
          </>
        );
      },
      sorter: (a, b) => {
        return a.tran_date.localeCompare(b.tran_date)
      },
    },
    {
      title: "Day",
      dataIndex: "days",
      key: "days",
      className: " b_left",
      render: (text, record) => {
        return (
          <>
            {text}
          </>
        );
      },
      sorter: (a, b) => {
        return a.days.localeCompare(b.days)
      },
    },
    {
      title: "GROSS $",
      dataIndex: "gross_cy",
      key: "gross_cy",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {parseFloat(text).toLocaleString('en-US')}
          </>
        );
      },
      sorter: (a, b) => {
        return a.gross_cy - b.gross_cy
      },
    },
    {
      title: "DISCOUNT $",
      dataIndex: "discount_cy",
      key: "discount_cy",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {parseFloat(text).toLocaleString('en-US')}
          </>
        );
      },
      sorter: (a, b) => {
        return a.discount_cy - b.discount_cy
      },
    },
    {
      title: "RETURN $",
      dataIndex: "returned_amount_cy",
      key: "returned_amount_cy",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {parseFloat(text).toLocaleString('en-US')}
          </>
        );
      },
      sorter: (a, b) => {
        return a.returned_amount_cy - b.returned_amount_cy
      },
    },
    {
      title: "NET $",
      dataIndex: "net_cy",
      key: "net_cy",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return {
          props: {
            style: { background: '#E8F2FC' }
          },
          children: `${parseFloat(text).toLocaleString('en-US')}`

        }
      },
      sorter: (a, b) => {
        return a.net_cy - b.net_cy
      },
    },
    {
      title: "RETURN RATE",
      dataIndex: "ret_rate_per_cy",
      key: "ret_rate_per_cy",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {text}%
          </>
        );
      },
      sorter: (a, b) => {
        return a.ret_rate_per_cy - b.ret_rate_per_cy
      },
    },
    {
      title: "DISCOUNT RATE",
      dataIndex: "dis_rate_per_cy",
      key: "dis_rate_per_cy",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {text}%
          </>
        );
      },
      sorter: (a, b) => {
        return a.dis_rate_per_cy - b.dis_rate_per_cy
      },
    },
    {
      title: "SHIPPED (U)",
      dataIndex: "shipped_quantity_cy",
      key: "shipped_quantity_cy",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {numeral(text).format("0")}
          </>
        );
      },
      sorter: (a, b) => {
        return a.shipped_quantity_cy - b.shipped_quantity_cy
      },
    },
    {
      title: "RETURN (U)",
      dataIndex: "returned_quantity_cy",
      key: "returned_quantity_cy",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {numeral(text).format("0")}
          </>
        );
      },
      sorter: (a, b) => {
        return a.returned_quantity_cy - b.returned_quantity_cy
      },
    },
    {
      title: "NET (U)",
      dataIndex: "qty_net_cy",
      key: "qty_net_cy",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return {
          props: {
            style: { background: '#E8F2FC' }
          },
          children: `${numeral(text).format("0")}`
        }
      },
      sorter: (a, b) => {
        return a.qty_net_cy - b.qty_net_cy
      },
    },
    {
      title: "AUC",
      dataIndex: "auc_cy",
      key: "auc_cy",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {parseFloat(text).toLocaleString('en-US')}
          </>
        );
      },
      sorter: (a, b) => {
        return a.auc_cy - b.auc_cy
      },
    },
  ];

  const columns1 = [
    {
      title: "#",
      render: (text, record, index) => {
        return index + 1
      },
    },
    {
      title: "Date",
      dataIndex: "tran_date",
      key: "tran_date",
      className: " b_left",
      render: (text, record) => {
        return (
          <>
            {moment(text).format("MM-DD-YYYY")}
          </>
        );
      },
      sorter: (a, b) => {
        return a.tran_date.localeCompare(b.tran_date)
      },
    },
    {
      title: "Day",
      dataIndex: "days",
      key: "days",
      className: " b_left",
      render: (text, record) => {
        return (
          <>
            {text}
          </>
        );
      },
      sorter: (a, b) => {
        return a.days.localeCompare(b.days)
      },
    },
    {
      title: "GROSS $",
      dataIndex: "gross_ly",
      key: "gross_ly",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {parseFloat(text).toLocaleString('en-US')}
          </>
        );
      },
      sorter: (a, b) => {
        return a.gross_ly - b.gross_ly
      },
    },
    {
      title: "DISCOUNT $",
      dataIndex: "discount_ly",
      key: "discount_ly",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {parseFloat(text).toLocaleString('en-US')}
          </>
        );
      },
      sorter: (a, b) => {
        return a.discount_ly - b.discount_ly
      },
    },
    {
      title: "RETURN $",
      dataIndex: "returned_amount_ly",
      key: "returned_amount_ly",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {parseFloat(text).toLocaleString('en-US')}
          </>
        );
      },
      sorter: (a, b) => {
        return a.returned_amount_ly - b.returned_amount_ly
      },
    },
    {
      title: "NET $",
      dataIndex: "net_ly",
      key: "net_ly",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return {
          props: {
            style: { background: '#E8F2FC' }
          },
          children: `${parseFloat(text).toLocaleString('en-US')}`

        }
      },
      sorter: (a, b) => {
        return a.net_ly - b.net_ly
      },
    },
    {
      title: "RETURN RATE",
      dataIndex: "ret_rate_per_ly",
      key: "ret_rate_per_ly",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {text}%
          </>
        );
      },
      sorter: (a, b) => {
        return a.ret_rate_per_ly - b.ret_rate_per_ly
      },
    },
    {
      title: "DISCOUNT RATE",
      dataIndex: "dis_rate_per_ly",
      key: "dis_rate_per_ly",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {text}%
          </>
        );
      },
      sorter: (a, b) => {
        return a.dis_rate_per_ly - b.dis_rate_per_ly
      },
    },
    {
      title: "SHIPPED (U)",
      dataIndex: "shipped_quantity_ly",
      key: "shipped_quantity_ly",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {numeral(text).format("0")}
          </>
        );
      },
      sorter: (a, b) => {
        return a.shipped_quantity_ly - b.shipped_quantity_ly
      },
    },
    {
      title: "RETURN (U)",
      dataIndex: "returned_quantity_ly",
      key: "returned_quantity_ly",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {numeral(text).format("0")}
          </>
        );
      },
      sorter: (a, b) => {
        return a.returned_quantity_ly - b.returned_quantity_ly
      },
    },
    {
      title: "NET (U)",
      dataIndex: "qty_net_ly",
      key: "qty_net_ly",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return {
          props: {
            style: { background: '#E8F2FC' }
          },
          children: `${numeral(text).format("0")}`
        }
      },
      sorter: (a, b) => {
        return a.qty_net_ly - b.qty_net_ly
      },
    },
    {
      title: "AUC",
      dataIndex: "auc_ly",
      key: "auc_ly",
      className: " b_left",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {parseFloat(text).toLocaleString('en-US')}
          </>
        );
      },
      sorter: (a, b) => {
        return a.auc_ly - b.auc_ly
      },
    },
  ];


  // useEffect(() => {
  //   let year = yearId;
  //   let month = monthId;
  //   let currentYearStartDate = moment().startOf("year").format("MM/DD/YYYY");
  //   let currentYearEndDate = moment().endOf("year").format("MM/DD/YYYY");
  //   let lastYearStartDate = moment()
  //     .startOf("year")
  //     .subtract(1, "year")
  //     .format("MM/DD/YYYY");
  //   let lastYearEndDate = moment()
  //     .endOf("year")
  //     .subtract(1, "year")
  //     .format("MM/DD/YYYY");

  //   if (dateTypeId === "yte") {
  //     currentYearStartDate = moment().startOf("year").format("MM/DD/") + year;
  //     currentYearEndDate = moment().endOf("year").format("MM/DD/") + year;
  //     lastYearStartDate = moment().startOf("year").subtract(1, "year").format("MM/DD/") + (year - 1);
  //     lastYearEndDate = moment().subtract(1, "year").endOf("year").format("MM/DD/") + (year - 1);
  //   } else if (dateTypeId === "ytd") {
  //     currentYearStartDate = moment().startOf("year").format("MM/DD/") + year;
  //     currentYearEndDate = moment().format("MM/DD/") + year;
  //     lastYearStartDate = moment().startOf("year").subtract(1, "year").format("MM/DD/") + (year - 1);
  //     lastYearEndDate = moment().subtract(1, "year").format("MM/DD/") + (year - 1);
  //   } else if (dateTypeId === "mtd") {
  //     currentYearStartDate = moment().format("MM") + "/" + "01" + "/" + year;
  //     currentYearEndDate = moment().format("MM") + "/" + moment().format("DD") + "/" + year;
  //     lastYearStartDate = moment().format("MM") + "/" + "01" + "/" + (year - 1);
  //     lastYearEndDate = moment().format("MM") + "/" + moment().format("DD") + "/" + (year - 1);
  //   } else if (dateTypeId === "mte") {
  //     currentYearStartDate = month + "/" + "01" + "/" + year;
  //     currentYearEndDate = month + "/" + moment(`${year} / ${month}`, "YYYY/MM").daysInMonth() + "/" + year;
  //     lastYearStartDate = month + "/" + "01" + "/" + (year - 1);
  //     lastYearEndDate = month + "/" + moment(`${year - 1} / ${month}`, "YYYY/MM").daysInMonth() + "/" + (year - 1);
  //   } else if (dateTypeId === "spring") {
  //     currentYearStartDate = "01" + "/" + "01" + "/" + year;
  //     currentYearEndDate = "06" + "/" + "30" + "/" + year;
  //     lastYearStartDate = "01" + "/" + "01" + "/" + (year - 1);
  //     lastYearEndDate = "06" + "/" + "30" + "/" + (year - 1);
  //   } else if (dateTypeId === "fall") {
  //     currentYearStartDate = "07" + "/" + "01" + "/" + year;
  //     currentYearEndDate = "12" + "/" + "31" + "/" + year;
  //     lastYearStartDate = "07" + "/" + "01" + "/" + (year - 1);
  //     lastYearEndDate = "12" + "/" + "31" + "/" + (year - 1);
  //   }
  //   setCurrentYearStartDate(currentYearStartDate);
  //   setCurrentYearEndDate(currentYearEndDate);
  //   setLastYearStartDate(lastYearStartDate);
  //   setLastYearEndDate(lastYearEndDate);

  //   const newState = {
  //     cy_start_date: currentYearStartDate,
  //     cy_end_date: currentYearEndDate,
  //     cy_end_date_ytd: currentYearEndDateYtd,
  //     cy_start_date_mte: currentYearStartDateMte,
  //     cy_end_date_mte: currentYearEndDateMte,

  //     ly_start_date: lastYearStartDate,
  //     ly_end_date: lastYearEndDate,
  //     ly_end_date_ytd: lastYearEndDateYtd,
  //     ly_start_date_mte: lastYearStartDateMte,
  //     ly_send_date_mte: lastYearEndDateMte,
  //     region_id: regionID,
  //     sales_person_id: salePersonName,
  //     gender_id: genderID,
  //   };
  //   setFormState(newState);
  // }, [dateTypeId, monthId, yearId]);

  useEffect(() => {
    let year = yearId;
    let month = monthId;
    let currentYearStartDate = moment().startOf("year").format("MM/DD/YYYY");
    let currentYearEndDate = moment().endOf("year").format("MM/DD/YYYY");
    let lastYearStartDate = moment()
      .startOf("year")
      .subtract(1, "year")
      .format("MM/DD/YYYY");
    let lastYearEndDate = moment()
      .endOf("year")
      .subtract(1, "year")
      .format("MM/DD/YYYY");
    if (dateTypeId === "mtd") {
      currentYearStartDate = moment().format("MM") + "/" + "01" + "/" + year;
      currentYearEndDate = moment().format("MM") + "/" + moment().format("DD") + "/" + year;
      lastYearStartDate = moment().format("MM") + "/" + "01" + "/" + (year - 1);
      lastYearEndDate = moment().format("MM") + "/" + moment().format("DD") + "/" + (year - 1);
    } else if (dateTypeId === "mte") {
      currentYearStartDate = month + "/" + "01" + "/" + year;
      currentYearEndDate = month + "/" + moment(`${year} / ${month}`, "YYYY/MM").daysInMonth() + "/" + year;
      lastYearStartDate = month + "/" + "01" + "/" + (year - 1);
      lastYearEndDate = month + "/" + moment(`${year - 1} / ${month}`, "YYYY/MM").daysInMonth() + "/" + (year - 1);
    }
    const newState = {
      cy_start_date: currentYearStartDate,
      cy_end_date: currentYearEndDate,
      ly_start_date: lastYearStartDate,
      ly_end_date: lastYearEndDate,
    };
    setFormState(newState);
  }, [dateTypeId, monthId, yearId]);

  useEffect(() => {
    if (dateTypeId === "mtd") {
      setMonthId(moment().format("MM"));
    }
  }, [dateTypeId]);

  const onFinish = () => {
    setLoading(true);
    dispatch(getEcommerceDaily(token, formState)).then((res) => {
      if (res.type === "GET_ECOMMERCE_DAILY_SUCCESS") {
        setLoading(false);
      }
    });
  };

  const lastYearData = ecommerceDaily?.payload?.data.filter(item => item.year_code === "ly");
  const currentYearData = ecommerceDaily?.payload?.data.filter(item => item.year_code === "cy");

  const cyData = currentYearData?.map(({ tran_date, days, gross_cy, discount_cy, returned_amount_cy, net_cy, ret_rate_per_cy, dis_rate_per_cy, shipped_quantity_cy, returned_quantity_cy, qty_net_cy, auc_cy }) => ({
    tran_date,
    days,
    gross_cy,
    discount_cy,
    returned_amount_cy,
    net_cy,
    ret_rate_per_cy,
    dis_rate_per_cy,
    shipped_quantity_cy,
    returned_quantity_cy,
    qty_net_cy,
    auc_cy,
  }));

  const lyData = lastYearData?.map(({ tran_date, days, gross_ly, discount_ly, returned_amount_ly, net_ly, ret_rate_per_ly, dis_rate_per_ly, shipped_quantity_ly, returned_quantity_ly, qty_net_ly, auc_ly }) => ({
    tran_date,
    days,
    gross_ly,
    discount_ly,
    returned_amount_ly,
    net_ly,
    ret_rate_per_ly,
    dis_rate_per_ly,
    shipped_quantity_ly,
    returned_quantity_ly,
    qty_net_ly,
    auc_ly,
  }));


  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      <div className="container-fluid">

        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row justify-content-between pb-0">
                <div className="col-3 d-flex align-items-start  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>

                <div className="col-3 box-space right-content-header text-right">
                  <div className=" d-flex justify-content-end">
                    <div className=" d-flex justify-content-end printing_class">
                      <div className="print_icon printing_class">
                        <i
                          id="Image1"
                          title="Print"
                          alt="Print"
                          className="fa-solid fa-print printing_class"
                          onClick={handlePrint}
                        ></i>
                      </div>
                      <div className="print_icon printing_class">
                        {ecommerceDaily?.payload?.data ? (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            onClick={onDownloadReport1}
                            style={{
                              borderWidth: "0px",
                              height: "14px",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            style={{ borderWidth: "0px", height: "14px" }}
                          />
                        )}
                      </div>
                    </div>
                    <span style={{ fontWeight: "bolder" }}>
                      Ecommerce Daily
                      <br />
                      Rpt # 006
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>

                    </span>
                  </div>
                </div>
              </div>
              <div className="row pb-2">
                <div className="col">
                  <Form
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    layout="horizontal"
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                  >
                    <div className="main_form row">
                      <div className=" col-6">
                        <div className=" d-flex flex-row align-items-baseline">
                          <label htmlFor="gender_id" className="labels width30">
                            Condition:
                          </label>
                          <div style={{ marginBottom: "-2px" }} className=" w-100">
                            <Form.Item
                              name="dateTypeId"
                              className="formFullWidth"
                            >
                              <Select
                                allowClear
                                size="small"
                                showSearch
                                optionFilterProp="children"
                                className={"select_Inputs"}
                                defaultValue={dateTypeId}
                                value={dateTypeId}
                                onChange={(a) => {
                                  setDateTypeId(a);
                                  setYearId(date.format("Y"));
                                }}
                              >
                                <Option key={"mtd"} value={"mtd"}>
                                  Month To Date
                                </Option>
                                <Option key={"mte"} value={"mte"}>
                                  Month To End
                                </Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className=" col-6">
                        <div className=" d-flex flex-row align-items-baseline">
                          <label className="labels width30">Month:</label>
                          <div style={{ marginBottom: "2px" }} className=" w-100">
                            <DatePicker
                              size="small"
                              className="filter-fields full_date"
                              value={moment(monthId)}
                              // value={moment(dateTypeId === "mtd" ? moment() : monthId)}
                              disabled={dateTypeId === "mtd"}
                              onChange={(e) =>
                                setMonthId(moment(e).format("MM"))
                              }
                              picker="month"
                              format={"MM"}
                              mode="month"
                            />
                          </div>
                        </div>
                        <div style={{ marginTop: "0px", display: "flex", justifyContent: "end" }} >
                          <Button type="primary" htmlType="submit" className='submit-btn-btm m-0' disabled={loading}>
                            Search
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="table_content"
        style={{ width: 'fit-content' }}
        ref={tableRef}
      >
        <div>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin />
            </div>
          ) : ecommerceDaily?.payload?.data.length ? (
            <>
              <div style={{ marginBottom: '20px' }}>
                <h3 style={{ borderBottom: "1px solid black", paddingBottom: "1px", display: "inline-block", marginBottom: "0px" }}>This Year</h3>
                <Table
                  style={{ fontSize: '10.3px' }}
                  className="test04 mt-3 sales_target_report_table sales_report_res_table sales_target_report_table_dashboard speciality_state"
                  size="small"
                  pagination={false}
                  bordered
                  columns={columns}
                  scroll={{ x: 'max-content' }}
                  dataSource={cyData}
                  summary={pageData => {
                    let grossCY = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.gross_cy)
                    }, 0);
                    let discountCY = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.discount_cy)
                    }, 0);
                    let returnCY = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.returned_amount_cy)
                    }, 0);
                    let netCY = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.net_cy)
                    }, 0);
                    let shipped_U_CY = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.shipped_quantity_cy)
                    }, 0);
                    let return_U_CY = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.returned_quantity_cy)
                    }, 0);
                    let net_U_CY = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.qty_net_cy)
                    }, 0);
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right" }}>Total</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(grossCY.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(discountCY.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(returnCY.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(netCY.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(((returnCY / grossCY) * 100).toLocaleString("en-US")).format("0")}%
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(((discountCY / grossCY) * 100).toLocaleString("en-US")).format("0")}%
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(shipped_U_CY.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(return_U_CY.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(net_U_CY.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral((netCY / net_U_CY).toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </div>
              <div>
                <h3 style={{ borderBottom: "1px solid black", paddingBottom: "1px", display: "inline-block", marginBottom: "0px" }}>Last Year</h3>
                <Table
                  style={{ fontSize: '10.3px' }}
                  className="test04 mt-3 sales_target_report_table sales_report_res_table sales_target_report_table_dashboard speciality_state"
                  size="small"
                  pagination={false}
                  bordered
                  columns={columns1}
                  scroll={{ x: 'max-content' }}
                  dataSource={lyData}
                  summary={pageData => {
                    let grossLY = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.gross_ly)
                    }, 0);
                    let discountLY = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.discount_ly)
                    }, 0);
                    let returnLY = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.returned_amount_ly)
                    }, 0);
                    let netLY = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.net_ly)
                    }, 0);
                    let shipped_U_LY = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.shipped_quantity_ly)
                    }, 0);
                    let return_U_LY = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.returned_quantity_ly)
                    }, 0);
                    let net_U_LY = pageData.reduce(function (previousValue, currentValue) {
                      return previousValue += parseFloat(currentValue.qty_net_ly)
                    }, 0);
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right" }}>Total</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(grossLY.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(discountLY.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(returnLY.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(netLY.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(((returnLY / grossLY) * 100).toLocaleString("en-US")).format("0")}%
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(((discountLY / grossLY) * 100).toLocaleString("en-US")).format("0")}%
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(shipped_U_LY.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(return_U_LY.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral(net_U_LY.toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell className="sales_table_summary">
                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                              {numeral((netLY / net_U_LY).toLocaleString("en-US")).format("0,00")}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                backgroundColor: '#CCCCCC',
                borderWidth: '1pt',
                borderStyle: 'Dashed',
                fontWeight: 'bold',
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              No record(s)
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EcommerceDaily;

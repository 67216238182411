import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRegionCategory } from "../../redux/actions/regionCategoryById";
import { loadRegionCategory } from "../../redux/actions/regionCategory";
import { loadSalesPersons } from "../../redux/actions/salesPerson";
import { getRights } from "../../redux/actions/userRights.action";
import { getGender } from "../../redux/actions/gender.action";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Row,
  Col,
  Checkbox,
  Tooltip,
  Popconfirm,
} from "antd";
import { loadRegion } from "../../redux/actions/region";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import Tables from "../../components/Table/Tables";
import { Row as Brow, Col as Bcol } from "reactstrap";

const { confirm } = Modal;
const { Option } = Select;

export default function CategorySection({
  customer_id,
  token,
  formType,
  categoryData,
  categorylist,
  category,
  setCategory,
  deletedCategoryLine,
  setDeletedCategoryLine,
}) {
  const menuId = ReadCookie("menuId");
  const [form] = Form.useForm();
  const [categoryID, setCategoryID] = useState(null);
  const [genderForm, setGenderForm] = useState({});
  const [regionCatForm, setRegionCatForm] = useState({});
  const [salesPerson, setSalesperson] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [EmployeeID, setEmployeeid] = useState(null);
  const [regionCategoryID, setRegionCategoryid] = useState(null);
  const [RegionID, setRegionsid] = useState(null);
  const [editForm, setEditForm] = useState(null);
  const [categoryObj, setCategoryObj] = useState(null);
  const [check, setCheck] = useState(true);
  const [itemIndex, setItemIndex] = useState(null);
  const [modalAddMode, setModalAddMode] = useState(true);

  useLayoutEffect(() => {
    dispatch(getRights(token, menuId))
  }, []);

  const categoryColumns = [
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_id",
      width: 25,
    },
    {
      title: "Category Id",
      dataIndex: "sp_region_category_id",
      key: "sp_region_category_id",
      width: 25,
    },
    {
      title: "Region",
      dataIndex: "region_name",
      key: "region_id",
      width: 20,
    },

    {
      title: "Sales person",
      dataIndex: "salesperson_name",
      key: "sales_person_id",
      width: 25,
    },

    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      width: 25,
      render: (text, record) => {
        return <p>{record.is_active ? "True" : "False"}</p>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: 25,
      render: (text, record, index) => (
        <>
          {
            <>
              {formType === "edit" && (
                <Tooltip title="Edit" color={"white"}>
                  <EditOutlined
                    className={`edit-Button`}
                    disabled={true}
                    onClick={() => hanldeSubItems(record, index)}
                  />
                </Tooltip>
              )}
              <Tooltip title="Delete" color={"white"}>
                <Popconfirm
                  title={`Are you sure to delete the Gender ${record.gender_name}?`}
                  onConfirm={() => hanldeDeleteItems(record, index)}
                >
                  <DeleteOutlined className={`delete-Button`} />
                </Popconfirm>
              </Tooltip>
            </>
          }
        </>
      ),
    },
  ];

  useEffect(() => {
    if (formType === "add") {
      return setCategory([]);
    }
    if (
      (customer_id !== "" && formType === "formView") ||
      formType === "edit"
    ) {
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  }, [customer_id]);

  useEffect(() => {
    dispatch(loadRegionCategory(token));
  }, [token]);

  useEffect(() => {
    dispatch(getGender(token, menuId));
  }, [token]);

  const hanldeSubItems = (record, index) => {
    setModalAddMode(false);
    setShowForm(true);
    setEditForm("edit");
    setVisible(true);

    setCategoryID(record.customer_sales_category_id);
    setItemIndex(index);

    setCategoryObj(record);

    form.setFieldsValue({
      gender: record.gender_id,
      regionCategory: record.sp_region_category_id,
      active: record.is_active,
    });
  };

  const hanldeDeleteItems = (record, index) => {
    let copyArr = [...category];
    setCategory(copyArr.filter((v, i) => i !== index));
    categoryData(copyArr.filter((v, i) => i !== index));
    const deleteDataSource = [...deletedCategoryLine];
    deleteDataSource.push(record);
    setDeletedCategoryLine(deleteDataSource);
    notification("success", `Item Deleted Gender (${record?.gender_name})`);
  };

  const onChangeGender = (value, obj) => {
    setGenderForm(obj);
  };

  const dispatch = useDispatch();
  const {
    gender,
    salePerson,
    Category,
    regionCategory,
    Region,
    getRegionCategoryId,
    userRights,
  } = useSelector((state) => state);

  useEffect(() => {
    if (EmployeeID === null) {
    } else {
      dispatch(loadSalesPersons(token, EmployeeID));
    }
  }, [EmployeeID]);

  useEffect(() => {
    if (RegionID === null) {
    } else {
      dispatch(loadRegion(token, RegionID));
      setShowForm(true);
    }
  }, [RegionID]);

  const onChangeRegionCategory = (value, obj) => {
    let emp = "";
    let reg = "";

    dispatch(getRegionCategory(token, `${value}`)).then((regionCatg) => {
      if (regionCatg.type === "GET_REGIONCATG_BYID_SUCCESS") {

        emp = regionCatg.payload.data[0].sales_person_id;
        reg = regionCatg.payload.data[0].region_id;
        setEmployeeid(emp);
        setRegionsid(reg);
        setRegionCategoryid(regionCatg.payload.data[0]?.sp_region_category_id);
        dispatch(loadSalesPersons(token, emp)).then((res) => {
          setSalesperson(res.payload.data[0]);
        });
        dispatch(loadRegion(token, reg)).then((region) => {
          if (region.type === "GET_REGION_SUCCESS") {
            setRegionCatForm(region.payload.data[0]);
            setShowForm(true);
          }
        });
      }
      if (regionCatg.type === "GET_REGIONCATG_BYID_ERROR") {
        notification("error", "Category", regionCatg?.payload?.data);
      }
    });
  };

  const onSearch = (val) => {};

  const handleOk = async (e, record) => {
    await form.validateFields().then((values) => {
      values.active = check;
      values.employee = EmployeeID;
      values.region = RegionID;

      if (formType === "add") {
        if (editForm === null) {
          setCategory([
            ...category,
            {
              gender_name: genderForm.children,
              gender_id: genderForm.value,
              region_name: regionCatForm.region_name,
              region_id: regionCatForm.region_id,
              salesperson_name: salesPerson.salesperson_name,
              sales_person_id: salesPerson.sales_person_id,
              is_active: check,
              sp_region_category_id: values.regionCategory,
            },
          ]);
          categoryData([
            ...category,
            {
              gender_name: genderForm.children,
              gender_id: genderForm.value,
              region_name: regionCatForm.region_name,
              region_id: regionCatForm.region_id,
              salesperson_name: salesPerson.salesperson_name,
              sales_person_id: salesPerson.sales_person_id,
              is_active: check,
              sp_region_category_id: values.regionCategory,
            },
          ]);
        } else {
          let copyArr = [...category];
          copyArr[itemIndex].gender_name = genderForm.children;
          copyArr[itemIndex].gender_id = genderForm.value;
          copyArr[itemIndex].region_name = regionCatForm.region_name;
          copyArr[itemIndex].region_id = regionCatForm.region_id;
          copyArr[itemIndex].salesperson_name = salesPerson.salesperson_name;
          copyArr[itemIndex].sales_person_id = salesPerson.sales_person_id;
          copyArr[itemIndex].is_active = check;
          setCategory(copyArr);
          categoryData(copyArr);
        }
      } else if (formType === "edit") {
        // if (editForm === null) {
        //   setCategory([...category, {
        //     gender_name: genderForm.children, gender_id: genderForm.value, region_name: regionCatForm.region_name, region_id: regionCatForm.region_id,
        //     salesperson_name: salesPerson.salesperson_name, sales_person_id: salesPerson.sales_person_id, is_active: check, sp_region_category_id: values.regionCategory,
        //   }])
        //   categoryData([...category, {
        //     gender_name: genderForm.children, gender_id: genderForm.value, region_name: regionCatForm.region_name, region_id: regionCatForm.region_id,
        //     salesperson_name: salesPerson.salesperson_name, sales_person_id: salesPerson.sales_person_id, is_active: check, sp_region_category_id: values.regionCategory,
        //   }]);
        // } else {
        //   console.log("RUNNING", values)
        //   console.log("RUNNING categoryObj", genderForm)
        if (modalAddMode) {
          let newObj = {
            gender_name: genderForm.children,
            gender_id: genderForm.value,
            region_name: regionCatForm.region_name,
            region_id: regionCatForm.region_id,
            salesperson_name: salesPerson.salesperson_name,
            sales_person_id: salesPerson.sales_person_id,
            sp_region_category_id: regionCategoryID,
            is_active: check,
          };
          setCategory([...category, newObj]);
          // setCategoryObj([...categoryObj, newObj]);
          categoryData([...categorylist, newObj]);
          setVisible(false)
        } else {
          let copyArr = [...category];
          copyArr[itemIndex].gender_name = genderForm.children
            ? genderForm.children
            : categoryObj.gender_name;
          copyArr[itemIndex].gender_id = genderForm.value
            ? genderForm.value
            : categoryObj.gender_id;
          copyArr[itemIndex].region_name = regionCatForm.region_name
            ? regionCatForm.region_name
            : categoryObj.region_name;
          copyArr[itemIndex].region_id = regionCatForm.region_id
            ? regionCatForm.region_id
            : categoryObj.region_id;
          copyArr[itemIndex].salesperson_name = salesPerson.salesperson_name
            ? salesPerson.salesperson_name
            : categoryObj.salesperson_name;
          copyArr[itemIndex].sales_person_id = salesPerson.sales_person_id
            ? salesPerson.sales_person_id
            : categoryObj.sales_person_id;
          copyArr[itemIndex].sp_region_category_id = regionCategoryID
            ? regionCategoryID
            : categoryObj.sp_region_category_id;
          copyArr[itemIndex].is_active = check;
          setCategory(copyArr);
          categoryData(copyArr);
        }
        // }
      }

      setCategoryID(null);
      setVisible(false);
      setEditForm(null);
    });
  };

  const handleCancel = () => {
    setVisible(false);
    setShowForm(false);
    setEmployeeid(null);
    setRegionsid(null);
    setEditForm("");
    form.setFieldsValue({
      regionCategory: null,
      employee_id: null,
      gender: null,
      region: null,
    });
  };
  return (
    <>
      <div>
        <Modal
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          title="Category Form"
          okText="Submit"
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              modifier: "public",
            }}
          >
            <div className="container-fluid p-0 modal-form-main">
              <Brow>
                <Bcol lg={12} xl={12} xxl={12}>
                  <Form.Item name="gender" label="Gender">
                    <Select
                      size="small"
                      showSearch
                      placeholder="Select Gender"
                      optionFilterProp="children"
                      value={genderForm}
                      onChange={onChangeGender}
                      onSearch={onSearch}
                      filterOption={(input, options) =>
                        options.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {gender?.payload?.data.map((gender) => (
                        <Option key={gender.gender_id} value={gender.gender_id}>
                          {gender.gender_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="regionCategory"
                    label="Region Category"
                    className="label-height-adjust"
                  >
                    <Select
                      size="small"
                      className="w-100"
                      showSearch
                      placeholder="Select Region Category"
                      optionFilterProp="children"
                      value={regionCatForm}
                      onChange={onChangeRegionCategory}
                      onSearch={onSearch}
                      find={(input, options) =>
                        options.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {regionCategory?.payload?.data.map((regioncatg, i) => (
                        <Option
                          key={i}
                          value={regioncatg.sp_region_category_id}
                        >
                          {regioncatg.sp_region_category_id}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="active" label="Active">
                    <Checkbox
                      size="small"
                      className="styleInput"
                      checked={check}
                      onChange={(e) => setCheck(e.target.checked)}
                    />
                  </Form.Item>
                </Bcol>
              </Brow>
            </div>

            {/* <Row style={{ width: "100%", display: "flex", gap: "10%" }} >
              <Col xs={24} sm={12} md={8} lg={18} xl={6}>
                <label>Gender</label>
              </Col>
              <Col xs={24} sm={12} md={8} lg={18} xl={6}>
              </Col>
            </Row>

            <Row style={{ width: "100%", display: "flex", gap: "10%" }} >
              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <label>Region Category</label>
              </Col>
              <Col xs={24} sm={12} md={6} lg={6} xl={7}>
              </Col>
              <Col xs={24} sm={12} md={8} lg={4} xl={1}>
                <label>Active</label>
              </Col>
              <Col xs={24} sm={24} md={8} lg={4} xl={1}>
              </Col>
            </Row> */}
            {showForm && (
              <>
                <Row style={{ width: "100%", display: "flex", gap: "10%" }}>
                  <Col xs={24} sm={12} md={8} lg={18} xl={6}>
                    <label>Employe</label>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={18} xl={10}>
                    <Form.Item
                      name="employee"
                      initialValue={form.getFieldValue().employee}
                    >
                      {salePerson?.payload?.data &&
                        salePerson?.payload?.data.map((emp, i) => (
                          <Input
                            size="small"
                            style={{ borderRadius: "10px", width: 300 }}
                            disabled
                            value={emp.salesperson_name}
                          />
                        ))}
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ width: "100%", display: "flex", gap: "10%" }}>
                  <Col xs={24} sm={12} md={8} lg={18} xl={6}>
                    <label>Region</label>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={18} xl={10}>
                    <Form.Item
                      name="region"
                      initialValue={form.getFieldValue().region}
                    >
                      {Region?.payload?.data &&
                        Region?.payload?.data.map((reg) => (
                          <Input
                            size="small"
                            style={{ borderRadius: "10px", width: 300 }}
                            disabled
                            value={reg.region_name}
                          />
                        ))}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Modal>
      </div>
      {/* <div className="productTable"> */}
      <Button
        disabled={!(formType === "add" || formType === "edit")}
        className="iconBtn crudBTN float-right mbpx-5"
        shape="circle"
        icon={<PlusOutlined />}
        onClick={() => {
          setVisible(true);
          setModalAddMode(true);
        }}
      />

      <Tables
        type="category"
        categoryObj={setCategoryObj}
        scroll={500}
        scrollX={"90rem"}
        source={JSON.parse(JSON.stringify(category))}
        col={categoryColumns}
        load={Category.loading}
      />
      {/* </div> */}
    </>
  );
}

import React, { useEffect, useState } from 'react'
import "./LeaderBoard.css";
import { Button, Col, DatePicker, Row, Skeleton, Tag } from 'antd';
import { FireFilled } from '@ant-design/icons';
import Tables from '../../components/Table/Tables';
import { Chart } from "react-google-charts";
import { useDispatch, useSelector } from 'react-redux';
import { getLeaderBoard } from '../../redux/actions/leaderBoard.action';
import { ReadCookie } from '../../utils/readCookie';
import numeral from 'numeral';
import moment from 'moment';


const LeaderBoard = () => {
    const { leaderboard } = useSelector((state) => state);
    const dispatch = useDispatch();
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");

    const [filter, setFilter] = useState({

        from_date: moment().startOf('year').year(2024),
        to_date: moment().endOf('year').year(2024),
    });

    console.log("filter", filter);

    const groupedLeaderBoard = leaderboard?.payload?.data?.reduce((acc, item) => {
        const { salerperson_name } = item;
        if (!acc[salerperson_name]) {
            acc[salerperson_name] = [];
        }
        acc[salerperson_name].push(item);
        return acc;
    }, {});



    const onFinish = () => {
        dispatch(getLeaderBoard(token, { from_date: moment(filter.from_date).format("MM-DD-YYYY"), to_date: moment(filter.to_date).format("MM-DD-YYYY") }))
    }



    useEffect(() => {
        dispatch(getLeaderBoard(token, { from_date: moment(filter.from_date).format("MM-DD-YYYY"), to_date: moment(filter.to_date).format("MM-DD-YYYY") }))
    }, [])


    return (
        <div className='leaderboard-container'>
            <div className='leaderboard-hd-main'>Leaderboard</div>

            <div className='dashboard-card-main filter-card'>
                <div className='dashboard-card-content'>
                    <Row className='mb-20' gutter={[{ xs: 6, sm: 12, md: 16, lg: 24, xl: 30, xxl: 32 }, { xs: 6, sm: 12, md: 16, lg: 24, xl: 30, xxl: 32 }]}>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="gutter-row mb-4">
                            <div className="filter-hd">From Month :</div>

                            <DatePicker
                                size="small"
                                className="styleInput w-100"
                                defaultValue={filter.from_date}

                                onChange={(e) => setFilter({ ...filter, from_date: e })}
                                picker="month"
                                format={"MM"}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="gutter-row mb-4">
                            <div className="filter-hd">To Month :</div>

                            <DatePicker
                                size="small"
                                className="styleInput w-100"
                                defaultValue={filter.to_date}
                                onChange={(e) => setFilter({ ...filter, to_date: e })}

                                picker="month"
                                format={"MM"}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="gutter-row mb-4">

                            <Button
                                style={{ marginTop: 25, width: 100 }}
                                // htmlType="submit"
                                type="primary"
                                // className="submit-btn-btm "
                                onClick={onFinish}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>


                    {/* <div className='filter-hd'>Gender Category :</div> */}
                    {/* <div>
                        {
                            MONTHS.map((val, idx) => {
                                return <Tag
                                    key={idx}
                                    className={`leaderboard_tag ${filter.month.includes(val) && 'leaderboard_tag_active'}`}
                                    onClick={() => {
                                        if (filter.month.includes(val)) {
                                            let removeMonth = filter.month.filter((v) => v !== val);
                                            setFilter({ ...filter, month: removeMonth })
                                        } else {
                                            setFilter({ ...filter, month: [...filter.month, val] })
                                        }
                                    }}
                                >
                                    {val.slice(0, 3).toUpperCase()}
                                </Tag>
                            })
                        }
                    </div> */}

                    {/* <div className="filter-hd">Year :</div> */}

                    {/* <div>
                        {
                            YEAR.map((val, idx) => {
                                return <Tag
                                    key={idx}
                                    className={`leaderboard_tag ${filter.year.includes(val) && 'leaderboard_tag_active'}`}
                                    onClick={() => {
                                        if (filter.year.includes(val)) {
                                            let removeYear = filter.year.filter((v) => v !== val);
                                            setFilter({ ...filter, year: removeYear })
                                        } else {
                                            setFilter({ ...filter, year: [...filter.year, val] })
                                        }
                                    }}
                                >
                                    {val}
                                </Tag>
                            })
                        }
                    </div> */}


                </div>
            </div>
            <div>
                <Row className='mb-20' gutter={[{ xs: 6, sm: 12, md: 16, lg: 24, xl: 30, xxl: 32 }, { xs: 6, sm: 12, md: 16, lg: 24, xl: 30, xxl: 32 }]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="gutter-row mb-4">
                        <div className='leaderboard-card-main'>
                            <div className='leaderboard-card-hd'><FireFilled className='fireIcon' /> Top Sales Person</div>
                            <Row className='leaderboard-logs-card' gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="gutter-row mb-4">
                                    {
                                        leaderboard?.loading ? (<Skeleton />) : (
                                            groupedLeaderBoard && Object.keys(groupedLeaderBoard)?.length > 0 ? (
                                                <LeaderBoardChart groupedData={groupedLeaderBoard} />
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        )}
                                </Col>


                            </Row>
                            <LeaderBoardTable loading={leaderboard?.loading} data={leaderboard?.payload?.data} />
                        </div>
                    </Col>
                </Row>

            </div>

        </div>
    )
}

export default LeaderBoard;


let colors = ["007ea7", "F94144", "577590", "F3722C", "90BE6D", "F8961E", "F9C74F"]

const LeaderBoardChart = ({ groupedData }) => {

    const header = ["Sale Person", "% Plan Achieved"];

    // Process the data
    let data = [
        header, // Header for the chart
        ...Object.keys(groupedData || {})
            .map((salesperson) => {
                const totalSales = groupedData[salesperson].reduce(
                    (sum, record) => sum + parseInt(record?.total_sales || 0, 10),
                    0
                );

                const totalPlanAmount = groupedData[salesperson].reduce(
                    (sum, record) => sum + parseInt(record?.planamount || 0, 10),
                    0
                );


                let total_percent = totalPlanAmount > 0 ? (totalSales / totalPlanAmount) * 100 : null
                return [salesperson, total_percent]; // Return [name, totalSales]
            })
            .sort((a, b) => b[1] - a[1]) // Sort by total sales (descending)
            .slice(0, 10), // Take only the top 10
    ];





    const options = {
        title: "Top 10 Sales Person",
        chartArea: { width: "70%" },
        hAxis: {
            title: "% Plan Achieved",
            minValue: 0,
        },
        colors: colors
        // vAxis: {
        //     title: "Salesperson",
        // },
    };

    return (
        <Chart
            chartType="BarChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
    );
};

const LeaderBoardTable = ({ loading, data = [] }) => {



    const sortedData = data.sort((a, b) => b.total_sales - a.total_sales);

    const columns = [
        {
            title: 'Position',
            dataIndex: '',
            key: '',
            render: (text, record, idx) => {
                return idx + 1
            },
        },

        {
            title: 'Month',
            dataIndex: 'month_name',
            key: 'month_name',
        },

        {
            title: 'Year',
            dataIndex: 'yearr',
            key: 'yearr',
        },
        {
            title: 'Sale Person Name',
            dataIndex: 'salerperson_name',
            key: 'salerperson_name',
        },
        {
            title: 'Plan',
            dataIndex: 'planamount',
            key: 'planamount',
            render: (text, record, idx) => {
                return numeral(text).format('0,0.00')
            },
        },
        {
            title: 'Net Sale',
            dataIndex: 'total_sales',
            key: 'total_sales',
            render: (text, record, idx) => {
                return numeral(text).format('0,0.00')
            },
        },
        {
            title: 'Percentage',
            // dataIndex: 'total_sales',
            // key: 'total_sales',
            render: (text, record, idx) => {
                const totalSales = parseInt(record.total_sales, 10) || 0;
                const planAmount = parseInt(record.planamount, 10) || 0;

                const percentage = planAmount > 0 ? (totalSales / planAmount) * 100 : 0;
                return numeral(percentage).format("0.00")
            },
        },
    ];

    return (

        <div className='leaderboard-card-content'>
            <div className='leaderboard-card-hd'>Plan Vs Net Sales Details</div>
            <Tables
                type={"saleOrderLine"}
                load={loading}
                scroll={({ x: 600 }, { y: 400 })}
                size="small"
                bordered
                perPage={100}
                source={sortedData}
                col={columns}
                paginate
                pagination={{ pageSize: 100 }}
            />
            {/* <Table dataSource={dataSource} columns={columns} /> */}
        </div>

    )
}
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Select, DatePicker, Spin, Table } from "antd";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { getGender } from "../../redux/actions/gender.action";
import { ReadCookie } from "../../utils/readCookie";
import numeral, { Numeral } from "numeral";

import moment from "moment";

import logo from "../../assets/images/logo.png";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import "../ProducList/productList.css";
import { TabTitle } from "../../utils/GeneralFunction.js";
import { useDownloadExcel } from "react-export-table-to-excel";
import { getAllActiveRegion, getAllActiveSaleperson } from "../../redux/actions/getAllActive.js";
import { getSpecialityByRegion, getSpecialityByState } from "../../redux/actions/specialityReport.action.js";

const SpecialityByState = ({ token }) => {
  TabTitle("Specialty By State")
  const { gender, specialityByState, saleComparisionCustomerWiseReport, salePersonNew, RegionNew } = useSelector((state) => state);
  const [salePersonName, setSalePersonName] = useState(null);
  const [genderID, setGenderID] = useState(null);
  const [regionID, setRegionID] = useState(null);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  const history = useHistory();
  var date = moment();

  const [dateTypeId, setDateTypeId] = useState("yte");
  const [monthId, setMonthId] = useState(date.format("MM"));
  const [yearId, setYearId] = useState(date.format("Y"));
  const [currentYearStartDate, setCurrentYearStartDate] = useState(moment().startOf("year").format("MM/DD/YYYY"));
  const [currentYearEndDate, setCurrentYearEndDate] = useState(moment().format("MM/DD/YYYY"));
  const [lastYearStartDate, setLastYearStartDate] = useState(moment().startOf("year").subtract(1, "year").format("MM/DD/YYYY"));
  const [lastYearEndDate, setLastYearEndDate] = useState(moment().subtract(1, "year").format("MM/DD/YYYY"));
  const currentYearStartDateYte = moment().startOf("year").format("MM/DD/YYYY");
  const currentYearEndDateYte = moment().endOf("year").format("MM/DD/YYYY");
  const currentYearEndDateYtd = moment().format("MM/DD/YYYY");
  const lastYearStartDateYte = moment().startOf("year").subtract(1, "year").format("MM/DD/YYYY");
  const lastYearEndDateYte = moment().endOf("year").subtract(1, "year").format("MM/DD/YYYY");
  const lastYearEndDateYtd = moment().subtract(1, "year").format("MM/DD/YYYY");
  const currentYearStartDateMte = monthId + "/" + "01" + "/" + yearId;
  const currentYearEndDateMte = monthId + "/" + moment(`${yearId} / ${monthId}`, "YYYY/MM").daysInMonth() + "/" + yearId;
  const lastYearStartDateMte = monthId + "/" + "01" + "/" + (yearId - 1);
  const lastYearEndDateMte = monthId + "/" + moment(`${yearId - 1} / ${monthId}`, "YYYY/MM").daysInMonth() + "/" + (yearId - 1);
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const { submited } = useParams();

  const tableRef = useRef(null);


  const { onDownload: onDownloadReport1 } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Specialty By State Report",
    sheet: "Sheet 1",
  });

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let check = query.get("submited");
  let cy_start_date = query.get("cy_start_date");
  let cy_end_date = query.get("cy_end_date");
  let ly_start_date = query.get("ly_start_date");
  let ly_end_date = query.get("ly_end_date");
  let region_id = query.get("region_id");
  let gender_id = query.get("gender_id");
  let salePersonId = query.get("salesperson_id");
  let selected_id = query.get("selected_id");
  let report = query.get("report");
  let condition = query.get("condition");

  const groupedData = specialityByState?.payload?.data.reduce((acc, item) => {
    if (!acc[item.region_name]) {
      acc[item.region_name] = [];
    }
    acc[item.region_name].push(item);
    return acc;
  }, {});

 

  const columns = [
    {
      title: "#",
      className: "b_bottom",
      render: (text, record, index) => {
        return index + 1
      },
    },
    {
      title: "REGION",
      dataIndex: "region_name",
      key: "region_name",
      className: " b_left b_bottom",
      render: (text, record) => {
        return (
          <>
            {text}
          </>
        );
      },
      sorter: (a, b) => {
        return a.region_name.localeCompare(b.region_name)
      },
    },
    {
      title: "STATE",
      dataIndex: "state_name",
      key: "state_name",
      className: " b_left b_bottom",
      render: (text, record) => {
        return (
          <>
            {text}
          </>
        );
      },
      sorter: (a, b) => {
        return a.state_name.localeCompare(b.state_name)
      },
    },
    {
      title: "LAST YEAR",
      children: [
        {
          title: "SHP ",
          dataIndex: "shippedly",
          key: "shippedly",
          className: "b_left b_right_none b_bottom",
          align: "right",
          render: (text, record) => {
            return {
              children: <div>{parseFloat(text).toLocaleString('en-US')}</div>
            };
          },
          sorter: (a, b) => {
            return a.shippedly - b.shippedly
          },
        },
        {
          title: "RETURN",
          dataIndex: "returnedly",
          key: "returnedly",

          align: "right",
          className: "b_left b_bottom",
          render: (text, record) => {
            return {
              children: <div>{parseFloat(text).toLocaleString('en-US')}</div>
            }
          },
          sorter: (a, b) => {
            return a.returnedly - b.returnedly
          },
        },
        {
          title: "NET",
          dataIndex: "net_amount_ly",
          key: "net_amount_ly",
          align: "right",
          className: "b_right b_left b_bottom",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#E8F2FC' }
              },
              children: <div>{parseFloat(text).toLocaleString('en-US')}</div>
            }
          },
          sorter: (a, b) => {
            return a.net_amount_ly - b.net_amount_ly
          },
        },
      ],
    },
    {
      title: "THIS YEAR",
      children: [
        {
          title: "SHP",
          dataIndex: "shippedcy",
          key: "shippedcy",
          align: "right",
          className: "b_left b_right_none b_bottom",
          render: (text, record) => {
            return (
              <>
                {
                  parseFloat(text).toLocaleString('en-US')
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.shippedcy - b.shippedcy
          },
        },
        {
          title: "RETURN",
          dataIndex: "returnedcy",
          key: "returnedcy",
          align: "right",
          className: " b_left b_bottom",
          render: (text, record) => {
            return (
              <>
                {
                  parseFloat(text).toLocaleString('en-US')
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.returnedcy - b.returnedcy
          },
        },
        {
          title: "NET",
          dataIndex: "net_amount_cy",
          key: "net_amount_cy",
          align: "right",
          className: " b_left b_bottom",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#E8F2FC', }
              },
              children:
                <div>
                  {
                    parseFloat(text).toLocaleString('en-US')
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return a.net_amount_cy - b.net_amount_cy
          },
        },
        {
          title: "POD",
          dataIndex: "pod",
          key: "pod",
          align: "right",
          className: " b_left b_bottom",
          render: (text, record) => {
            return (
              <>
                {
                  parseFloat(text).toLocaleString('en-US')
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.pod - b.pod
          },
        },
        {
          title: "PEND",
          dataIndex: "pending",
          key: "pending",
          align: "right",
          className: " b_left b_bottom",
          render: (text, record) => {
            return (
              <>
                {
                  parseFloat(text).toLocaleString('en-US')
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.pending - b.pending
          },
        },
        {
          title: "TOTAL SALES",
          dataIndex: "totalsalescy",
          key: "totalsalescy",
          align: "right",
          className: " b_left b_bottom",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#E8F2FC' }
              },
              children: <div>{parseFloat(text).toLocaleString('en-US')}</div>
            }
          },
          sorter: (a, b) => {
            return a.totalsalescy - b.totalsalescy
          },
        },

      ],
    },
    {
      title: "$ DIFF. TY/LY",
      dataIndex: "diff_amount_cy_ly",
      key: "diff_amount_cy_ly",
      align: "right",
      className: " b_left b_bottom",
      render: (text, record) => {
        return {
          props: {
            style: {
              color: text < 0 ? 'red' : 'inherit' // Set color conditionally
            }
          },
          children: `${parseFloat(text).toLocaleString('en-US')}`
        };
      },
      sorter: (a, b) => {
        return a.diff_amount_cy_ly - b.diff_amount_cy_ly
      },
    },
    {
      title: "% LY",
      dataIndex: "diff_amount_cy_ly_percent",
      key: "diff_amount_cy_ly_percent",
      align: "right",
      className: " b_left b_bottom",
      render: (text, record) => {
        return {
          // props: {
          //   style: { background: '#fffadf' }
          // },
          children:
            <div className={text < 0 ? 'color_red' : ''}>
              {
                text
              }%
            </div>
        }
      },
      sorter: (a, b) => {
        return a.diff_amount_cy_ly_percent - b.diff_amount_cy_ly_percent
      },
    },
    {
      title: "STATUS",
      children: [
        {
          title: "EXISTING",
          dataIndex: "existing",
          key: "existing",
          align: "right",
          className: "b_left b_right_none b_bottom",
          render: (text, record) => {
            return (
              <>
                {
                  parseFloat(text).toLocaleString('en-US')
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.existing - b.existing
          },
        },
        {
          title: "NEW",
          dataIndex: "new",
          key: "new",
          align: "right",
          className: " b_left b_bottom",
          render: (text, record) => {
            return (
              <>
                {
                  parseFloat(text).toLocaleString('en-US')
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.new - b.new
          },
        },
        {
          title: "INACTIVE",
          dataIndex: "inactive",
          key: "inactive",
          align: "right",
          className: " b_left b_bottom",
          render: (text, record) => {
            return {
              // props: {
              //   style: { background: '#E8F2FC' }
              // },
              children:
                <div>
                  {
                    parseFloat(text).toLocaleString('en-US')
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return a.inactive - b.inactive
          },
        },
      ],
    },
    {
      title: "# DOOR",
      children: [
        {
          title: "EXISTING",
          dataIndex: "existing_count",
          key: "existing_count",
          align: "right",
          className: "b_left b_right_none b_bottom",
          sorter: (a, b) => {
            return a.existing_count - b.existing_count
          },
        },
        {
          title: "NEW",
          dataIndex: "new_count",
          key: "new_count",
          align: "right",
          className: " b_left b_bottom",
          sorter: (a, b) => {
            return a.new_count - b.new_count
          },
        },
        {
          title: "INACTIVE",
          dataIndex: "inactive_count",
          key: "inactive_count",
          align: "right",
          className: " b_left b_bottom",
          sorter: (a, b) => {
            return a.inactive_count - b.inactive_count
          },
        },
      ],
    },
  ];

  const totalTTLSLS = saleComparisionCustomerWiseReport?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.ty_yte_ttl), 0)

  console.log("dateTypeId", dateTypeId)

  function getNewState() {
    return {
      currentYearStartDate: currentYearStartDate,
      currentYearEndDate: currentYearEndDate,
      currentYearEndDateYtd: currentYearEndDateYtd,
      currentYearStartDateMte: currentYearStartDateMte,
      currentYearEndDateMte: currentYearEndDateMte,
      lastYearStartDate: lastYearStartDate,
      lastYearEndDate: lastYearEndDate,
      lastYearEndDateYtd: lastYearEndDateYtd,
      lastYearStartDateMte: lastYearStartDateMte,
      lastYearEndDateMte: lastYearEndDateMte,
      regionId: regionID,
      salePersonId: salePersonName,
      genderId: genderID,
    };
  }
  let newState;

  useEffect(() => {
    newState = getNewState();
    setFormState(newState);
  }, [regionID, salePersonName, genderID]);


  useEffect(() => {
    let year = yearId;
    let month = monthId;
    let currentYearStartDate = moment().startOf("year").format("MM/DD/YYYY");
    let currentYearEndDate = moment().endOf("year").format("MM/DD/YYYY");
    let lastYearStartDate = moment()
      .startOf("year")
      .subtract(1, "year")
      .format("MM/DD/YYYY");
    let lastYearEndDate = moment()
      .endOf("year")
      .subtract(1, "year")
      .format("MM/DD/YYYY");

    if (dateTypeId === "yte") {
      currentYearStartDate = moment().startOf("year").format("MM/DD/") + year;
      currentYearEndDate = moment().endOf("year").format("MM/DD/") + year;
      lastYearStartDate = moment().startOf("year").subtract(1, "year").format("MM/DD/") + (year - 1);
      lastYearEndDate = moment().subtract(1, "year").endOf("year").format("MM/DD/") + (year - 1);
    } else if (dateTypeId === "ytd") {
      currentYearStartDate = moment().startOf("year").format("MM/DD/") + year;
      currentYearEndDate = moment().format("MM/DD/") + year;
      lastYearStartDate = moment().startOf("year").subtract(1, "year").format("MM/DD/") + (year - 1);
      lastYearEndDate = moment().subtract(1, "year").format("MM/DD/") + (year - 1);
    } else if (dateTypeId === "mtd") {
      currentYearStartDate = moment().format("MM") + "/" + "01" + "/" + year;
      currentYearEndDate = moment().format("MM") + "/" + moment().format("DD") + "/" + year;
      lastYearStartDate = moment().format("MM") + "/" + "01" + "/" + (year - 1);
      lastYearEndDate = moment().format("MM") + "/" + moment().format("DD") + "/" + (year - 1);
    } else if (dateTypeId === "mte") {
      currentYearStartDate = month + "/" + "01" + "/" + year;
      currentYearEndDate = month + "/" + moment(`${year} / ${month}`, "YYYY/MM").daysInMonth() + "/" + year;
      lastYearStartDate = month + "/" + "01" + "/" + (year - 1);
      lastYearEndDate = month + "/" + moment(`${year - 1} / ${month}`, "YYYY/MM").daysInMonth() + "/" + (year - 1);
    } else if (dateTypeId === "spring") {
      currentYearStartDate = "01" + "/" + "01" + "/" + year;
      currentYearEndDate = "06" + "/" + "30" + "/" + year;
      lastYearStartDate = "01" + "/" + "01" + "/" + (year - 1);
      lastYearEndDate = "06" + "/" + "30" + "/" + (year - 1);
    } else if (dateTypeId === "fall") {
      currentYearStartDate = "07" + "/" + "01" + "/" + year;
      currentYearEndDate = "12" + "/" + "31" + "/" + year;
      lastYearStartDate = "07" + "/" + "01" + "/" + (year - 1);
      lastYearEndDate = "12" + "/" + "31" + "/" + (year - 1);
    }
    setCurrentYearStartDate(currentYearStartDate);
    setCurrentYearEndDate(currentYearEndDate);
    setLastYearStartDate(lastYearStartDate);
    setLastYearEndDate(lastYearEndDate);

    const newState = {
      cy_start_date: currentYearStartDate,
      cy_end_date: currentYearEndDate,
      cy_end_date_ytd: currentYearEndDateYtd,
      cy_start_date_mte: currentYearStartDateMte,
      cy_end_date_mte: currentYearEndDateMte,

      ly_start_date: lastYearStartDate,
      ly_end_date: lastYearEndDate,
      ly_end_date_ytd: lastYearEndDateYtd,
      ly_start_date_mte: lastYearStartDateMte,
      ly_send_date_mte: lastYearEndDateMte,
      region_id: regionID,
      sales_person_id: salePersonName,
      gender_id: genderID,
    };
    setFormState(newState);
  }, [dateTypeId, monthId, yearId]);


  useEffect(() => {
    dispatch(getAllActiveSaleperson(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getAllActiveRegion(token, menuId));
  }, []);


  const onFinish = () => {
    setLoading(true);
    // history.push({
    //   pathname: "/speciality-by-region",
    //   search: `?submited=${true}&report=${1}&region_id=${formState.region_id}&gender_id=${formState.gender_id}&customer_type_id=${formState.hasOwnProperty("customer_type_id") ? formState.customer_type_id : formState?.customerTypeID}&cy_start_date=${formState.hasOwnProperty("cy_start_date") ? formState.cy_start_date : formState?.currentYearStartDate}&cy_end_date=${formState.hasOwnProperty("cy_end_date") ? formState.cy_end_date : formState?.currentYearEndDate}&ly_start_date=${formState.hasOwnProperty("ly_start_date") ? formState.ly_start_date : formState?.lastYearStartDate}&ly_end_date=${formState.hasOwnProperty("ly_end_date") ? formState.ly_end_date : formState?.lastYearEndDate}&condition=${dateTypeId}`
    // });
    dispatch(getSpecialityByState(token, formState)).then((res) => {
      if (res.type === "GET_SPECIALITY_BY_STATE_SUCCESS") {
        setLoading(false);
      }
    });
  };


  const clearFields = (event, type) => {
    event.preventDefault();
    if (type === "region") {
      setRegionID("All");
    } else if (type === "salesPerson") {
      setSalePersonName("All");
    } else if (type === "gender") {
      setGenderID("All");
    }
  };

  const handlePrint = () => {
    window.print();
  };




  return (
    <>
      <div className="container-fluid">
        {report < 2 &&
          <div className="row">
            <div className="col">
              <div className="report-wrapper-main header_class">
                <div className="row justify-content-between pb-0">
                  <div className="col-3 d-flex align-items-start  box-space">
                    <div className="logobrand">
                      <img id="ImgLogo" alt="logo" src={logo} />
                    </div>
                  </div>

                  <div className="col-3 box-space right-content-header text-right">
                    <div className=" d-flex justify-content-end">
                      <div className=" d-flex justify-content-end printing_class">
                        <div className="print_icon printing_class">
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            className="fa-solid fa-print printing_class"
                            onClick={handlePrint}
                          ></i>
                        </div>
                        <div className="print_icon printing_class">
                          {specialityByState?.payload?.data ? (
                            <input
                              type="image"
                              name="ImgExcel"
                              alt="Export to excel"
                              id="ImgExcel"
                              title="Export to excel"
                              src={xlsx}
                              onClick={onDownloadReport1}
                              style={{
                                borderWidth: "0px",
                                height: "14px",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <input
                              type="image"
                              name="ImgExcel"
                              alt="Export to excel"
                              id="ImgExcel"
                              title="Export to excel"
                              src={xlsx}
                              style={{ borderWidth: "0px", height: "14px" }}
                            />
                          )}
                        </div>
                      </div>
                      <span style={{ fontWeight: "bolder" }}>
                        Specialty By State
                        <br />
                        Rpt # 006
                        <br />
                        Login as:
                        <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                          {" "}
                          {userName}
                        </span>

                      </span>
                    </div>
                  </div>
                </div>
                <div className="row pb-2">
                  <div className="col">
                    <Form
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      layout="horizontal"
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <div className="main_form row">
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="gender_id" className="labels width30">
                              Condition:
                            </label>
                            <div style={{ marginBottom: "-2px" }} className=" w-100">
                              <Form.Item
                                name="dateTypeId"
                                className="formFullWidth"
                              >
                                <Select
                                  allowClear
                                  size="small"
                                  showSearch
                                  optionFilterProp="children"
                                  className={"select_Inputs"}
                                  defaultValue={dateTypeId}
                                  value={dateTypeId}
                                  onChange={(a) => {
                                    setDateTypeId(a);
                                    setYearId(date.format("Y"));
                                  }}
                                >
                                  <Option key={"ytd"} value={"ytd"}>
                                    Year To Date
                                  </Option>
                                  <Option key={"yte"} value={"yte"}>
                                    Year To End
                                  </Option>
                                  <Option key={"mtd"} value={"mtd"}>
                                    Month To Date
                                  </Option>
                                  <Option key={"mte"} value={"mte"}>
                                    Month To End
                                  </Option>
                                  <Option key={"spring"} value={"spring"}>
                                    Spring
                                  </Option>
                                  <Option key={"fall"} value={"fall"}>
                                    Fall
                                  </Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="gender_id" className="labels width30">
                              Year:
                            </label>
                            <div style={{ marginBottom: "2px" }} className=" w-100">

                              <DatePicker
                                size="small"
                                className="filter-fields full_date"
                                value={moment(yearId)}
                                defaultValue={moment(yearId)}
                                onChange={(e) =>
                                  setYearId(moment(e).format("YYYY"))
                                }
                                picker="year"
                              />

                            </div>
                          </div>
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="" className="labels width30">Sale Person</label>
                            <div style={{ marginBottom: "-5px" }} className=" w-100">
                              <Form.Item
                                name="salePersonName"
                                initialValue={"All"}
                                className="formFullWidth"
                              >
                                <Select
                                  allowClear
                                  size="small"
                                  showSearch
                                  optionFilterProp="children"
                                  onChange={(e) => setSalePersonName(e)}
                                  className="filter-fields"
                                  loading={salePersonNew?.loading}
                                  defaultValue="All"
                                  value={salePersonName}
                                  filterSort={(optionA, optionB) =>
                                    optionB?.value !== null ?
                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                  }
                                >
                                  <Option value={null}>All</Option>
                                  {salePersonNew?.payload?.data.map((item) => {
                                    return (
                                      <Option
                                        defaultValue={"All"}
                                        key={item.sales_person_id}
                                        value={item.sales_person_id}
                                      >
                                        {item.salesperson_name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label className="labels width30">Month:</label>
                            <div style={{ marginBottom: "2px" }} className=" w-100">
                              <DatePicker
                                size="small"
                                className="filter-fields full_date"
                                value={moment(monthId)}
                                disabled={dateTypeId === "ytd"}
                                onChange={(e) =>
                                  setMonthId(moment(e).format("MM"))
                                }
                                picker="month"
                                format={"MM"}
                                mode="month"
                              />
                            </div>
                          </div>
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="" className="labels width30">Gender</label>
                            <div style={{ marginBottom: "-5px" }} className=" w-100">
                              <Form.Item
                                name="genderID"
                                initialValue={"All"}
                                className="formFullWidth"
                              >
                                <Select
                                  allowClear
                                  size="small"
                                  showSearch
                                  optionFilterProp="children"
                                  onChange={(e) => setGenderID(e)}
                                  className="filter-fields"
                                  loading={gender?.loading}
                                  defaultValue="All"
                                  value={genderID}
                                  filterSort={(optionA, optionB) =>
                                    optionB?.value !== null ?
                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                  }
                                >
                                  <Option value={null}>All</Option>
                                  {gender?.payload?.data.map((item) => {
                                    return (
                                      <Option
                                        defaultValue={"All"}
                                        key={item.gender_id}
                                        value={item.gender_id}
                                      >
                                        {item.gender_name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="" className="labels width30">Region</label>
                            <div className=" w-100">
                              <Form.Item
                                name="regionID"
                                initialValue={"All"}
                                className="formFullWidth"
                              >
                                <Select
                                  allowClear
                                  size="small"
                                  showSearch
                                  optionFilterProp="children"
                                  defaultValue="All"
                                  onChange={(e) => setRegionID(e)}
                                  className="filter-fields"
                                  loading={RegionNew?.loading}
                                  value={regionID}
                                  filterSort={(optionA, optionB) =>
                                    optionB?.value !== null ?
                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                  }
                                >
                                  <Option value={null}>All</Option>
                                  {RegionNew?.payload?.data.map((item) => {
                                    return (
                                      <Option
                                        defaultValue={"All"}
                                        key={item.region_id}
                                        value={item.region_id}
                                      >
                                        {item.region_name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div style={{ marginTop: "0px", display: "flex", justifyContent: "end" }} >
                            <Button type="primary" htmlType="submit" className='submit-btn-btm m-0' disabled={loading}>
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      {report < 2 &&
        <div className="table_content"
          style={{ width: 'fit-content' }}
          ref={tableRef}
        >
          <div>
            {loading ? (
              <div className="d-flex align-items-center justify-content-center spin-style mt-3">
                <Spin />
              </div>
            ) : specialityByState?.payload?.data.length ? (
              // Loop through each state group and render a table
              Object.entries(groupedData).map(([region, data]) => (
                <div key={region} >
                  <h3 style={{ borderBottom: "1px solid black", paddingBottom: "1px", display: "inline-block"}}>{region}</h3>
                  <Table
                    style={{ fontSize: '10.3px' }}
                    className="test04 mt-3 sales_target_report_table sales_report_res_table sales_target_report_table_dashboard speciality_state"
                    size="small"
                    pagination={false}
                    summary={pageData => {
                      let shippLY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.shippedly)
                      }, 0);
                      let returnLY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.returnedly)
                      }, 0);
                      let netLY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.net_amount_ly)
                      }, 0);
                      let planLY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.planamount)
                      }, 0);
                      // let plLY = pageData.reduce(function (previousValue, currentValue) {
                      //   return previousValue += parseFloat(currentValue.plan_to_ly_percent)
                      // }, 0);
                      let shipCY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.shippedcy)
                      }, 0);
                      let returnCY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.returnedcy)
                      }, 0);
                      let netCY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.net_amount_cy)
                      }, 0);
                      let podCY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.pod)
                      }, 0);
                      let pendingCY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.pending)
                      }, 0);
                      let totalSalesCY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.totalsalescy)
                      }, 0);
                      let diffTY_LY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.diff_amount_cy_ly)
                      }, 0);
                      // let percentLY = pageData.reduce(function (previousValue, currentValue) {
                      //   return previousValue += parseFloat(currentValue.percent_plan_ty)
                      // }, 0);
                      // let percentPlan = pageData.reduce(function (previousValue, currentValue) {
                      //   return previousValue += parseFloat(currentValue.miss_plan_ty)
                      // }, 0);
                      let missPlanCY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.miss_plan_amount)
                      }, 0);
                      let existing = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.existing)
                      }, 0);
                      let newa = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.new)
                      }, 0);
                      let inActive = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.inactive)
                      }, 0);
                      let existingCount = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.existing_count)
                      }, 0);
                      let newCount = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.new_count)
                      }, 0);
                      let inActiveCount = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.inactive_count)
                      }, 0);
                      return (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right"}}>Total</p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {numeral(shippLY.toLocaleString("en-US")).format("0,00")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {numeral(returnLY.toLocaleString("en-US")).format("0,00")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {numeral(netLY.toLocaleString("en-US")).format("0,00")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {numeral(shipCY.toLocaleString("en-US")).format("0,00")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {numeral(returnCY.toLocaleString("en-US")).format("0,00")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {numeral(netCY.toLocaleString("en-US")).format("0,00")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {numeral(podCY.toLocaleString("en-US")).format("0,00")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {numeral(pendingCY.toLocaleString("en-US")).format("0,00")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {numeral(totalSalesCY.toLocaleString("en-US")).format("0,00")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p className={diffTY_LY < 0 ? 'color_red' : ''} style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {numeral(diffTY_LY.toLocaleString("en-US")).format("0,00")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p className={(((totalSalesCY / netLY) - 1) * 100) < 0 ? 'color_red' : ''} style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {numeral((((totalSalesCY / netLY) - 1) * 100).toLocaleString("en-US")).format("0")}%
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {existing.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {newa.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {inActive.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {existingCount}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {newCount}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="sales_table_summary">
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {inActiveCount}
                              </p>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                    }}
                    bordered
                    columns={columns}
                    scroll={{ x: 'max-content' }}
                    dataSource={data}
                  />
                </div>
              ))
            ) : (
              <div
                style={{
                  backgroundColor: '#CCCCCC',
                  borderWidth: '1pt',
                  borderStyle: 'Dashed',
                  fontWeight: 'bold',
                  height: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                No record(s)
              </div>
            )}
          </div>
        </div>}
    </>
  );
};

export default SpecialityByState;

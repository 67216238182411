import {
  GET_SPECIALITY_BY_REGION_REQUEST,
  GET_SPECIALITY_BY_REGION_SUCCESS,
  GET_SPECIALITY_BY_REGION_ERROR,
  GET_SPECIALITY_BY_STATE_REQUEST,
  GET_SPECIALITY_BY_STATE_SUCCESS,
  GET_SPECIALITY_BY_STATE_ERROR,
  GET_SPECIALITY_BY_CUSTOMER_REQUEST,
  GET_SPECIALITY_BY_CUSTOMER_SUCCESS,
  GET_SPECIALITY_BY_CUSTOMER_ERROR,
  GET_SPECIALITY_BY_CUSTOMER_MONTH_WISE_REQUEST,
  GET_SPECIALITY_BY_CUSTOMER_MONTH_WISE_SUCCESS,
  GET_SPECIALITY_BY_CUSTOMER_MONTH_WISE_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

export const getSpecialityByRegion = (token, values) => (dispatch) => {
  dispatch({ type: GET_SPECIALITY_BY_REGION_REQUEST });
  const newData = {
    cy_start_date: values.currentYearStartDate ? values.currentYearStartDate : values.cy_start_date,
    cy_end_date: values.currentYearEndDate ? values.currentYearEndDate : values.cy_end_date,
    ly_start_date: values.lastYearStartDate ? values.lastYearStartDate : values.ly_start_date,
    ly_end_date: values.lastYearEndDate ? values.lastYearEndDate : values.ly_end_date,
    // customer_type_id: null,
    region_id: values.region_id || values.regionId ? (values.regionId ? values.regionId : values.region_id) : null,
    gender_id: values.gender_id || values.genderId ? (values.genderId ? values.genderId : values.gender_id) : null,
    sales_person_id: values.sales_person_id || values.salePersonId ? (values.salePersonId ? values.salePersonId : values.sales_person_id) : null
  };
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/GetSpecialityReportByRegion`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_SPECIALITY_BY_REGION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SPECIALITY_BY_REGION_ERROR,
        payload: [],
      });
    });
};

export const getSpecialityByState = (token, values) => (dispatch) => {
  dispatch({ type: GET_SPECIALITY_BY_STATE_REQUEST });
  const newData = {
    cy_start_date: values.currentYearStartDate ? values.currentYearStartDate : values.cy_start_date,
    cy_end_date: values.currentYearEndDate ? values.currentYearEndDate : values.cy_end_date,
    ly_start_date: values.lastYearStartDate ? values.lastYearStartDate : values.ly_start_date,
    ly_end_date: values.lastYearEndDate ? values.lastYearEndDate : values.ly_end_date,
    // customer_type_id: null,
    region_id: values.region_id || values.regionId ? (values.regionId ? values.regionId : values.region_id) : null,
    gender_id: values.gender_id || values.genderId ? (values.genderId ? values.genderId : values.gender_id) : null,
    sales_person_id: values.sales_person_id || values.salePersonId ? (values.salePersonId ? values.salePersonId : values.sales_person_id) : null
  };
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/GetSpecialityReportByState`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_SPECIALITY_BY_STATE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SPECIALITY_BY_STATE_ERROR,
        payload: [],
      });
    });
};

export const getSpecialityByCustomer = (token, values) => (dispatch) => {
  dispatch({ type: GET_SPECIALITY_BY_CUSTOMER_REQUEST });
  const newData = {
    cy_start_date: values.currentYearStartDate ? values.currentYearStartDate : values.cy_start_date,
    cy_end_date: values.currentYearEndDate ? values.currentYearEndDate : values.cy_end_date,
    ly_start_date: values.lastYearStartDate ? values.lastYearStartDate : values.ly_start_date,
    ly_end_date: values.lastYearEndDate ? values.lastYearEndDate : values.ly_end_date,
    // customer_type_id: null,
    region_id: values.region_id || values.regionId ? (values.regionId ? values.regionId : values.region_id) : null,
    gender_id: values.gender_id || values.genderId ? (values.genderId ? values.genderId : values.gender_id) : null,
    sales_person_id: values.sales_person_id || values.salePersonId ? (values.salePersonId ? values.salePersonId : values.sales_person_id) : null,
    state_name: values.state_id || values.stateId ? (values.stateId ? values.stateId : values.state_id) : null,
    status: values.status_id || values.statusId ? (values.statusId ? values.statusId : values.status_id) : null,

  };
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/GetSpecialityReportByCustomer`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_SPECIALITY_BY_CUSTOMER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SPECIALITY_BY_CUSTOMER_ERROR,
        payload: [],
      });
    });
};


export const getSpecialityByCustomerMonthWise = (token, values) => (dispatch) => {
  dispatch({ type: GET_SPECIALITY_BY_CUSTOMER_MONTH_WISE_REQUEST });
  // const newData = {
  // customer_id: parseFloat(values.customer_id)
  // };
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/GetSpecialityReportByCustomerMonthWise`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: values
  })
    .then((response) => {
      return dispatch({
        type: GET_SPECIALITY_BY_CUSTOMER_MONTH_WISE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SPECIALITY_BY_CUSTOMER_MONTH_WISE_ERROR,
        payload: [],
      });
    });
};


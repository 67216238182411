import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { Route, Redirect, Link, useHistory } from "react-router-dom";
import { ReadCookie } from "./readCookie";
import {
  Menu,
  PageHeader,
  Button,
  Dropdown,
  Row,
  Col,
  Layout,
  Space,
} from "antd";
import {
  SettingOutlined,
  UserOutlined,
  LogoutOutlined,
  DownOutlined,
  ProfileOutlined,
  MenuOutlined,
  ArrowDownOutlined,
  CaretDownOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { logout } from "../redux/actions/auth";
import { getMenu } from "../redux/actions/menus";
import logo from "../assets/images/newLogo.png";
import GlobalSearch from "../components/GlobalSearch/GlobalSearch";
import { getUserType } from "../redux/actions/userType.action";
import GlobalSearchOms from "../components/GlobalSearch/GlobalSearchOms";
import { jwtDecode } from "jwt-decode";

const { Sider } = Layout;
const { SubMenu } = Menu;
const formatTime = (time) => {
  const hours = Math.floor(time / 1000 / 60 / 60);
  const minutes = Math.floor((time / 1000 / 60) % 60);
  const seconds = Math.floor((time / 1000) % 60);

  // Display hours if greater than 0, otherwise just show minutes and seconds
  if (hours > 0) {
    return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds
      }`;
  } else {
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }
};
const ProtectedRoute = ({ test, component: Comp, loggedIn, path, ...rest }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [sidebar, setSidebar] = useState(true);

  const [timeLeft, setTimeLeft] = useState(null);

  const { menus, userType, auth } = useSelector((state) => state);
  const isAuthenticated = ReadCookie("token");

  const handleLogout = () => {
    dispatch(logout(isAuthenticated));
    // console.log("aaaaaaaaa yesssssss");

  };

  const showSidebar = () => {
    if (sidebar) {
      setSidebar(false);
    } else {
      setSidebar(true);
    }
  };



  // useEffect(() => {
  //   let tokenn = ReadCookie("token");
  //   if (tokenn) {
  //     let { exp } = jwtDecode(tokenn);
  //     let expirationTime = exp * 1000;
  //     const updateCountdown = () => {
  //       const currentTime = Date.now(); // Current time in milliseconds
  //       const remainingTime = expirationTime - currentTime;

  //       if (remainingTime <= 0) {
  //         setTimeLeft(0); // Token expired
  //       } else {
  //         setTimeLeft(remainingTime);
  //       }
  //     };

  //     // Update countdown every second
  //     const intervalId = setInterval(updateCountdown, 1000);

  //     // Cleanup the interval on component unmount
  //     return () => clearInterval(intervalId);
  //   }
  // }, [auth]);

  useEffect(() => {
    if (isAuthenticated && menus.length === 0) {
      dispatch(getUserType(isAuthenticated));
      dispatch(getMenu(isAuthenticated));
    }
  }, [isAuthenticated, dispatch, menus]);

  const menu = (
    <Menu>
      <Menu.Item key="leaderboard" icon={<DashboardOutlined />}>
        <Link rel="noopener noreferrer" to={{ pathname: `/leaderboard` }}>
          Leader Board
        </Link>
      </Menu.Item>
      <Menu.Item key="profile" icon={<UserOutlined />}>
        <Link rel="noopener noreferrer" to={{ pathname: `/profile` }}>
          Profile
        </Link>
      </Menu.Item>
      <Menu.Item key="settings" icon={<SettingOutlined />}>
        <Link rel="noopener noreferrer" to={{ pathname: `/forget-password` }}>
          Change Password
        </Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          dispatch(logout(isAuthenticated));
        }}
        key="logout"
        icon={<LogoutOutlined />}
      >
        <span>Logout </span>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      {menus.loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <div style={{ width: "100px", height: "100vh" }}>
            <Loader type="TailSpin" color="gray" height={60} width={60} />
          </div>
        </div>
      ) : (
        <Route
          path={path}
          {...rest}
          render={(props) => {
            return isAuthenticated ? (
              <>
                <div className="mainContainer">
                  {userType?.payload?.data[0].coalesce === "OMS" ? (
                    <></>
                  ) : (
                    <div className="firstNav printing_class">
                      <PageHeader
                        ghost={false}
                        className="site-page-header"
                        title={
                          <>
                            <img
                              className="logoImage"
                              onClick={() => history.push("/")}
                              src={logo}
                              style={{ cursor: "pointer" }}
                              alt="DL1961"
                            />
                          </>
                        }
                        extra={[
                          <div className="global_search">
                            <GlobalSearch
                              key="globalSearchKey"
                              token={isAuthenticated}
                            />
                          </div>,
                          // <div
                          //   className="global_search"
                          //   style={{ width: "150px" }}
                          // >
                          //   Session Expiry:{" "}
                          //   {timeLeft === 0 ? (
                          //     <span style={{ color: "#f30000" }}>Expired</span>
                          //   ) : (
                          //     formatTime(timeLeft)
                          //   )}
                          // </div>,
                          <Dropdown
                            overlay={menu}
                            placement="bottom"
                            arrow
                            key="3"
                            className="user_dropdown"
                          >
                            {userType?.payload?.data[0].coalesce === "OMS" ? (
                              <></>
                            ) : (
                              <Space>
                                <Button
                                  className="d-flex align-items-center color-black user_detail_btn"
                                  type="link"
                                  icon={<UserOutlined />}
                                >
                                  {ReadCookie("name").toLowerCase()}
                                </Button>
                                <i className="fa-solid fa-chevron-down font_weight"></i>
                              </Space>
                            )}
                          </Dropdown>,
                        ]}
                      />
                    </div>
                  )}
                  <div className="testt">
                    {userType?.payload?.data[0].coalesce === "OMS" ? (
                      <>
                        <div className="main_cont">
                          <div
                            className={`oms-sidebar ${sidebar === true ? "" : "-m-239"
                              }`}
                          >
                            <div className=" oms-sidebar-content">
                              <div
                                className="trapezoid"
                                style={{ position: "absolute", left: "260px" }}
                                onClick={showSidebar}
                              >
                                <MenuOutlined />
                              </div>

                              <div className="brand-logo">
                                <img src={logo} alt="logo" />
                              </div>
                              <div className="user-info">
                                <div className="user-circle">
                                  <i className="fa-solid fa-user"></i>
                                </div>
                                <span className="user-name">
                                  Welcome {ReadCookie("name").toLowerCase()}
                                </span>
                              </div>
                              <div className="sidebar_content">
                                <div className="global_search_oms">
                                  <GlobalSearchOms
                                    key="globalSearchKey"
                                    token={isAuthenticated}
                                  />
                                </div>
                                <SideBar
                                  menus={menus}
                                  logout={() => handleLogout()}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="contentWithSidebar">
                            <Comp {...props} token={isAuthenticated} />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="secondNav printing_class position-relative">
                          <Navigation menus={menus} />
                          <div className="expiry-timer">
                            <TokenTimer auth={auth} dispatch={dispatch}/>
                          </div>
                        </div>
                        <div className="body content-main-wrapper">
                          <Comp {...props} token={isAuthenticated} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <Redirect to={"login"} />
            );
          }}
        />
      )}
    </>
  );
};
export default ProtectedRoute;

const Navigation = ({ menus }) => {
  const handleClick = (record) => {
    document.cookie = `menuId=${record.col_menu_id}`;
  };
  return (
    <Menu
      triggerSubMenuAction="hover"
      mode="horizontal"
      theme="light"
      className="menu_container"
    >
      {menus.loading ? (
        <Row type="flex" align="middle">
          <Col>
            <Loader
              style={{
                verticalAlign: "middle",
              }}
              className="plpx-20"
              type="TailSpin"
              color="gray"
              height={15}
              width={15}
            />
          </Col>
        </Row>
      ) : (
        <>
          {menus?.payload?.map((first) => {
            return (
              <SubMenu
                key={first.col_menu_id}
                title={
                  <div className="sub_menuName">
                    {first.col_menu_name} <CaretDownOutlined className="down_icon" />
                  </div>
                }
              >
                {first?.children?.map((second) => {
                  if (second?.children.length > 0) {
                    return (
                      <SubMenu
                        key={second.col_menu_id}
                        title={second.col_menu_name}
                      >
                        {second?.children?.map((third) => {
                          return (
                            <Menu.Item
                              className=""
                              onClick={() => handleClick(third)}
                              key={third.col_menu_id}
                            >
                              <Link
                                key={third.col_menu_id}
                                to={
                                  third.col_runtime_name
                                    ? third.col_runtime_name
                                    : "/"
                                }
                              >
                                {third.col_menu_name}
                              </Link>
                            </Menu.Item>
                          );
                        })}
                      </SubMenu>
                    );
                  } else {
                    return (
                      <Menu.Item
                        onClick={() => handleClick(second)}
                        key={second.col_menu_id}
                      >
                        <Link
                          key={second.col_menu_id}
                          to={
                            second?.col_runtime_name
                              ? second?.col_runtime_name
                              : "/"
                          }
                        >
                          {second.col_menu_name}
                        </Link>
                      </Menu.Item>
                    );
                  }
                })}
              </SubMenu>
            );
          })}
        </>
      )}
    </Menu>
  );
};

const SideBar = ({ menus, logout }) => {
  const handleClick = (record) => {
    document.cookie = `menuId=${record.col_menu_id}`;
  };
  return (
    <Sider>
      <Menu triggerSubMenuAction="click" mode="inline" theme="light">
        {menus.loading ? (
          <Row type="flex" align="middle">
            <Col>
              <Loader
                style={{
                  verticalAlign: "middle",
                }}
                className="plpx-20"
                type="TailSpin"
                color="gray"
                height={15}
                width={15}
              />
            </Col>
          </Row>
        ) : (
          <>
            {menus?.payload?.map((first) => {
              return (
                <SubMenu
                  className="main_menu"
                  key={first.col_menu_id}
                  icon={<ProfileOutlined />}
                  title={<span>{first.col_menu_name}</span>}
                >
                  {first?.children?.map((second) => {
                    if (second?.children.length > 0) {
                      return (
                        <SubMenu
                          key={second.col_menu_id}
                          title={second.col_menu_name}
                          className="oms_sub_nav"
                        >
                          {second?.children?.map((third) => {
                            return (
                              <Menu.Item
                                onClick={() => handleClick(third)}
                                key={third.col_menu_id}
                                className=""
                              >
                                <Link
                                  key={third.col_menu_id}
                                  to={
                                    third.col_runtime_name
                                      ? third.col_runtime_name
                                      : "/"
                                  }
                                >
                                  {third.col_menu_name}
                                </Link>
                              </Menu.Item>
                            );
                          })}
                        </SubMenu>
                      );
                    } else {
                      return (
                        <Menu.Item
                          onClick={() => handleClick(second)}
                          key={second.col_menu_id}
                          className=""
                        >
                          <Link
                            key={second.col_menu_id}
                            to={
                              second?.col_runtime_name
                                ? second?.col_runtime_name
                                : "/"
                            }
                          >
                            {second.col_menu_name}
                          </Link>
                        </Menu.Item>
                      );
                    }
                  })}
                </SubMenu>
              );
            })}
            <Menu.Item
              onClick={logout}
              key="logout"
              icon={<LogoutOutlined />}
              className="main_menu logout_btn"
            >
              Logout
            </Menu.Item>
          </>
        )}
      </Menu>
    </Sider>
  );
};



const TokenTimer = ({ auth, dispatch }) => {
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    let tokenn = ReadCookie("token");
    if (tokenn) {
      let { exp } = jwtDecode(tokenn);
      let expirationTime = exp * 1000;
      const updateCountdown = () => {
        const currentTime = Date.now(); // Current time in milliseconds
        const remainingTime = expirationTime - currentTime;

        if (remainingTime <= 0) {
          
          dispatch(logout(tokenn)).then((res) => {
            if(res.type === "ADMIN_LOGOUT_SUCCESS") {
              setTimeLeft(0); // Token expired
            }
          });
        } else {
          setTimeLeft(remainingTime);
        }
      };

      // Update countdown every second
      const intervalId = setInterval(updateCountdown, 1000);

      // Cleanup the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [auth]);

  return (
    <>
      <div
        className="global_search"
        style={{ width: "200px" }}
      >
        Session Expiry:{" "}
        {timeLeft === 0 ? (
          <span style={{ color: "#f30000" }}>Expired</span>
        ) : (
          formatTime(timeLeft)
        )}
      </div>
    </>
  )
}
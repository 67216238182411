import {
  GET_SPECIALITY_BY_CUSTOMER_REQUEST,
  GET_SPECIALITY_BY_CUSTOMER_SUCCESS,
  GET_SPECIALITY_BY_CUSTOMER_ERROR
} from "../actions/types";


export const specialityByCustomer = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SPECIALITY_BY_CUSTOMER_REQUEST:
      return {
        loading: true,
      };
    case GET_SPECIALITY_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_SPECIALITY_BY_CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default: {
      return state;
    }
  }
};

import {
    GET_LEADER_BOARD_REQUEST,
    GET_LEADER_BOARD_SUCCESS,
    GET_LEADER_BOARD_ERROR,

  } from "./types";
  import axios from "axios";
  import { BASEURL } from "../../constant/config.js";
  
  export const getLeaderBoard = (token, data) => (dispatch) => {
    dispatch({ type: GET_LEADER_BOARD_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}getAll/GetLeaderBoardChart`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: data,
    })
      .then((response) => {
        return dispatch({
          type: GET_LEADER_BOARD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LEADER_BOARD_ERROR,
          payload: [],
        });
      });
  };